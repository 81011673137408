import { Component, OnInit, Input } from '@angular/core';
import { SelectOneSchema } from '../../../generics/select-one/select-one.schema';
import { Pagination, getPaginationDefault } from '../../../generics/pagination.model';
import { MunicipioFacade } from '../../../state/municipio/municipio.facade';
import { Municipio } from '../../../state/municipio/municipio.model';

@Component({
  selector: 'municipio-select',
  templateUrl: './municipio-select.component.html',
  styleUrls: ['./municipio-select.component.css']
})
export class MunicipioSelectComponent implements OnInit {

  public schema: SelectOneSchema;
  private pagination: Pagination = getPaginationDefault();

  @Input() showItensWithIds: number[] = [];
  @Input() hideItensWithIds: number[] = [];

  constructor(public facade: MunicipioFacade) { 
    this.pagination.paginator.limit = 9999999;
  }

  ngOnInit() 
  {
    this.schema = {
      label: 'Município',
      placeholder: 'Selecionar Município',
      facade: this.facade,
      include_list: this.showItensWithIds,
      exclude_list: this.hideItensWithIds,
      getId(item: Municipio) {
        return item.id;
      },
      getName(item: Municipio) {
        return item.nome
      }
    };   

    this.facade.paginate(this.pagination);
  }
}
