import { Action } from '@ngrx/store';
import { Macrorregiao } from './macrorregiao.model';
import { Pagination, ApiResponse } from '../../generics/pagination.model';

export enum MacrorregioesActionTypes {
  MacrorregiaoAction = '[Macrorregiao] Action',
  LoadMacrorregiao = '[Macrorregiao] Load Data',
  MacrorregiaoLoaded = '[Macrorregiao] Data Loaded',
  MacrorregiaoSelected = '[Macrorregiao] Selected',
  MacrorregioesSelected = '[Macrorregiao] Multi Selected',
  MacrorregiaoUnselected = '[Macrorregiao] Unselected',
  PaginateMacrorregiao = '[Macrorregiao] Paginate',
  MacrorregiaoPaginated = '[Macrorregiao] Paginated',
  MacrorregiaoRequestDone = '[Macrorregiao] RequestDone',
  DownloadMacrorregiao = '[Macrorregiao] Download',
  MacrorregiaoRequestFailed = '[Macrorregiao] Request Failed',
}

export class Macrorregioes implements Action {
  readonly type = MacrorregioesActionTypes.MacrorregiaoAction;
}

export class LoadMacrorregiao implements Action {
  readonly type = MacrorregioesActionTypes.LoadMacrorregiao;
  constructor() { }
}

export class MacrorregiaoLoaded implements Action {
  readonly type = MacrorregioesActionTypes.MacrorregiaoLoaded;
  constructor(public payload: Macrorregiao[]) { }
}

export class MacrorregiaoSelected implements Action {
  readonly type = MacrorregioesActionTypes.MacrorregiaoSelected;
  constructor(public payload: number) { }
}

export class MacrorregioesSelected implements Action {
  readonly type = MacrorregioesActionTypes.MacrorregioesSelected;
  constructor(public payload: number[]) { }
}

export class MacrorregiaoUnselected implements Action {
  readonly type = MacrorregioesActionTypes.MacrorregiaoUnselected;
  constructor(public payload: number[]) { }
}

export class PaginateMacrorregiao implements Action {
  readonly type = MacrorregioesActionTypes.PaginateMacrorregiao;
  constructor(public payload: Pagination) { }
}

export class MacrorregiaoPaginated implements Action {
  readonly type = MacrorregioesActionTypes.MacrorregiaoPaginated;
  constructor(public payload: ApiResponse<Macrorregiao>) { }
}

export class MacrorregiaoRequestDone implements Action {
  readonly type = MacrorregioesActionTypes.MacrorregiaoRequestDone;
  constructor() { }
}

export class MacrorregiaoRequestFailed implements Action {
  readonly type = MacrorregioesActionTypes.MacrorregiaoRequestFailed;
  constructor() { }
}

export class DownloadMacrorregiao implements Action {
  readonly type = MacrorregioesActionTypes.DownloadMacrorregiao;
  constructor(public payload: Pagination) {
  }
}

export type MacrorregioesActions = Macrorregioes
  | LoadMacrorregiao
  | MacrorregiaoLoaded  
  | MacrorregiaoSelected
  | MacrorregioesSelected
  | MacrorregiaoUnselected
  | PaginateMacrorregiao
  | MacrorregiaoPaginated
  | MacrorregiaoRequestDone
  | DownloadMacrorregiao
  | MacrorregiaoRequestFailed
;