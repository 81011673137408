import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LazyGridTableComponent } from './lazy-grid-table/lazy-grid-table.component';
import { LazyGridPaginationComponent } from './lazy-grid-pagination/lazy-grid-pagination.component';
import { MaterialModule } from '@funceme/material';
import { LazyGridTdTextComponent } from './lazy-grid-td-text/lazy-grid-td-text.component';
import { DynamicOutlet } from './dynamic-outlet';
import { LazyGridTdBoolComponent } from './lazy-grid-td-bool/lazy-grid-td-bool.component';
import { LazyGridTdActionComponent } from './lazy-grid-td-action/lazy-grid-td-action.component';

@NgModule({
  declarations: [
    LazyGridTableComponent, 
    LazyGridPaginationComponent, 
    LazyGridTdTextComponent, 
    DynamicOutlet, 
    LazyGridTdBoolComponent, 
    LazyGridTdActionComponent
  ],
  imports: [
    CommonModule,
    MaterialModule
  ],
  exports: [
    LazyGridTableComponent,
    LazyGridPaginationComponent,
    LazyGridTdTextComponent,
    LazyGridTdBoolComponent,
    LazyGridTdActionComponent
  ],
  entryComponents: [
    LazyGridTdTextComponent, 
    LazyGridTdBoolComponent,
    LazyGridTdActionComponent
  ]
})
export class LazyGridModule { }
