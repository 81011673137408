import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Pagination } from '../../pagination.model';
import { GenericFacade } from '../../generic-facade';
import { Subscription } from 'rxjs';


@Component({
  selector: 'lazy-grid-pagination',
  templateUrl: './lazy-grid-pagination.component.html',
  styleUrls: ['./lazy-grid-pagination.component.css']
})
export class LazyGridPaginationComponent implements OnInit, OnDestroy {

  private subscriptions = new Subscription();

  @Input() pagination: Pagination;
  @Input() facade: GenericFacade;  

  public from: number = null;
  public to: number = null;
  public total: number = null;
  private total_pages: number = null; 

  constructor() { }

  ngOnInit() 
  {
    this.subscriptions.add(      
      this.facade.metaData$.subscribe(value => {
        if (!value) return;
        this.pagination = {... value.request.query_params};

        this.from = (this.pagination.paginator.page - 1) * this.pagination.paginator.limit + 1;
        this.to = (this.pagination.paginator.page) * this.pagination.paginator.limit;            
      })
    ).add(
      this.facade.total$.subscribe(value => {
        if (!value) return;
        this.total = value;

        if (this.pagination)
          this.total_pages = Math.ceil(this.total / this.pagination.paginator.limit);
        
        // if (this.total == 0) {
        //   this.from = 0;
        //   this.to = 0;
        // } else if (this.to > this.total)  
        //   this.to = this.total;  
      })
    );
  }

  firstPage()
  {
    this.pagination.paginator.page = 1;    
    this.facade.paginate(this.pagination);
  }

  lastPage()
  {
    this.pagination.paginator.page = this.total_pages;
    this.facade.paginate(this.pagination);
  }

  private hasNextPage(): boolean
  {
    return this.pagination.paginator.page + 1 <= this.total_pages;
  }

  nextPage()
  {
    if (!this.hasNextPage())
      return;
        
    this.pagination.paginator.page += 1;
    this.facade.paginate(this.pagination);
  }

  private hasPreviousPage(): boolean
  {
    return this.pagination.paginator.page - 1 >= 1;
  }

  previousPage()
  {
    if (!this.hasPreviousPage())
      return;
      
    this.pagination.paginator.page -= 1;
    this.facade.paginate(this.pagination);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
