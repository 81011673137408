import { Component, OnInit, OnDestroy } from '@angular/core';
import { Config, ConfigFacade } from '@funceme/core-app';
import { ActivatedRoute } from '@angular/router';
import { NavigatorService } from '../../../shared/services/navigator.service';
import { Subscription } from 'rxjs';
import { Mapa } from '../../../shared/models/mapa.model';
import { CalendarioItem } from '../../../shared/models/calendario.model';
import { CalendarioService } from '../../../shared/services/calendario.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-calendario-dia',
  templateUrl: './calendario-dia.component.html',
  styleUrls: ['./calendario-dia.component.css']
})
export class CalendarioDiaComponent implements OnInit, OnDestroy {

  public loading: boolean = false;
  public mapa: Mapa;
  public hasPrevious: boolean = true;
  public hasNext: boolean = true;

  public data_atualizacao:string = '';

  public dados: CalendarioItem[] = [];
  public destaques: CalendarioItem[] = [];

  public config: Config;
  public routeParams;

  private subscription: Subscription;

  constructor( 
    private route: ActivatedRoute,
    private navigator: NavigatorService,
    private configFacade: ConfigFacade,
    private calendarioFacade: CalendarioService,
    private location: Location
  ) { 
    this.subscription = this.configFacade.current$.subscribe(value => {
        
      this.routeParams = this.location.path().split('/');

      this.hasPrevious = this.navigator.hasPreviousDay();
      this.hasNext = this.navigator.hasNextDay();

      if (this.config != value && value.ano && value.mes && value.dia)
        this.make(value);
    });    
  }

  ngOnInit() {
    this.navigator.parse('dia', this.route.snapshot);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  backward() {
    this.navigator.dayBackward();
  }

  forward() {
    this.navigator.dayForward();
  }

  disableButtons(value){
    if (value)
      this.hasPrevious = false;
    else
      this.hasPrevious = this.navigator.hasPreviousDay();

    if (value)
      this.hasNext = false;
    else
      this.hasNext = this.navigator.hasNextDay();
  }

  up() {
    this.navigator.up();
  }

  private make(config: Config){
    this.disableButtons(true);
    this.config = config;
    
    if (this.config && this.config.dia && !this.checkIfEqual(this.config, this.routeParams))
    {
        return;
    }

    this.dados = [];
    this.data_atualizacao = '';

    let _metrica = (this.config.metrica == 'media')? 'thiessen' : 'chuva';

    let produtoNome = '';

    if (this.config.produto == 'reservatorios')
    {
      produtoNome = 'acudes';
    }
    else
    {
      produtoNome = this.config.produto;
    }

    this.mapa = {
      ativo: true,
      label: null,
      link: null,
      path: 'https://cdn.funceme.br/calendario/chuvas/' 
        + produtoNome + '/'
        + _metrica + '/anos/' 
        + this.config.ano + '/' 
        + this.config.mes + '/'
        + this.config.dia + '/mapa.gif',
      date: new Date(this.config.ano,this.config.mes-1,this.config.dia),
    }

    this.loading = true;

    this.calendarioFacade.get(this.config).subscribe(response => {
      this.dados = response.data.list;
      this.data_atualizacao = response.data.data_processamento;
      this.loading = false;

      this.destaques = this.dados.sort(
        (a,b) => {
          if (this.config.metrica == 'maxima')
            return b.observado - a.observado
          else 
            return b.media - a.media
        }).slice(0,10);
    }, (error) => {
      this.loading = false;
    });
    this.disableButtons(false);
  }
  
  checkIfEqual(config, params) {
      return (
        // config.produto == params[2] &&
        config.metrica == params[3] &&
        config.ano == params[4] &&
        config.mes == params[5] &&
        config.dia == params[6]
      )
  }
}
