import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MunicipioFacade } from '../../../state/municipio/municipio.facade';
import { MunicipioModalComponent } from '../municipio-modal/municipio-modal.component';

@Component({
  selector: 'municipio-modal-selector',
  templateUrl: './municipio-modal-selector.component.html',
  styleUrls: ['./municipio-modal-selector.component.css']
})
export class MunicipioModalSelectorComponent implements OnInit {
  
  private subscriptions = new Subscription();
  private dialogRef: MatDialogRef<MunicipioModalComponent, any>;
  public nome: string = '';

  constructor(
    public dialog: MatDialog, 
    private facade: MunicipioFacade
  ) {}

  ngOnInit() {
    this.subscriptions.add(
      this.facade.current$.subscribe(value => {
        if (this.dialogRef && value && value.nome != this.nome)
          this.dialogRef.close();
        if (value)
          this.nome = value.nome;
      })
    );
  }

  openModal(): void 
  {   
    //this.facade.select(null);    

    this.dialogRef = this.dialog.open(MunicipioModalComponent, {
      width: '800px',
      data: null
    });    

    this.dialogRef.afterClosed().subscribe(result => {
      
    });
  }
}