import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { PostoFacade } from '../../../state/posto/posto.facade';

@Component({
  selector: 'app-posto-grid',
  templateUrl: './posto-grid.component.html',
  styleUrls: ['./posto-grid.component.css']
})
export class PostoGridComponent implements OnInit {

  private subscriptions = new Subscription();

  public postos = []

  public displayedColumns: string[] = [
    'nome', 
    'municipio', 
    'bacia', 
    'lon', 
    'lat', 
    'status',
    'dados',
    'grafico'
  ];

  public dataSource = new MatTableDataSource<any>();

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  constructor(private postoFacade: PostoFacade) { }

  ngOnInit() 
  {
    this.dataSource.paginator = this.paginator;
    this.dataSource.data = [];

    this.postoFacade.all$.subscribe((values) => {      
        this.dataSource.data = values;
    });

    this.subscriptions.add(this.postoFacade.all$.subscribe(values => {
      this.dataSource.data = values;
    }));
  }

  public applyFilter(event) 
  {
    // if (event.key === "Enter") { 
    //     console.log('Enter');
    // } else if (event.which === 27) {
    //     this.localizarField.nativeElement.value = '';
    // }

    this.filter(event.target.value)
  }

  public filter(value) {
    this.dataSource.data = this.postos.filter(posto =>
      posto.nome.toLowerCase().search(value.toLowerCase()) != -1
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
