import * as fromOrgao from './orgao.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Orgao, emptyOrgao } from './orgao.model';

export const selectOrgaostate = createFeatureSelector<fromOrgao.OrgaoState>('orgao');

export const selectOrgaoIds = createSelector(
  selectOrgaostate,
  fromOrgao.selectOrgaoIds
);

export const selectOrgaoEntities = createSelector(
  selectOrgaostate,
  fromOrgao.selectOrgaoEntities
);

export const selectAllOrgaos = createSelector(
  selectOrgaostate,
  fromOrgao.selectAllOrgaos
);

export const selectMetaDataOrgaos = createSelector(
  selectOrgaostate,
  fromOrgao.getMetaDataOrgaos
);

export const selectTotalOrgaos = createSelector(
  selectOrgaostate,
  fromOrgao.getTotalOrgaos
); 

export const selectCurrentOrgaoId = createSelector(
  selectOrgaostate,
  fromOrgao.getSelectedOrgaoId
);

export const selectLoadingOrgaos = createSelector(
  selectOrgaostate,
  fromOrgao.getLoadingOrgaos
); 

export const selectCurrentOrgao = createSelector(
  selectOrgaoEntities,
  selectCurrentOrgaoId,
  (OrgaoEntities, OrgaoId) => {
    return OrgaoId ? OrgaoEntities[OrgaoId] : emptyOrgao;
  }
);

export const selectCurrentOrgaoIds = createSelector(
  selectOrgaostate,
  fromOrgao.getSelectedOrgaoIds
);

export const selectCurrentOrgaos = createSelector(
  selectOrgaoEntities,
  selectCurrentOrgaoIds,
  (OrgaoEntities, OrgaoIds) => {

    let list: Orgao[] = [];

    OrgaoIds.forEach(element => {
      list.push(OrgaoEntities[element]);
    });

    return list;
  }
);