import * as fromBaciaFunceme from './bacia-funceme.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { BaciaFunceme, emptyBaciaFunceme } from './bacia-funceme.model';

export const selectBaciasFuncemetate = createFeatureSelector<fromBaciaFunceme.BaciaFuncemeState>('baciaFunceme');

export const selectBaciaFuncemeIds = createSelector(
  selectBaciasFuncemetate,
  fromBaciaFunceme.selectBaciaFuncemeIds
);

export const selectBaciaFuncemeEntities = createSelector(
  selectBaciasFuncemetate,
  fromBaciaFunceme.selectBaciaFuncemeEntities
);

export const selectAllBaciasFunceme = createSelector(
  selectBaciasFuncemetate,
  fromBaciaFunceme.selectAllBaciasFunceme
);

export const selectMetaDataBaciasFunceme = createSelector(
  selectBaciasFuncemetate,
  fromBaciaFunceme.getMetaDataBaciasFunceme
);

export const selectTotalBaciasFunceme = createSelector(
  selectBaciasFuncemetate,
  fromBaciaFunceme.getTotalBaciasFunceme
); 

export const selectCurrentBaciaFuncemeId = createSelector(
  selectBaciasFuncemetate,
  fromBaciaFunceme.getSelectedBaciaFuncemeId
);

export const selectLoadingBaciasFunceme = createSelector(
  selectBaciasFuncemetate,
  fromBaciaFunceme.getLoadingBaciasFunceme
); 

export const selectCurrentBaciaFunceme = createSelector(
  selectBaciaFuncemeEntities,
  selectCurrentBaciaFuncemeId,
  (BaciaFuncemeEntities, BaciaFuncemeId) => {
    return BaciaFuncemeId ? BaciaFuncemeEntities[BaciaFuncemeId] : emptyBaciaFunceme;
  }
);

export const selectCurrentBaciaFuncemeIds = createSelector(
  selectBaciasFuncemetate,
  fromBaciaFunceme.getSelectedBaciaFuncemeIds
);

export const selectCurrentBaciasFunceme = createSelector(
  selectBaciaFuncemeEntities,
  selectCurrentBaciaFuncemeIds,
  (BaciaFuncemeEntities, BaciaFuncemeIds) => {

    let list: BaciaFunceme[] = [];

    BaciaFuncemeIds.forEach(element => {
      list.push(BaciaFuncemeEntities[element]);
    });

    return list;
  }
);