import { Action } from '@ngrx/store';
import { RegiaoHidrografica } from './regiao-hidrografica.model';
import { Pagination, ApiResponse } from '../../generics/pagination.model';

export enum RegioesHidrograficasActionTypes {
  RegiaoHidrograficaAction = '[RegiaoHidrografica] Action',
  LoadRegiaoHidrografica = '[RegiaoHidrografica] Load Data',
  RegiaoHidrograficaLoaded = '[RegiaoHidrografica] Data Loaded',
  RegiaoHidrograficaSelected = '[RegiaoHidrografica] Selected',
  RegioesHidrograficasSelected = '[RegiaoHidrografica] Multi Selected',
  RegiaoHidrograficaUnselected = '[RegiaoHidrografica] Unselected',
  PaginateRegiaoHidrografica = '[RegiaoHidrografica] Paginate',
  RegiaoHidrograficaPaginated = '[RegiaoHidrografica] Paginated',
  RegiaoHidrograficaRequestDone = '[RegiaoHidrografica] RequestDone',
  RegiaoHidrograficaRequestFailed = '[RegiaoHidrografica] Request Failed',
  DownloadRegiaoHidrografica = '[RegiaoHidrografica] Download',
}

export class RegioesHidrograficas implements Action {
  readonly type = RegioesHidrograficasActionTypes.RegiaoHidrograficaAction;
}

export class LoadRegiaoHidrografica implements Action {
  readonly type = RegioesHidrograficasActionTypes.LoadRegiaoHidrografica;
  constructor() { }
}

export class RegiaoHidrograficaLoaded implements Action {
  readonly type = RegioesHidrograficasActionTypes.RegiaoHidrograficaLoaded;
  constructor(public payload: RegiaoHidrografica[]) { }
}

export class RegiaoHidrograficaSelected implements Action {
  readonly type = RegioesHidrograficasActionTypes.RegiaoHidrograficaSelected;
  constructor(public payload: number) { }
}

export class RegioesHidrograficasSelected implements Action {
  readonly type = RegioesHidrograficasActionTypes.RegioesHidrograficasSelected;
  constructor(public payload: number[]) { }
}

export class RegiaoHidrograficaUnselected implements Action {
  readonly type = RegioesHidrograficasActionTypes.RegiaoHidrograficaUnselected;
  constructor(public payload: number[]) { }
}

export class PaginateRegiaoHidrografica implements Action {
  readonly type = RegioesHidrograficasActionTypes.PaginateRegiaoHidrografica;
  constructor(public payload: Pagination) { }
}

export class RegiaoHidrograficaPaginated implements Action {
  readonly type = RegioesHidrograficasActionTypes.RegiaoHidrograficaPaginated;
  constructor(public payload: ApiResponse<RegiaoHidrografica>) { }
}

export class RegiaoHidrograficaRequestDone implements Action {
  readonly type = RegioesHidrograficasActionTypes.RegiaoHidrograficaRequestDone;
  constructor() { }
}

export class RegiaoHidrograficaRequestFailed implements Action {
  readonly type = RegioesHidrograficasActionTypes.RegiaoHidrograficaRequestFailed;
  constructor() { }
}

export class DownloadRegiaoHidrografica implements Action {
  readonly type = RegioesHidrograficasActionTypes.DownloadRegiaoHidrografica;
  constructor(public payload: Pagination) {
  }
}

export type RegioesHidrograficasActions = RegioesHidrograficas
  | LoadRegiaoHidrografica
  | RegiaoHidrograficaLoaded  
  | RegiaoHidrograficaSelected
  | RegioesHidrograficasSelected
  | RegiaoHidrograficaUnselected
  | PaginateRegiaoHidrografica
  | RegiaoHidrograficaPaginated
  | RegiaoHidrograficaRequestDone
  | DownloadRegiaoHidrografica
  | RegiaoHidrograficaRequestFailed
;