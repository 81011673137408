import { Component, OnInit } from '@angular/core';
import { Config, ConfigFacade } from '@funceme/core-app';
import { Subscription } from 'rxjs';
import {FormControl} from '@angular/forms';


@Component({
  selector: 'app-calendario-app-date-picker',
  templateUrl: './calendario-app-date-picker.component.html',
  styleUrls: ['./calendario-app-date-picker.component.css']
})
export class CalendarioAppDatePickerComponent implements OnInit {

  public data_mapa:string = '';
  public today = new Date();
  public date = new FormControl(this.today);
  public config: Config;

  private subscription: Subscription;


  constructor(private configFacade: ConfigFacade)
  {
    this.subscription = this.configFacade.current$.subscribe(value => {

      this.config = value;

      this.data_mapa = this.config.dia+"/"+this.config.mes+"/"+this.config.ano;
      this.date.setValue(new Date(this.config.ano+"/"+this.config.mes+"/"+this.config.dia));
    });    
  }

  ngOnInit() {
  }

  dateChange(event) {
    let date = event.value as Date
    this.config.ano = date.getFullYear()
    this.config.mes = date.getMonth() +1
    this.config.dia = date.getDate()
    this.configFacade.save(this.config);
  }

}
