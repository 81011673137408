import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrgaoSelectComponent } from './orgao-select/orgao-select.component';
import { CoreDataModule } from '../../core-app.module';

@NgModule({
  declarations: [
    OrgaoSelectComponent
  ],
  imports: [
    CommonModule,
    CoreDataModule
  ],
  exports: [
    OrgaoSelectComponent
  ]
})
export class OrgaoModule { }
