import { Component, Input, AfterViewInit } from '@angular/core';
import * as Highcharts from 'highcharts';
import { PluviometriaNormalizadaFunceme, emptyPluviometriaNormalizadaFunceme } from '../../../state/pluviometria-normalizada-funceme/pluviometria-normalizada-funceme.model';

@Component({
  selector: 'pluviometria-normalizada-funceme-graph',
  templateUrl: './pluviometria-normalizada-funceme-graph.component.html',
  styleUrls: ['./pluviometria-normalizada-funceme-graph.component.css']
})
export class PluviometriaNormalizadaFuncemeGraphComponent {

  public updateFlag = false;

  public _data: PluviometriaNormalizadaFunceme[];

  @Input()
  set data(value:PluviometriaNormalizadaFunceme[]){
    this._data = value;
    this.draw();
  };

  public highcharts = Highcharts;
  public chartOptions = {   
    chart: {
       type: 'column',
       width: 750,
       height: 320,
       reflow: true,
       plotBorderColor: '#C9C9C9',
       plotBorderWidth: 2,
    },
    colors: ['#468847'],
    title: {
       text: ""
    },
    subtitle: {
        text: null
    },
    credits: {
        enabled: true,
        style: {
            color: '#4F4F4F',
        },
        text: '',
    },
    xAxis:{
       categories:[],
       tickmarkPlacement: 'on',
       title: {
           enabled: false
       },
    },
    yAxis: {          
       title:{
          text:"Precipitação (mm)"
       },
       max: 0
    },
    tooltip: {
      //  headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
      //  pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}%</b> of total<br/>',
       valueSuffix:" (mm)",
       shared: true,
    },
    plotOptions: {
        area: {
            lineColor: '#666666',
            lineWidth: 1,
            marker: {
                enabled: false
            },
        },
    },
    series: [
       {
          name: 'Precipitação',
          data: []
       }
    ]
  };

  constructor() { }

  private draw() 
  {    
    let title = '10 maiores chuvas ';
    let xLabels = [];
    let data = []
    let maxValue = emptyPluviometriaNormalizadaFunceme;
    
    if (this._data && this._data.length) {
      this._data.map((value) => value.valor = parseFloat(value.valor+''));
      
      maxValue = this._data.reduce((value, current) => (value.valor > current.valor)? value : current);
      
      this._data.map(dado => {
        xLabels.push(dado.data);
        data.push(dado.valor);
      });
    }

    //this.chartOptions.title.text = title;   
    this.chartOptions.xAxis.categories = xLabels;
    this.chartOptions.yAxis.max = maxValue.valor+5;
    this.chartOptions.series[0].data = data;

    this.updateFlag = true;
  }
}