export { CoreDataModule }       from './lib/core-app.module';

export { Config, emptyConfig, ConfigProduto, ConfigPeriodo, ConfigMetrica }  from './lib/state/config/config.model';

export { ConfigFacade }         from './lib/state/config/config.facade';

export { Uf }                   from './lib/state/uf/uf.model';
export { UfService }            from './lib/state/uf/uf.service';
export { UfFacade }             from './lib/state/uf/uf.facade';
export { UfModule }             from './lib/components/uf/uf.module';

export { Posto }                from './lib/state/posto/posto.model';
export { PostoService }         from './lib/state/posto/posto.service';
export { PostoFacade }          from './lib/state/posto/posto.facade';
export { PostoModule }          from './lib/components/posto/posto.module';

export { Orgao }                from './lib/state/orgao/orgao.model';
export { OrgaoService }         from './lib/state/orgao/orgao.service';
export { OrgaoFacade }          from './lib/state/orgao/orgao.facade';
export { OrgaoModule }          from './lib/components/orgao/orgao.module';

export { Municipio }            from './lib/state/municipio/municipio.model';
export { MunicipioService }     from './lib/state/municipio/municipio.service';
export { MunicipioFacade }      from './lib/state/municipio/municipio.facade';
export { MunicipioModule }      from './lib/components/municipio/municipio.module';

export { BaciaFunceme }         from './lib/state/bacia-funceme/bacia-funceme.model';
export { BaciaFuncemeService }  from './lib/state/bacia-funceme/bacia-funceme.service';
export { BaciaFuncemeFacade }   from './lib/state/bacia-funceme/bacia-funceme.facade';
export { BaciaFuncemeModule }   from './lib/components/bacia-funceme/bacia-funceme.module';

export { PluviometriaNormalizadaFunceme }         from './lib/state/pluviometria-normalizada-funceme/pluviometria-normalizada-funceme.model';
export { PluviometriaNormalizadaFuncemeService }  from './lib/state/pluviometria-normalizada-funceme/pluviometria-normalizada-funceme.service';
export { PluviometriaNormalizadaFuncemeFacade }   from './lib/state/pluviometria-normalizada-funceme/pluviometria-normalizada-funceme.facade';
export { PluviometriaNormalizadaFuncemeModule }   from './lib/components/pluviometria-normalizada-funceme/pluviometria-normalizada-funceme.module';

export { RegiaoHidrografica }         from './lib/state/regiao-hidrografica/regiao-hidrografica.model';
export { RegiaoHidrograficaService }  from './lib/state/regiao-hidrografica/regiao-hidrografica.service';
export { RegiaoHidrograficaFacade }   from './lib/state/regiao-hidrografica/regiao-hidrografica.facade';
export { RegiaoHidrograficaModule }   from './lib/components/regiao-hidrografica/regiao-hidrografica.module';

export { Macrorregiao }            from './lib/state/macrorregiao/macrorregiao.model';
export { MacrorregiaoService }     from './lib/state/macrorregiao/macrorregiao.service';
export { MacrorregiaoFacade }      from './lib/state/macrorregiao/macrorregiao.facade';
export { MacrorregiaoModule }      from './lib/components/macrorregiao/macrorregiao.module';

export { GenericFacade }        from './lib/generics/generic-facade';
