import { Component, EventEmitter, Output } from '@angular/core';

import * as _moment from 'moment';
import {default as _rollupMoment, Moment} from 'moment';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { _MONTH_FORMAT } from '../datepicker-month/datepicker-month.component';
import { FormControl } from '@angular/forms';

const moment = _rollupMoment || _moment;
export const _DAY_FORMAT = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  }
};

@Component({
  selector: 'datepicker-day',
  templateUrl: './datepicker-day.component.html',
  styleUrls: ['./datepicker-day.component.css'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_DATE_FORMATS, useValue: _DAY_FORMAT},
  ]
})
export class DatepickerDayComponent {

  public dataMin: Date = new Date('1900/1/1');
  public dataMax: Date = new Date();

  public inputDate: string;

  public date = new FormControl(moment());

  @Output() selected: EventEmitter<Moment> = new EventEmitter();

  constructor() { }

  keyUp(event: KeyboardEvent) 
  {
    event.preventDefault();

    if (event.keyCode == 13) 
        this.selected.emit(this.date.value);
  }

  chosenYearHandler(normalizedYear: Moment, datepicker: MatDatepicker<Moment>) 
  {
    const ctrlValue = this.date.value;

    if (!ctrlValue) return;

    ctrlValue.year(normalizedYear.year());
    this.date.setValue(ctrlValue);
  }

  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) 
  {
    const ctrlValue = this.date.value;

    if (!ctrlValue) return;

    ctrlValue.month(normalizedMonth.month());
    this.date.setValue(ctrlValue);
  }

  chosenDayHandler(event, datepicker: MatDatepicker<Moment>) 
  {
    const ctrlValue = this.date.value;
    let normalizedDay = event.value;

    if (!ctrlValue || !normalizedDay) return;
    
    ctrlValue.date(normalizedDay.date());
    this.date.setValue(ctrlValue);

    datepicker.close();

    this.selected.emit(ctrlValue);    
  }
}