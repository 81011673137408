import { Component, OnInit } from '@angular/core';
import { NavigatorService } from '../../../shared/services/navigator.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-calendario-quadrimestral',
  templateUrl: './calendario-quadrimestral.component.html',
  styleUrls: ['./calendario-quadrimestral.component.css']
})
export class CalendarioQuadrimestralComponent implements OnInit {
  
  constructor(
    private navigator: NavigatorService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.navigator.parse('quadrimestral', this.route.snapshot);
  }
}
