import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Config } from '@funceme/core-app';
import { RpcCalendarioResponse } from '../models/calendario.model';

@Injectable({
  providedIn: 'root'
})
export class CalendarioService {

  private apiPath = 'rpc/v1/calendario';

  constructor(
    private http: HttpClient
  ) { }

  getUrl() {
    return `${environment.apiEndpoint}${this.apiPath}`;
  }

  get(config: Config) {

    if (config.periodo == 'dia' && config.produto == 'municipios' && config.metrica == 'maxima')
    {
      return this.http.get<RpcCalendarioResponse>(this.getUrl() + `?produto=${config.produto}&periodo=${config.periodo}&metrica=${config.metrica}&ano=${config.ano}&mes=${config.mes}&dia=${config.dia}&cache=no`);
    }
    return this.http.get<RpcCalendarioResponse>(this.getUrl() + `?produto=${config.produto}&periodo=${config.periodo}&metrica=${config.metrica}&ano=${config.ano}&mes=${config.mes}&dia=${config.dia}`);
  }
}