import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/nx';
import { map } from 'rxjs/operators';

import { BaciaFunceme } from './bacia-funceme.model';
import { BaciaFuncemeService } from './bacia-funceme.service';

import {
  LoadBaciasFunceme, BaciasFuncemeLoaded,
  BaciaFuncemeActionTypes,
  BaciasFuncemePaginated,
  PaginateBaciasFunceme,
  BaciasFuncemeRequestDone,
  BaciasFuncemeRequestFailed
} from './bacia-funceme.actions';
import { BaciaFuncemeState } from './bacia-funceme.reducer';
import { NotificationsService } from '../../notifications/notifications.service';
import { BaciaFuncemeFacade } from './bacia-funceme.facade';
import { ApiResponse, paginationEquals } from '../../generics/pagination.model';

@Injectable({providedIn: 'root'})
export class BaciaFuncemeEffects 
{
  //@Effect() effect$ = this.actions$.ofType(BaciasFuncemeActionTypes.BaciasFuncemeAction);

  @Effect()
  loadBaciasFunceme$ = this.dataPersistence.fetch(BaciaFuncemeActionTypes.LoadBaciasFunceme, {
    run: (action: LoadBaciasFunceme, state: any) => {

      if(Object.keys(state.baciaFunceme.entities).length > 0){
        let allBaciasFunceme;

        this.baciaFuncemeFacade.all$.subscribe(baciafunceme => allBaciasFunceme = baciafunceme )
        return new BaciasFuncemeLoaded(allBaciasFunceme);
      }

      return this.baciaFuncemeService
        .all()
        .pipe(
          map((res: BaciaFunceme[]) => new BaciasFuncemeLoaded(res['data']['list']))
        )
    },

    onError: (action: LoadBaciasFunceme, error) => {
      this.notifyError.emit(error.statusText);
      return new BaciasFuncemeRequestFailed;
    }
  });

  @Effect()
  paginateBaciasFunceme$ = this.dataPersistence.fetch(BaciaFuncemeActionTypes.PaginateBaciasFunceme, {
    run: (action: PaginateBaciasFunceme, state: any) => {

      if (state.baciaFunceme.last_pagination && paginationEquals(action.payload, JSON.parse(state.baciaFunceme.last_pagination))) 
        return new BaciasFuncemeRequestDone;

      return this.baciaFuncemeService
        .paginate(action.payload)
        .pipe(
          map((res: ApiResponse<BaciaFunceme>) => new BaciasFuncemePaginated(res))
        )
    },

    onError: (action: PaginateBaciasFunceme, error) => {
      this.notifyError.emit(error.statusText);
      return new BaciasFuncemeRequestFailed;
    }
  });

  constructor(
    private actions$: Actions,
    private dataPersistence: DataPersistence<BaciaFuncemeState>,
    private baciaFuncemeService: BaciaFuncemeService,
    private baciaFuncemeFacade: BaciaFuncemeFacade,
    private notifyError: NotificationsService
  ) {}
}
