import { Point } from '../../generics/geom.model';

export interface RegiaoHidrografica {
    id: number;
    nome: string;
    uf: string;
    longitude: number;
    latitude: number;
    geom: Point
    
}

export const emptyRegiaoHidrografica: RegiaoHidrografica = {
    id: null,
    nome: null,
    uf: null,
    longitude: null,
    latitude: null,
    geom: null    
}
