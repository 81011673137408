import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CalendarioItem } from '../../../shared/models/calendario.model';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Config, emptyConfig, ConfigFacade } from '@funceme/core-app';
import limites from '../calendario-tabela/limites-categoria.json';
import { CalendarioAppGraficoComponent } from '../calendario-app-grafico/calendario-app-grafico.component';

@Component({
  selector: 'app-calendario-app-tabela',
  templateUrl: './calendario-app-tabela.component.html',
  styleUrls: ['./calendario-app-tabela.component.css']
})
export class CalendarioAppTabelaComponent implements OnInit {

  private _data = new BehaviorSubject<CalendarioItem[]>([]);

  private alldata;
  private limites = limites;

  @Input()
  set data(value) {
      this._data.next(value);
  };

  get data() {
    return this._data.getValue();
  }

  private _config = new BehaviorSubject<Config>(emptyConfig);

  @Input()
  set config(value) {
      this._config.next(value);
  };

  get config() {
    return this._config.getValue();
  }

  public title = 'Nome';

  public municipios: any[] = [];
  public regioes: any[] = [];

  public dataQuantis = [];

  public selectedMunicipio: string;
  public selectedRegiao: string;
  public filterNome: string;

  public displayedColumns: string[] = [];

  public dataSource = new MatTableDataSource<CalendarioItem>();

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(private configFacade: ConfigFacade, public dialog: MatDialog)
  {
    this.configFacade.findKey$.subscribe(value => {
      this.selectedRegiao = '';

      if (this.municipios && this.municipios.length > 0)
        this.selectedMunicipio = this.municipios.find(item => item.toLowerCase() == value.toLowerCase());
      else
        this.filterNome = value;

      this.applyFilter(this.filterNome);
    });
  }

  ngOnInit()
  {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.dataSource.data = [];
    this.dataSource.filterPredicate = (data: CalendarioItem, filter: string) => {

      if (filter == ';;')
        return false;

      const parts = filter.split(';');

      let _nome = false;
      let _municipio = false;
      let _regiao = false;

      if (parts[1] == '' || (data.municipio && data.municipio == parts[1]))
        _municipio = true;
      if (parts[2] == '' || (data.regiao && data.regiao == parts[2]))
        _regiao = true;
      if (parts[0] == '' || (data.nome.trim().toLowerCase().indexOf(parts[0]) != -1))
        _nome = true;

      if (_nome && _municipio && _regiao)
        return true;
      else
        return false;
    }

    this._data.subscribe(values => {
      this.dataSource.data = values;
      this.alldata = values;

      this.dataSource.data.forEach((data:any)=>{
        data.media_dia = data.observado
        data.observado_media = data.observado
      })

      if (this.config.periodo != 'dia' && (this.config.produto == 'ceara' || this.config.produto == 'macrorregioes')){
        let limites;

        if (this.config.periodo == 'ano')
          limites = this.limites[this.config.produto][this.config.periodo];
        else
          limites = this.limites[this.config.produto][this.config.periodo][this.config.mes];

        this.dataSource.data.forEach((data:any) =>{
          data.categoria = this.getCategoria(data.observado,limites[data.nome]);
          this.dataQuantis[data.nome] = {data:data.observado, limites:limites[data.nome]}
        })
      }

      this.municipios = [];
      this.regioes = [];

      values.map(item => {
        if (!!item.municipio && this.municipios.indexOf(item.municipio) == -1)
          this.municipios.push(item.municipio);

        if (!!item.regiao && this.regioes.indexOf(item.regiao) == -1)
          this.regioes.push(item.regiao);
      });

      this.municipios.sort();
      this.regioes.sort();

      this.selectedMunicipio = '';
      this.selectedRegiao = '';
      this.filterNome = '';

      this.applyFilter('');
    });

    this._config.subscribe(value => {
      this.displayedColumns = this.getDisplayedColumns(value);
      this.title = this.getTitle(value);
    });
  }

  applyFilter(filterValue: string) {
    if (!this.alldata && this.dataSource.data.length > 1){
      this.alldata = [];
      this.dataSource.data.forEach(d => this.alldata.push(d));
    }

    if (filterValue)
      this.filterNome = filterValue.trim().toLowerCase();
    else
      this.filterNome = '';

    if (this.alldata && this.filterNome + ';' + this.selectedMunicipio + ';' + this.selectedRegiao == ";;")
      this.dataSource.data = this.alldata;

    this.dataSource.filter = this.filterNome + ';' + this.selectedMunicipio + ';' + this.selectedRegiao;
  }

  public changedFilter()
  {
    if (!this.selectedMunicipio)
      this.selectedMunicipio = '';

    if (!this.selectedRegiao)
      this.selectedRegiao = '';


    this.applyFilter(this.filterNome);
  }

  private getDisplayedColumns(config: Config): string[]
  {
    if (config.metrica == 'maxima') {
      if (config.produto == 'municipios')

        if (config.periodo == 'dia')
          return ['nome', 'municipio', 'regiao', 'observado', 'grafico'];
        else
          return ['nome', 'municipio', 'regiao', 'normal', 'observado', 'desvio'];

      else if (config.produto == 'ceara')
        return ['nome', 'normal', 'observado', 'desvio'];

      else
        return ['nome', 'regiao', 'observado'];
    } else if (config.metrica == 'media') {
      if (config.produto == 'municipios') {

        if (config.periodo == 'dia')
          return ['nome', 'regiao', 'min', 'media_dia', 'max'];
        else
          return ['nome', 'regiao', 'normal', 'observado_media', 'desvio'];

      } else if (config.produto == 'ceara' || config.produto == 'macrorregioes') {
        if (config.periodo == 'dia')
          return ['nome', 'min', 'media_dia', 'max'];
        else
          return ['nome', 'normal', 'observado_media', 'desvio', 'categoria'];

      } else {
        if (config.periodo == 'dia')
          return ['nome', 'min', 'media_dia', 'max'];
        else
          return ['nome', 'normal', 'observado_media', 'desvio'];
      }
    }
  }

  private getTitle(config: Config): string
  {
    if (config.metrica == 'maxima' && config.produto == 'municipios')
      return 'Posto';

    switch (config.produto) {
      case 'municipios':
        return 'Município';
      case 'regioes':
        return 'Região hidrográfica';
      case 'bacias':
        return 'Bacia';
      case 'macrorregioes':
        return 'Macrorregião';
      case 'ceara':
        return 'Ceará';
    }
  }

  limpar() {
    this.configFacade.find('');
    this.filterNome = '';
    this.selectedMunicipio = '';
    this.selectedRegiao = '';
    this.applyFilter(this.filterNome);
  }

  getCategoria(media, limites ){
    if(media >= limites.superior)
      return {texto:"Acima da média",limites:limites,color:'#0064b4'}
    else if (media < limites.inferior)
      return {texto:"Abaixo da média",limites:limites,color:'#f73939'}
    else
      return {texto:"Em torno da média",limites:limites,color:'#5C9C5C'}
  }

  navegate(id){
    let series_baseref = "https://cdn.funceme.br/calendario/postos/"
    window.open(series_baseref + id + '.txt', '_blank');
  }

  openDialog(id,nome){
    const dialogRef = this.dialog.open(CalendarioAppGraficoComponent, {
      data: {id: id, nome: nome, mes:this.config.mes, ano: this.config.ano},
      width: "90%", maxWidth: "90%"
    });
  }

}
