import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { ConfigActions, ConfigActionTypes } from './config.actions';
import { Config } from './config.model';

export interface ConfigState extends EntityState<Config> {
  findKey: string;
}

export const adapter: EntityAdapter<Config> = createEntityAdapter<Config>({
  selectId: (config: Config) => config.id,
});

export const initialState: ConfigState = adapter.getInitialState({
  findKey: '',
});

export function configReducer(state = initialState, action: ConfigActions): ConfigState {

  switch (action.type) {

    case ConfigActionTypes.SaveConfig: {
      return adapter.upsertOne(action.payload, state);
    }

    case ConfigActionTypes.FindConfig: {
      return Object.assign({}, state, { findKey: action.payload });
    }

    default:
      return state;
  }
}

export const findKey = (state: ConfigState) => state.findKey;

// get the selectors
const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

// select the array of dado-sensor ids
export const selectConfigIds = selectIds;

// select the dictionary of dado-sensor entities
export const selectConfigEntities = selectEntities;

// select the array of dado-sensor
export const selectAllConfig = selectAll;

// select the total dado-sensor count
export const selectConfigTotal = selectTotal;