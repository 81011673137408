import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavigatorService } from '../../shared/services/navigator.service';

@Component({
  selector: 'app-postos-pluviometricos-index',
  templateUrl: './postos-pluviometricos-index.component.html',
  styleUrls: ['./postos-pluviometricos-index.component.css']
})
export class PostosPluviometricosIndexComponent implements OnInit {
  
  ngOnInit() {
    //this.navigator.parse('postos-index', this.route.snapshot); 
  }
}
