import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { UfActions, UfActionTypes } from './uf.actions';
import { Uf } from './uf.model';
import { ApiMetaData } from '../../generics/pagination.model';

/**
 * Interface to the part of the Store containing UfState
 * and other information related to UfData.
 */
export interface UfState extends EntityState<Uf> {
  selectedId: number;
  selectedIds: number[];
  loading: boolean;
  loaded_at: Date | null;
  meta_data: ApiMetaData;
  total: number,
  last_pagination: string
}

export const adapter: EntityAdapter<Uf> = createEntityAdapter<Uf>({
  selectId: (Uf: Uf) => Uf.id,
});

export const initialState: UfState = adapter.getInitialState({
  selectedId: null,
  selectedIds: [],
  loading: false,
  loaded_at: null,
  meta_data: null,
  total: null,
  last_pagination: null
});

export function ufReducer(state = initialState, action: UfActions): UfState {

  switch (action.type) {
    
    case UfActionTypes.UfSelected: {
      return Object.assign({}, state, { selectedId: action.payload });
    }

    case UfActionTypes.UfsSelected: {
      return Object.assign({}, state, { selectedIds: [...state.selectedIds, action.payload] });
    }

    case UfActionTypes.UfsUnselected: {
      return Object.assign({}, state, { 
        selectedUfIds: state.selectedIds.filter(id => !action.payload.includes(id))
      });
    }

    case UfActionTypes.LoadUfs: {
      return Object.assign({}, state, { loading: true });
    }

    case UfActionTypes.UfsLoaded: {
      return adapter.addAll(action.payload, Object.assign({}, state, { loading: false, loaded_at: new Date }));
    }

    case UfActionTypes.PaginateUfs: {
      return Object.assign({}, state, { loading: true });
    }

    case UfActionTypes.UfsPaginated: {   
      return adapter.addAll(action.payload.data.list, Object.assign({}, state, { 
        meta_data: action.payload.meta, 
        total: action.payload.data.total_results,
        loading: false,
        loaded_at: new Date().toISOString(),
        last_pagination: JSON.stringify(action.payload.meta.request.query_params)
      }));  
    }

    case UfActionTypes.UfsRequestDone:
    case UfActionTypes.UfsRequestFailed: {
      return Object.assign({}, state, { loading: false });
    }
    
    default:
      return state;
  }
}

export const getSelectedUfIds = (state: UfState) => state.selectedIds;
export const getSelectedUfId = (state: UfState) => state.selectedId;
export const getMetaDataUfs = (state: UfState) => state.meta_data;
export const getTotalUfs = (state: UfState) => state.total;
export const getLoadingUfs = (state: UfState) => state.loading;

// get the selectors
const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

// select the array of Uf ids
export const selectUfIds = selectIds;

// select the dictionary of Uf entities
export const selectUfEntities = selectEntities;

// select the array of Uf
export const selectAllUfs = selectAll;

// select the total Uf count
export const selectUfTotal = selectTotal;
