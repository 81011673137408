import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PostoGridComponent } from './posto-grid/posto-grid.component';
import { MaterialModule } from '@funceme/material';
import { PostoLgridComponent } from './posto-lgrid/posto-lgrid.component';
import { StateModule } from '../../state/state.module';
import { LazyGridModule } from '../../generics/lazy-grid/lazy-grid.module';
import { OrgaoSelectComponent } from '../orgao/orgao-select/orgao-select.component';
import { OrgaoModule } from '../orgao/orgao.module';
import { FormsModule } from '@angular/forms';
import { MunicipioModule } from '../municipio/municipio.module';
import { BaciaFuncemeModule } from '../bacia-funceme/bacia-funceme.module';
import { PostoModalComponent } from './posto-modal/posto-modal.component';
import { PostoModalSelectorComponent } from './posto-modal-selector/posto-modal-selector.component';
import { RegiaoHidrograficaModule } from '../regiao-hidrografica/regiao-hidrografica.module';
import { MacrorregiaoModule } from '../macrorregiao/macrorregiao.module';
import { CoreDataModule } from '../../core-app.module';
import { ZmatDatepickerModule } from '../../generics/zmat-datepicker/zmat-datepicker.module';

@NgModule({
  declarations: [
    PostoGridComponent, 
    PostoLgridComponent, 
    PostoModalComponent, 
    PostoModalSelectorComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    CoreDataModule,
    FormsModule,
    StateModule,
    LazyGridModule,
    OrgaoModule,
    MunicipioModule,
    BaciaFuncemeModule,
    MunicipioModule,
    RegiaoHidrograficaModule,
    MacrorregiaoModule,
    ZmatDatepickerModule
  ],
  exports: [
    PostoGridComponent,
    PostoLgridComponent,
    PostoModalSelectorComponent
  ],
  entryComponents: [
    OrgaoSelectComponent,
    PostoModalComponent
  ]
})
export class PostoModule { }
