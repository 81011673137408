import { Component, OnInit, Input } from '@angular/core';
import { SelectOneSchema } from '../../../generics/select-one/select-one.schema';
import { Pagination, getPaginationDefault } from '../../../generics/pagination.model';
import { UfFacade } from '../../../state/uf/uf.facade';
import { Uf } from '../../../state/uf/uf.model';

@Component({
  selector: 'uf-select',
  templateUrl: './uf-select.component.html',
  styleUrls: ['./uf-select.component.css']
})
export class UfSelectComponent implements OnInit {

  public schema: SelectOneSchema;
  private pagination: Pagination = getPaginationDefault();

  @Input() showItensWithIds: number[] = [];
  @Input() hideItensWithIds: number[] = [];

  constructor(public facade: UfFacade) { 
    this.pagination.paginator.limit = 999999;
  }

  ngOnInit() 
  {
    this.schema = {
      label: 'UF',
      placeholder: 'Selecionar UF',
      facade: this.facade,
      include_list: this.showItensWithIds,
      exclude_list: this.hideItensWithIds,
      getId(item: Uf) {
        return item.id;
      },
      getName(item: Uf) {
        return item.nome
      }
    };   

    this.facade.paginate(this.pagination);
  }
}
