import { Action } from '@ngrx/store';
import { Posto } from './posto.model';
import { Pagination, ApiResponse } from '../../generics/pagination.model';

export enum PostoActionTypes {
  PostosAction = '[Postos] Action',
  LoadPostos = '[Postos] Load Data',
  PostosLoaded = '[Postos] Data Loaded',
  PostoSelected = '[Posto] Selected',
  PostosSelected = '[Postos] Selected',
  PostosUnselected = '[Postos] Unselected',
  PaginatePostos = '[Postos] Paginate',
  PostosPaginated = '[Postos] Paginated',
  PostosRequestDone = '[Postos] RequestDone',
  PostosRequestFailed = '[Postos] Request Failed',
}

export class Postos implements Action {
  readonly type = PostoActionTypes.PostosAction;
}

export class LoadPostos implements Action {
  readonly type = PostoActionTypes.LoadPostos;
  constructor() { }
}

export class PostosLoaded implements Action {
  readonly type = PostoActionTypes.PostosLoaded;
  constructor(public payload: Posto[]) { }
}

export class PostoSelected implements Action {
  readonly type = PostoActionTypes.PostoSelected;
  constructor(public payload: number) { }
}

export class PostosSelected implements Action {
  readonly type = PostoActionTypes.PostosSelected;
  constructor(public payload: number[]) { }
}

export class PostosUnselected implements Action {
  readonly type = PostoActionTypes.PostosUnselected;
  constructor(public payload: number[]) { }
}

export class PaginatePostos implements Action {
  readonly type = PostoActionTypes.PaginatePostos;
  constructor(public payload: Pagination) { 
  }
}

export class PostosPaginated implements Action {
  readonly type = PostoActionTypes.PostosPaginated;
  constructor(public payload: ApiResponse<Posto>) { }
}

export class PostosRequestDone implements Action {
  readonly type = PostoActionTypes.PostosRequestDone;
  constructor() { }
}

export class PostosRequestFailed implements Action {
  readonly type = PostoActionTypes.PostosRequestFailed;
  constructor() { }
}

export type PostoActions = Postos
  | LoadPostos
  | PostosLoaded  
  | PostoSelected
  | PostosSelected
  | PostosUnselected
  | PaginatePostos
  | PostosPaginated
  | PostosRequestDone
  | PostosRequestFailed
;