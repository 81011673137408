import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { RegioesHidrograficasActions, RegioesHidrograficasActionTypes } from './regiao-hidrografica.actions';
import { RegiaoHidrografica } from './regiao-hidrografica.model';
import { ApiMetaData } from '../../generics/pagination.model';

/**
 * Interface to the part of the Store containing RegiaoHidrograficaState
 * and other information related to RegiaoHidrograficaData.
 */
export interface RegiaoHidrograficaState extends EntityState<RegiaoHidrografica> {
  selectedId: number;
  selectedIds: number[];
  loading: boolean;
  loaded_at: Date | null;
  meta_data: ApiMetaData;
  total: number;
  last_pagination: string
}

export const adapter: EntityAdapter<RegiaoHidrografica> = createEntityAdapter<RegiaoHidrografica>({
  selectId: (regiaoHidrografica: RegiaoHidrografica) => regiaoHidrografica.id
});

export const initialState: RegiaoHidrograficaState = adapter.getInitialState({
  selectedId: null,
  selectedIds: [],
  loading: false,
  loaded_at: null,
  meta_data: null,
  total: null,
  last_pagination: null
});

export function regiaoHidrograficaReducer(state = initialState, action: RegioesHidrograficasActions): RegiaoHidrograficaState {

  switch (action.type) {
    
    case RegioesHidrograficasActionTypes.RegiaoHidrograficaSelected: {
      return Object.assign({}, state, { selectedId: action.payload });
    }

    case RegioesHidrograficasActionTypes.RegioesHidrograficasSelected: {
      return Object.assign({}, state, { selectedIds: [...state.selectedIds, action.payload] });
    }

    case RegioesHidrograficasActionTypes.RegiaoHidrograficaUnselected: {
      return Object.assign({}, state, { 
        selectedRegiaoHidrograficaIds: state.selectedIds.filter(id => !action.payload.includes(id))
      });
    }

    case RegioesHidrograficasActionTypes.LoadRegiaoHidrografica: {
      return Object.assign({}, state, { loading: true });
    }

    case RegioesHidrograficasActionTypes.RegiaoHidrograficaLoaded: {
      return adapter.addAll(action.payload, Object.assign({}, state, { loading: false }));
    }

    case RegioesHidrograficasActionTypes.PaginateRegiaoHidrografica: {
      return Object.assign({}, state, { loading: true });
    }

    case RegioesHidrograficasActionTypes.RegiaoHidrograficaPaginated: {          
      return adapter.addAll(action.payload.data.list, Object.assign({}, state, { 
        meta_data: action.payload.meta, 
        total: action.payload.data.total_results,
        loading: false,
        loaded_at: new Date().toISOString(),
        last_pagination: JSON.stringify(action.payload.meta.request.query_params),
      }));  
    }

    case RegioesHidrograficasActionTypes.RegiaoHidrograficaRequestDone:
    case RegioesHidrograficasActionTypes.RegiaoHidrograficaRequestFailed: {
      return Object.assign({}, state, { loading: false });
    }
    
    default:
      return state;
  }
}

export const getSelectedRegiaoHidrograficaIds = (state: RegiaoHidrograficaState) => state.selectedIds;
export const getSelectedRegiaoHidrograficaId = (state: RegiaoHidrograficaState) => state.selectedId;
export const getMetaDataRegiaoHidrografica = (state: RegiaoHidrograficaState) => state.meta_data;
export const getTotalRegiaoHidrografica = (state: RegiaoHidrograficaState) => state.total;
export const getLoadingRegiaoHidrografica = (state: RegiaoHidrograficaState) => state.loading;

// get the selectors
const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

// select the array of RegiaoHidrografica ids
export const selectRegiaoHidrograficaIds = selectIds;

// select the dictionary of RegiaoHidrografica entities
export const selectRegiaoHidrograficaEntities = selectEntities;

// select the array of RegiaoHidrografica
export const selectAllRegiaoHidrografica = selectAll;

// select the total RegiaoHidrografica count
export const selectRegiaoHidrograficaTotal = selectTotal;
