import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'funceme-datepicker-decade',
  templateUrl: './datepicker-decade.component.html',
  styleUrls: ['./datepicker-decade.component.css']
})
export class DatepickerDecadeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
