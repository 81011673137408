import { Injectable } from '@angular/core';
import { ActionsSubject, select, Store } from '@ngrx/store';

import * as ConfigActions from './config.actions';
import { ConfigState } from './config.reducer';
import { selectCurrentConfig, findConfig } from './config.selectors';
import { Config } from './config.model';

@Injectable({ providedIn: 'root' })
export class ConfigFacade {

  current$  = this.store.pipe(select(selectCurrentConfig));
  findKey$     = this.store.pipe(select(findConfig));

  constructor(private store: Store<ConfigState>, private actions$: ActionsSubject) {}

  save(config: Config) {
    this.store.dispatch(new ConfigActions.SaveConfig(config));
  }

  find(key: string) {
    this.store.dispatch(new ConfigActions.FindConfig(key));
  }
}
