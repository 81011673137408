import { Injectable } from '@angular/core';
import { ActionsSubject, select, Store } from '@ngrx/store';
import * as MunicipioActions from './municipio.actions';
import { MunicipioState } from './municipio.reducer';
import { selectAllMunicipios, selectCurrentMunicipios, selectCurrentMunicipio, selectMetaDataMunicipios, selectTotalMunicipios, selectLoadingMunicipios } from './municipio.selectors';
import { Pagination } from '../../generics/pagination.model';
import { GenericFacade } from '../../generics/generic-facade';

@Injectable({ providedIn: 'root' })
export class MunicipioFacade implements GenericFacade
{
  all$ = this.store.pipe(select(selectAllMunicipios));
  current$ = this.store.pipe(select(selectCurrentMunicipio));
  currentList$ = this.store.pipe(select(selectCurrentMunicipios));
  metaData$ = this.store.pipe(select(selectMetaDataMunicipios));
  total$ = this.store.pipe(select(selectTotalMunicipios));
  loading$ = this.store.pipe(select(selectLoadingMunicipios));

  constructor(private store: Store<MunicipioState>, private actions$: ActionsSubject) {}

  selectOne(municipioId: number) {
    this.store.dispatch(new MunicipioActions.Municipioselected(municipioId));
  }

  selectMany(municipioIds: number[]) {
    this.store.dispatch(new MunicipioActions.MunicipiosSelected(municipioIds));
  }

  unselectMany(municipioIds: number[]) {
    this.store.dispatch(new MunicipioActions.MunicipiosUnselected(municipioIds));
  }

  load(reset: boolean) {
    this.store.dispatch(new MunicipioActions.LoadMunicipios());
  }

  paginate(pagination: Pagination) {
    this.store.dispatch(new MunicipioActions.PaginateMunicipios(pagination));
  }

  select(id: number) {
    this.selectOne(id);
  }

  pick(id: number) {}
  destroy(id: number) {};
  save(model: any) {};
  index() {};
  edit(id: number) {};
  show(id: number) {};
  create(): void {};
  refresh(): void {};
}
