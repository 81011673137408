import { Action } from '@ngrx/store';
import { Orgao } from './orgao.model';
import { Pagination, ApiResponse } from '../../generics/pagination.model';

export enum OrgaoActionTypes {
  OrgaosAction = '[Orgaos] Action',
  LoadOrgaos = '[Orgaos] Load Data',
  OrgaosLoaded = '[Orgaos] Data Loaded',
  OrgaoSelected = '[Orgao] Selected',
  OrgaosSelected = '[Orgaos] Selected',
  OrgaosUnselected = '[Orgaos] Unselected',
  PaginateOrgaos = '[Orgaos] Paginate',
  OrgaosPaginated = '[Orgaos] Paginated',
  OrgaosRequestDone = '[Orgaos] RequestDone',
  OrgaosRequestFailed = '[Orgaos] Request Failed',
}

export class Orgaos implements Action {
  readonly type = OrgaoActionTypes.OrgaosAction;
}

export class LoadOrgaos implements Action {
  readonly type = OrgaoActionTypes.LoadOrgaos;
  constructor() { }
}

export class OrgaosLoaded implements Action {
  readonly type = OrgaoActionTypes.OrgaosLoaded;
  constructor(public payload: Orgao[]) { }
}

export class OrgaoSelected implements Action {
  readonly type = OrgaoActionTypes.OrgaoSelected;
  constructor(public payload: number) { }
}

export class OrgaosSelected implements Action {
  readonly type = OrgaoActionTypes.OrgaosSelected;
  constructor(public payload: number[]) { }
}

export class OrgaosUnselected implements Action {
  readonly type = OrgaoActionTypes.OrgaosUnselected;
  constructor(public payload: number[]) { }
}

export class PaginateOrgaos implements Action {
  readonly type = OrgaoActionTypes.PaginateOrgaos;
  constructor(public payload: Pagination) { }
}

export class OrgaosPaginated implements Action {
  readonly type = OrgaoActionTypes.OrgaosPaginated;
  constructor(public payload: ApiResponse<Orgao>) { }
}

export class OrgaosRequestDone implements Action {
  readonly type = OrgaoActionTypes.OrgaosRequestDone;
  constructor() { }
}

export class OrgaosRequestFailed implements Action {
  readonly type = OrgaoActionTypes.OrgaosRequestFailed;
  constructor() { }
}

export type OrgaoActions = Orgaos
  | LoadOrgaos
  | OrgaosLoaded  
  | OrgaoSelected
  | OrgaosSelected
  | OrgaosUnselected
  | PaginateOrgaos
  | OrgaosPaginated
  | OrgaosRequestDone
  | OrgaosRequestFailed
;