import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { PostoActions, PostoActionTypes } from './posto.actions';
import { Posto } from './posto.model';
import { ApiMetaData } from '../../generics/pagination.model';

/**
 * Interface to the part of the Store containing PostoState
 * and other information related to PostoData.
 */
export interface PostoState extends EntityState<Posto> {
  selectedId: number;
  selectedIds: number[];
  loading: boolean;
  loaded_at: Date | null;
  meta_data: ApiMetaData; 
  total: number,
  last_pagination: string;
}

export const adapter: EntityAdapter<Posto> = createEntityAdapter<Posto>({
  selectId: (Posto: Posto) => Posto.id,
});

export const initialState: PostoState = adapter.getInitialState({
  selectedId: null,
  selectedIds: [],
  loading: false,
  loaded_at: null,
  meta_data: null,  
  total: null,
  last_pagination: null,
});

export function postoReducer(state = initialState, action: PostoActions): PostoState {

  switch (action.type) {
    
    case PostoActionTypes.PostoSelected: {
      return Object.assign({}, state, { selectedId: action.payload });
    }

    case PostoActionTypes.PostosSelected: {
      return Object.assign({}, state, { selectedIds: [...state.selectedIds, action.payload] });
    }

    case PostoActionTypes.PostosUnselected: {
      return Object.assign({}, state, { 
        selectedPostoIds: state.selectedIds.filter(id => !action.payload.includes(id))
      });
    }

    case PostoActionTypes.LoadPostos: {
      return Object.assign({}, state, { loading: true });
    }

    case PostoActionTypes.PostosLoaded: {
      return adapter.addAll(action.payload, Object.assign({}, state, { loading: false, loaded_at: new Date }));
    }

    case PostoActionTypes.PaginatePostos: {
      return Object.assign({}, state, { loading: true });
    }

    case PostoActionTypes.PostosPaginated: {   
      return adapter.addAll(action.payload.data.list, Object.assign({}, state, { 
        meta_data: action.payload.meta, 
        total: action.payload.data.total_results,
        loading: false,        
        loaded_at: new Date().toISOString(),
        last_pagination: JSON.stringify(action.payload.meta.request.query_params),
      }));  
    }

    case PostoActionTypes.PostosRequestDone: 
    case PostoActionTypes.PostosRequestFailed: 
    {
      return Object.assign({}, state, { loading: false });
    }
    
    default:
      return state;
  }
}

export const getSelectedPostoIds = (state: PostoState) => state.selectedIds;
export const getSelectedPostoId = (state: PostoState) => state.selectedId;
export const getMetaDataPostos = (state: PostoState) => state.meta_data;
export const getTotalPostos = (state: PostoState) => state.total;
export const getLoadingPostos = (state: PostoState) => state.loading;

// get the selectors
const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

// select the array of Posto ids
export const selectPostoIds = selectIds;

// select the dictionary of Posto entities
export const selectPostoEntities = selectEntities;

// select the array of Posto
export const selectAllPostos = selectAll;

// select the total Posto count
export const selectPostoTotal = selectTotal;
