import * as fromRegiaoHidrografica from './regiao-hidrografica.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RegiaoHidrografica, emptyRegiaoHidrografica } from './regiao-hidrografica.model';

export const selectRegiaoHidrograficaState = createFeatureSelector<fromRegiaoHidrografica.RegiaoHidrograficaState>('regiaoHidrografica');

export const selectRegiaoHidrograficaIds = createSelector(
  selectRegiaoHidrograficaState,
  fromRegiaoHidrografica.selectRegiaoHidrograficaIds
);

export const selectRegiaoHidrograficaEntities = createSelector(
  selectRegiaoHidrograficaState,
  fromRegiaoHidrografica.selectRegiaoHidrograficaEntities
);

export const selectAllRegiaoHidrografica = createSelector(
  selectRegiaoHidrograficaState,
  fromRegiaoHidrografica.selectAllRegiaoHidrografica
);

export const selectMetaDataRegiaoHidrografica = createSelector(
  selectRegiaoHidrograficaState,
  fromRegiaoHidrografica.getMetaDataRegiaoHidrografica
);

export const selectTotalRegiaoHidrografica = createSelector(
  selectRegiaoHidrograficaState,
  fromRegiaoHidrografica.getTotalRegiaoHidrografica
); 

export const selectCurrentRegiaoHidrograficaId = createSelector(
  selectRegiaoHidrograficaState,
  fromRegiaoHidrografica.getSelectedRegiaoHidrograficaId
);

export const selectLoadingRegiaoHidrografica = createSelector(
  selectRegiaoHidrograficaState,
  fromRegiaoHidrografica.getLoadingRegiaoHidrografica
); 

export const selectCurrentRegiaoHidrografica = createSelector(
  selectRegiaoHidrograficaEntities,
  selectCurrentRegiaoHidrograficaId,
  (RegiaoHidrograficaEntities, RegiaoHidrograficaId) => {
    return RegiaoHidrograficaId ? RegiaoHidrograficaEntities[RegiaoHidrograficaId] : emptyRegiaoHidrografica;
  }
);

export const selectCurrentRegiaoHidrograficaIds = createSelector(
  selectRegiaoHidrograficaState,
  fromRegiaoHidrografica.getSelectedRegiaoHidrograficaIds
);

export const selectCurrentRegioesHidrograficas = createSelector(
  selectRegiaoHidrograficaEntities,
  selectCurrentRegiaoHidrograficaIds,
  (RegiaoHidrograficaEntities, RegiaoHidrograficaIds) => {

    let list: RegiaoHidrografica[] = [];

    RegiaoHidrograficaIds.forEach(element => {
      list.push(RegiaoHidrograficaEntities[element]);
    });

    return list;
  }
);