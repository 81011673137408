import { Component, OnInit, Input } from '@angular/core';
import { Config, emptyConfig } from '@funceme/core-app';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-calendario-interpolacao',
  templateUrl: './calendario-interpolacao.component.html',
  styleUrls: ['./calendario-interpolacao.component.css']
})
export class CalendarioInterpolacaoComponent implements OnInit {

  private _config = new BehaviorSubject<Config>(emptyConfig);

  @Input()
  set config(value) {
      this._config.next(value);
  };

  get config() {
    return this._config.getValue();
  }

  private path:string = 'http://www3.funceme.br/web/storage/obs/interpolation_kriging_funceme_valid_rain/';

  public accumulated: string = null;
  public category: string = null;
  public deviation: string = null;
  public deviation_perc: string = null;
  public anomaly: string = null;
  public anomaly_perc: string = null;

  constructor() { }

  ngOnInit() {

    this._config.subscribe(value => {
      this.make(value);
    });

  }

  private make(config: Config) 
  {
    const today = new Date();

    let _mes = config.mes+''

    if (config.periodo == 'bimestre') {
      if ((config.mes+1) % 12 != 0)
        _mes = _mes + '-' + (config.mes+1) % 12;
      else   
        _mes = _mes + '-12';
    } else if (config.periodo == 'trimestre') {
      if ((config.mes+2) % 12 != 0)
        _mes = _mes + '-' + (config.mes+2) % 12;
      else 
        _mes = _mes + '-12';

    } else if (config.periodo == 'quadrimestre') {
      if ((config.mes+3) % 12 != 0)
        _mes = _mes + '-' + (config.mes+3) % 12;
      else 
        _mes = _mes + '-12';
    }

    if (config.periodo == 'dia') {

      this.accumulated = this.path + config.ano + '/' + _mes + '/' + config.dia
        + '/accumulated_' + config.dia + '_' + this.getMes(_mes) + '_' + config.ano + '_1d.png';
      
      this.anomaly = this.path + config.ano + '/' + _mes + '/' + config.dia
        + '/anomaly_' + config.dia + '_' + this.getMes(_mes) + '_' + config.ano + '_1d.png';

      this.anomaly_perc = this.path + config.ano + '/' + _mes + '/' + config.dia
        + '/anomaly_perc_' + config.dia + '_' + this.getMes(_mes) + '_' + config.ano + '_1d.png';

    } else if (config.periodo == 'mes' || config.periodo == 'bimestre' || config.periodo == 'trimestre' || config.periodo == 'quadrimestre') {

      this.accumulated = this.path + config.ano + '/' + _mes 
        + '/accumulated_' + this.getMes(_mes) + '_' + config.ano + '.png';

      this.category = this.path + config.ano + '/' + _mes 
        + '/category-pr-' + this.getMes(_mes) + '-' + config.ano + '.png';

      this.deviation = this.path + config.ano + '/' + _mes 
        + '/deviation_' + this.getMes(_mes) + '_' + config.ano + '.png';

      this.deviation_perc = this.path + config.ano + '/' + _mes 
        + '/deviation_perc_' + this.getMes(_mes) + '_' + config.ano + '.png';
      
      this.anomaly = this.path + config.ano + '/' + _mes 
        + '/anomaly_' + this.getMes(_mes) + '_' + config.ano + '.png';

      this.anomaly_perc = this.path + config.ano + '/' + _mes 
        + '/anomaly_perc_' + this.getMes(_mes) + '_' + config.ano + '.png';
        
    } else if (config.periodo == 'ano') {
      
      this.accumulated = this.path + config.ano  
        + '/accumulated_' + config.ano + '.png';

      this.category = this.path + config.ano 
        + '/category-pr-' + this.getMes('1-12') + '-' + config.ano + '.png';

      this.deviation = this.path + config.ano 
        + '/deviation_' + config.ano + '.png';

      this.deviation_perc = this.path + config.ano 
        + '/deviation_perc_' + config.ano + '.png';
      
      this.anomaly = this.path + config.ano 
        + '/anomaly_' + config.ano + '.png';

      this.anomaly_perc = this.path + config.ano 
        + '/anomaly_perc_' + config.ano + '.png';
    }

    if (this.hideAnomaly(config,today,_mes)) {
      this.anomaly = null;
      this.anomaly_perc = null;
    }
  }

  private getMes(mes:string) {
    return mes.split('-').map(value => this.getName(value)).join('-');
  }

  private getName(mes): string
  {
    switch (mes) {
      case '1': return 'jan';
      case '2': return 'fev';
      case '3': return 'mar';
      case '4': return 'abr';
      case '5': return 'mai';
      case '6': return 'jun';
      case '7': return 'jul';
      case '8': return 'ago';
      case '9': return 'set';
      case '10': return 'out';
      case '11': return 'nov';
      case '12': return 'dez';
    }
  }

  private hideAnomaly(config: Config, today: Date, mes){
    if (config.periodo == "ano") {
      return config.ano != today.getFullYear();
    }

    if (config.periodo == "dia") {
      return false;
    }

    let [mesInicial, mesFinal] = mes.split('-')

    let data = new Date(config.ano,mesInicial,-1);

    if (mesFinal) {
      
      if (parseInt(mesInicial) > parseInt(mesFinal)) {
        data = new Date(config.ano+1,mesFinal,0);

      } else {
        data = new Date(config.ano,mesFinal,0);
      }
    }

    return today.getTime() > data.getTime();
  }
}
