import { Component, TemplateRef, ViewChild, OnInit, OnDestroy, OnChanges, AfterViewInit } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { PluviometriaNormalizadaFuncemeModalComponent } from '../pluviometria-normalizada-funceme-modal/pluviometria-normalizada-funceme-modal.component';

@Component({
  selector: 'pluviometria-normalizada-funceme-modal-button',
  templateUrl: './pluviometria-normalizada-funceme-modal-button.component.html',
  styleUrls: ['./pluviometria-normalizada-funceme-modal-button.component.css']
})
export class PluviometriaNormalizadaFuncemeModalButtonComponent {

  @ViewChild(TemplateRef, {static: true}) template: TemplateRef<any>;
  model: any;
  data: any;

  private dialogRef: MatDialogRef<PluviometriaNormalizadaFuncemeModalComponent, any>;

  constructor(public dialog: MatDialog) {}

  openModal(): void 
  {   
    this.dialogRef = this.dialog.open(PluviometriaNormalizadaFuncemeModalComponent, {
      width: '800px',
      data: this.data
    });    

    this.dialogRef.afterClosed().subscribe(result => {
      
    });
  }
}
