import { Component, OnInit, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CalendarioItem } from '../../../shared/models/calendario.model';

@Component({
  selector: 'app-calendario-progress-municipio',
  templateUrl: './calendario-progress-municipio.component.html',
  styleUrls: ['./calendario-progress-municipio.component.css']
})
export class CalendarioProgressMunicipioComponent implements OnInit {

  private _data = new BehaviorSubject<CalendarioItem[]>([]);

  @Input()
  set data(value) {
      this._data.next(value);
  };

  get data() {
    return this._data.getValue();
  }

  private municipios: any[];

  public municipios_informados: number = 0; 
  public municipios_com_chuva: number = 0; 

  constructor() { }

  ngOnInit()
  {
    this._data.subscribe(values => {

      this.municipios = [];

      values.forEach(_value => {
        if (!this.municipios.includes(_value.municipio))
          this.municipios.push(_value.municipio);
      });
      
      this.municipios_informados = this.getMunicipiosInformados();
      this.municipios_com_chuva = this.getMunicipiosComChuva();
    });
  }

  public getMunicipiosInformados(): number
  {
    return this.municipios.filter(value => this.data.filter(_value => _value.observado != null && _value.municipio == value).length > 0).length;
  }

  public getMunicipiosComChuva(): number
  {
    return this.municipios.filter(value => this.data.filter(_value => _value.observado > 0 && _value.municipio == value).length > 0).length;
  }
}
