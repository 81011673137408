import { Component, OnInit, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { Municipio } from '../../../state/municipio/municipio.model';
import { Pagination, getPaginationDefault } from '../../../generics/pagination.model';
import { TableSchema } from '../../../generics/lazy-grid/table.schema';
import { MunicipioFacade } from '../../../state/municipio/municipio.facade';
import { LazyGridTdTextComponent } from '../../../generics/lazy-grid/lazy-grid-td-text/lazy-grid-td-text.component';
import { UfFacade } from '../../../state/uf/uf.facade';

@Component({
  selector: 'municipio-lgrid',
  templateUrl: './municipio-lgrid.component.html',
  styleUrls: ['./municipio-lgrid.component.css']
})
export class MunicipioLgridComponent implements OnInit {

  private subscriptions = new Subscription();

  public municipios: Municipio[] = [];
  public localizar: string;
  public statusFilter: boolean = false;

  @Input() enableActions: boolean = true;
  @Input() showColumns: string[] = [];
  @Input() showFilters: string[] = [];

  private pagination: Pagination;

  private cleaning: boolean = false;

  public tableSchema: TableSchema;

  constructor(
    public facade: MunicipioFacade,
    private ufFacade: UfFacade
  ) {     
    this.tableSchema = {    
      columns: [
        {
          title: "Municipio",
          field: 'nome',
          ordenable: true,
          render: LazyGridTdTextComponent,        
          getData: function(obj: Municipio) {
            return obj.nome;
          }
        },
        {
          title: "UF",
          field: 'uf',
          ordenable: true,
          render: LazyGridTdTextComponent,        
          getData: function(obj: Municipio) {
            return obj.uf;
          }
        }
      ],
      actions: []
    }
  }

  async ngOnInit() 
  {

    await this.load();

    this.subscriptions.add(
      this.facade.all$.subscribe(values => {
        this.municipios = values;
        this.cleaning = false;
      })
    ).add(
      this.facade.metaData$.subscribe(value => {
        if (!value) return;
        this.pagination = value.request.query_params;     
        this.localizar = value.request.query_params.filter_list['nome-lk'];
      })
    ).add(
      this.ufFacade.current$.subscribe(value => {

        if (this.cleaning) return;
        
        if (value && value.id)
          this.pagination.filter_list['uf'] = value.id;
        else if (this.pagination.filter_list['uf'])
          delete this.pagination.filter_list['uf'];

        this.pagination.paginator.page = 1;
        this.facade.paginate(this.pagination);
      })
    );
  }

  load() 
  {
    this.pagination = getPaginationDefault();

    this.ufFacade.select('CE');
    
    //this.facade.paginate(this.pagination);
  }

  cleanFilters()
  {
    this.cleaning = true;

    this.localizar = '';
    this.ufFacade.select(null);  
    
    this.pagination.filter_list = [];
    this.pagination.paginator.page = 1;    

    this.facade.paginate(this.pagination);
  }

  ngOnDestroy() {    
    this.subscriptions.unsubscribe();    
  }

  applyFilter($event) 
  {
    if ($event.code == 'NumpadEnter' || $event.code == 'Enter') {
      this.pagination.paginator.page = 1;
      this.pagination.filter_list['nome-lk'] = $event.target.value;
      this.facade.paginate(this.pagination);
    }
  }  
}
