import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { PluviometriaNormalizadaFunceme } from '../../../state/pluviometria-normalizada-funceme/pluviometria-normalizada-funceme.model';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'pluviometria-normalizada-funceme-grid',
  templateUrl: './pluviometria-normalizada-funceme-grid.component.html',
  styleUrls: ['./pluviometria-normalizada-funceme-grid.component.css']
})
export class PluviometriaNormalizadaFuncemeGridComponent implements OnInit {

  private _data = new BehaviorSubject<PluviometriaNormalizadaFunceme[]>([]);

  public displayedColumns: string[] = ['data', 'valor'];

  @Input()
  set data(value) {
      this._data.next(value);
  };

  get data() {
    return this._data.getValue();
  }

  public dataSource = new MatTableDataSource<PluviometriaNormalizadaFunceme>();

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor() 
  { 
    
  }

  ngOnInit() 
  {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.dataSource.data = [];
    
    this._data.subscribe(values => {
      this.dataSource.data = values;
    });
  }
}
