import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-calendario-categoria',
  templateUrl: './calendario-categoria.component.html',
  styleUrls: ['./calendario-categoria.component.css']
})
export class CalendarioCategoriaComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
