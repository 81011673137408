import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PluviometriaNormalizadaFuncemeLgridComponent } from './pluviometria-normalizada-funceme-lgrid/pluviometria-normalizada-funceme-lgrid.component';
import { MaterialModule } from '@funceme/material';
import { FormsModule } from '@angular/forms';
import { StateModule } from '../../state/state.module';
import { LazyGridModule } from '../../generics/lazy-grid/lazy-grid.module';
import { PostoModule } from '../posto/posto.module';
import { PluviometriaNormalizadaFuncemeModalComponent } from './pluviometria-normalizada-funceme-modal/pluviometria-normalizada-funceme-modal.component';
import { PluviometriaNormalizadaFuncemeModalButtonComponent } from './pluviometria-normalizada-funceme-modal-button/pluviometria-normalizada-funceme-modal-button.component';
import { PluviometriaNormalizadaFuncemeDownloadButtonComponent } from './pluviometria-normalizada-funceme-download-button/pluviometria-normalizada-funceme-download-button.component';
import { PluviometriaNormalizadaFuncemeGraphComponent } from './pluviometria-normalizada-funceme-graph/pluviometria-normalizada-funceme-graph.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { PluviometriaNormalizadaFuncemeGridComponent } from './pluviometria-normalizada-funceme-grid/pluviometria-normalizada-funceme-grid.component';
import { CoreDataModule } from '../../core-app.module';
import { ZmatDatepickerModule } from '../../generics/zmat-datepicker/zmat-datepicker.module';

@NgModule({
  declarations: [
    PluviometriaNormalizadaFuncemeLgridComponent, 
    PluviometriaNormalizadaFuncemeModalComponent, 
    PluviometriaNormalizadaFuncemeModalButtonComponent, 
    PluviometriaNormalizadaFuncemeDownloadButtonComponent, 
    PluviometriaNormalizadaFuncemeGraphComponent, PluviometriaNormalizadaFuncemeGridComponent
  ],
  imports: [
    CommonModule,
    CoreDataModule,
    MaterialModule,
    FormsModule,
    StateModule,
    LazyGridModule,
    PostoModule,
    HighchartsChartModule,
    ZmatDatepickerModule
  ],
  exports: [
    PluviometriaNormalizadaFuncemeLgridComponent,
    PluviometriaNormalizadaFuncemeModalButtonComponent
  ],
  entryComponents: [
    PluviometriaNormalizadaFuncemeModalComponent,
    PluviometriaNormalizadaFuncemeModalButtonComponent,
    PluviometriaNormalizadaFuncemeDownloadButtonComponent,
    PluviometriaNormalizadaFuncemeGraphComponent
  ]
})
export class PluviometriaNormalizadaFuncemeModule { }
