import { Component, OnInit, Input, AfterContentInit } from '@angular/core';
import { Config, ConfigFacade } from '@funceme/core-app';
import { HttpClient } from '@angular/common/http';
import { Mapa } from '../../../shared/models/mapa.model';

@Component({
  selector: 'app-calendario-mapa',
  templateUrl: './calendario-mapa.component.html',
  styleUrls: ['./calendario-mapa.component.css']
})
export class CalendarioMapaComponent implements OnInit {

  public loading: boolean = false;
  public mapa: Mapa;

  @Input() public mini: boolean = false;


  private mapa_default: string = 'https://chuvas.funceme.br/assets/img/mapa_default.jpg';
  //private mapa_default: string = 'https://cdn.funceme.br/calendario/chuvas/mapa.gif';

  @Input()
  set setMapa(mapa) {
    this.mapa = mapa;

    (async () => {
      var path = this.mapa.path;
      let resp = await fetch(path);
      if (resp['status'] != 200)
        this.mapa.path = 'https://cdn.funceme.br/calendario/chuvas/mapa.gif';
      if (this.mapa.date.valueOf() > new Date().valueOf())
        this.image_path = this.mapa_default;
      else
        this.image_path = this.mapa.path;
    })()

    /*
    if (this.mapa.date.valueOf() > new Date().valueOf())
      this.image_path = this.mapa_default;
    else
      this.image_path = this.mapa.path;
    */

    this.loadMapa();

  };

  @Input() public config: Config;

  public image_path: string;

  constructor(
    private http: HttpClient,
    private configFacade: ConfigFacade
  ) {

  }

  ngOnInit() {

  }

  private loadMapa()
  {
    this.loading = true;

    this.http.get<any>(this.mapa.path, {headers: {
      'Accept': '*/*',
      'Access-Control-Allow-Origin': '*'
    }}).subscribe(
        (response) => {
            this.image_path = this.mapa.path;
            this.loading = false;
        },
        (error) => {
            this.loading = false;

            //console.log(error);

            if (error.status == 404) {
                this.mapa.link = null;
                this.image_path = this.mapa_default;
            }
        }
    );
  }

  link(data)
  {

    if ((data == null) || (data > new Date()))
      return;

    switch (this.config.periodo) {
      case 'diario':        this.config.periodo = 'dia'; break;
      case 'mensal':        this.config.periodo = 'mes'; break;
      case 'bimestral':     this.config.periodo = 'bimestre'; break;
      case 'trimestral':    this.config.periodo = 'trimestre'; break;
      case 'quadrimestral': this.config.periodo = 'quadrimestre'; break;
      case 'anual':         this.config.periodo = 'ano'; break;
    }

    this.config.dia = data.getDate();
    this.config.mes = data.getMonth()+1;
    this.config.ano = data.getFullYear();

    this.configFacade.save(this.config);
  }

  updateUrl(event) {
    this.image_path = this.mapa_default;
    this.mapa.link = null;
  }

  mapClicked(event: MouseEvent)
  {
    event.preventDefault();
    this.configFacade.find(event.target['title']);
  }
}

