import { Component, OnInit, Input, ViewChild, OnDestroy } from '@angular/core';
import { MatSelect } from '@angular/material/select';
import { Subscription } from 'rxjs';
import { SelectOneSchema } from './select-one.schema';

@Component({
  selector: 'select-one',
  templateUrl: './select-one.component.html',
  styleUrls: ['./select-one.component.css']
})
export class SelectOneComponent implements OnInit, OnDestroy {

  private subscriptions = new Subscription();

  public list: any[] = [];

  @Input() schema: SelectOneSchema;

  @ViewChild(MatSelect, {static: true}) select: MatSelect;

  public selectedItem: any;

  constructor() { 
    
  }

  ngOnInit() 
  {
    this.subscriptions.add(
      this.schema.facade.current$.subscribe(value => this.select.value = (value)? value.id : null)
    ).add(    
      this.schema.facade.all$.subscribe((values) => {
        if (this.schema.include_list.length) 
          this.list = values.filter(item => this.schema.include_list.includes(this.schema.getId(item)));
        else if (this.schema.exclude_list.length)  
          this.list = values.filter(item => !this.schema.exclude_list.includes(this.schema.getId(item)));
        else
          this.list = values;
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  public itemChanged(event)
  {    
    this.schema.facade.select(event.value);
  }
}
