import { Action } from '@ngrx/store';

import { Config } from './config.model';

export enum ConfigActionTypes {
  ConfigAction            = '[Config] Action',
  SaveConfig              = '[Config] Save Config',
  FindConfig              = '[Config] Find Config'
}

export class Configs implements Action {
  readonly type = ConfigActionTypes.ConfigAction;
}

export class FindConfig implements Action {
  readonly type = ConfigActionTypes.FindConfig;
  constructor(public payload: string) { }
}

export class SaveConfig implements Action {
  readonly type = ConfigActionTypes.SaveConfig;
  constructor(public payload: Config) { }
}

export type ConfigActions = Configs
  | SaveConfig
  | FindConfig
;
