import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavigatorService } from '../../../shared/services/navigator.service';

@Component({
  selector: 'app-calendario-mensal',
  templateUrl: './calendario-mensal.component.html',
  styleUrls: ['./calendario-mensal.component.css']
})
export class CalendarioMensalComponent implements OnInit {

  constructor(
    private navigator: NavigatorService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.navigator.parse('mensal', this.route.snapshot);
  }
}
