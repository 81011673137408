import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavigatorService } from '../../shared/services/navigator.service';

@Component({
  selector: 'app-repositorio-chuva',
  templateUrl: './repositorio-chuva.component.html',
  styleUrls: ['./repositorio-chuva.component.css']
})
export class RepositorioChuvaComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private navigator: NavigatorService
  ) { }

  ngOnInit(): void {
    this.navigator.parse('repositorio-chuva', this.route.snapshot); 
  }

}
