import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreModule, ActionReducer, MetaReducer } from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';
import { NxModule } from '@nrwl/nx';
import { MatSnackBarModule } from '@angular/material/snack-bar'; 

import { reducers } from '../state';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { UfEffects } from './uf/uf.effects';
import { UserEffects } from './user/user.effects';
import { PostoEffects } from './posto/posto.effects';
import { OrgaoEffects } from './orgao/orgao.effects';
import { MunicipioEffects } from './municipio/municipio.effects';
import { BaciaFuncemeEffects } from './bacia-funceme/bacia-funceme.effects';
import { PluviometriaNormalizadaFuncemeEffects } from './pluviometria-normalizada-funceme/pluviometria-normalizada-funceme.effects';
import { RegiaoHidrograficaEffects } from './regiao-hidrografica/regiao-hidrografica.effects';
import { MacrorregiaoEffects } from './macrorregiao/macrorregiao.effects';

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({keys: [ 
    'uf',
    'user', 
    'config', 
    'posto',
    'orgao',
    'municipio',
    'baciaFunceme',
    'pluviometriaNormalizadaFunceme',
    'regiaoHidrografica',
    'macrorregiao'
  ],rehydrate: true})(reducer);
}
const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer];

@NgModule({
  imports: [
    CommonModule,
    MatSnackBarModule,
    NxModule.forRoot(),
    StoreModule.forRoot(reducers,
      { metaReducers,
        runtimeChecks: { 
          strictStateImmutability: false, 
          strictActionImmutability: false,
        }
      }
    ),
    StoreDevtoolsModule.instrument(
      { 
        maxAge: 10 
      }
    ),
    EffectsModule.forRoot([
      UfEffects,
      UserEffects,
      PostoEffects,
      OrgaoEffects,
      MunicipioEffects,
      BaciaFuncemeEffects,
      PluviometriaNormalizadaFuncemeEffects,
      RegiaoHidrograficaEffects,
      MacrorregiaoEffects
    ]),
  ],
  declarations: []
})
export class StateModule { }