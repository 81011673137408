import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class NotificationsService {

  constructor(private snackbar: MatSnackBar) {
  }

  emit(notification, action = 'OK', duration = 5000) {
    this.snackbar.open(notification, action, { duration });
  }
}
