import { Action } from '@ngrx/store';
import { Municipio } from './municipio.model';
import { Pagination, ApiResponse } from '../../generics/pagination.model';

export enum MunicipioActionTypes {
  MunicipiosAction = '[Municipios] Action',
  LoadMunicipios = '[Municipios] Load Data',
  MunicipiosLoaded = '[Municipios] Data Loaded',
  Municipioselected = '[Municipio] Selected',
  MunicipiosSelected = '[Municipios] Selected',
  MunicipiosUnselected = '[Municipios] Unselected',
  PaginateMunicipios = '[Municipios] Paginate',
  MunicipiosPaginated = '[Municipios] Paginated',
  MunicipiosRequestDone = '[Municipios] RequestDone',
  MunicipiosRequestFailed = '[Municipios] Request Failed',
}

export class Municipios implements Action {
  readonly type = MunicipioActionTypes.MunicipiosAction;
}

export class LoadMunicipios implements Action {
  readonly type = MunicipioActionTypes.LoadMunicipios;
  constructor() { }
}

export class MunicipiosLoaded implements Action {
  readonly type = MunicipioActionTypes.MunicipiosLoaded;
  constructor(public payload: Municipio[]) { }
}

export class Municipioselected implements Action {
  readonly type = MunicipioActionTypes.Municipioselected;
  constructor(public payload: number) { }
}

export class MunicipiosSelected implements Action {
  readonly type = MunicipioActionTypes.MunicipiosSelected;
  constructor(public payload: number[]) { }
}

export class MunicipiosUnselected implements Action {
  readonly type = MunicipioActionTypes.MunicipiosUnselected;
  constructor(public payload: number[]) { }
}

export class PaginateMunicipios implements Action {
  readonly type = MunicipioActionTypes.PaginateMunicipios;
  constructor(public payload: Pagination) { }
}

export class MunicipiosPaginated implements Action {
  readonly type = MunicipioActionTypes.MunicipiosPaginated;
  constructor(public payload: ApiResponse<Municipio>) { }
}

export class MunicipiosRequestDone implements Action {
  readonly type = MunicipioActionTypes.MunicipiosRequestDone;
  constructor() { }
}

export class MunicipiosRequestFailed implements Action {
  readonly type = MunicipioActionTypes.MunicipiosRequestFailed;
  constructor() { }
}

export type MunicipioActions = Municipios
  | LoadMunicipios
  | MunicipiosLoaded  
  | Municipioselected
  | MunicipiosSelected
  | MunicipiosUnselected
  | PaginateMunicipios
  | MunicipiosPaginated
  | MunicipiosRequestDone
  | MunicipiosRequestFailed
;