import { Injectable } from '@angular/core';
import { ActionsSubject, select, Store } from '@ngrx/store';
import * as OrgaoActions from './orgao.actions';
import { OrgaoState } from './orgao.reducer';
import { selectAllOrgaos, selectCurrentOrgaos, selectCurrentOrgao, selectMetaDataOrgaos, selectTotalOrgaos, selectLoadingOrgaos } from './orgao.selectors';
import { Pagination } from '../../generics/pagination.model';
import { GenericFacade } from '../../generics/generic-facade';

@Injectable({ providedIn: 'root' })
export class OrgaoFacade implements GenericFacade {
  
  all$ = this.store.pipe(select(selectAllOrgaos));
  current$ = this.store.pipe(select(selectCurrentOrgao));
  currentList$ = this.store.pipe(select(selectCurrentOrgaos));
  metaData$ = this.store.pipe(select(selectMetaDataOrgaos));
  total$ = this.store.pipe(select(selectTotalOrgaos));
  loading$ = this.store.pipe(select(selectLoadingOrgaos));

  constructor(private store: Store<OrgaoState>, private actions$: ActionsSubject) {}

  selectOne(orgaoId: number) {
    this.store.dispatch(new OrgaoActions.OrgaoSelected(orgaoId));
  }

  selectMany(orgaoIds: number[]) {
    this.store.dispatch(new OrgaoActions.OrgaosSelected(orgaoIds));
  }

  unselectMany(orgaoIds: number[]) {
    this.store.dispatch(new OrgaoActions.OrgaosUnselected(orgaoIds));
  }

  load() {
    this.store.dispatch(new OrgaoActions.LoadOrgaos());
  }

  paginate(pagination: Pagination) {
    this.store.dispatch(new OrgaoActions.PaginateOrgaos(pagination));
  }

  select(id: number) {
    this.selectOne(id);
  }

  pick(id: number) {}
  destroy(id: number) {};
  save(model: any) {};
  index() {};
  edit(id: number) {};
  show(id: number) {};
  create(): void {};
  refresh(): void {};
}
