import * as fromUf from './uf.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Uf, emptyUf } from './uf.model';

export const selectUfstate = createFeatureSelector<fromUf.UfState>('uf');

export const selectUfIds = createSelector(
  selectUfstate,
  fromUf.selectUfIds
);

export const selectUfEntities = createSelector(
  selectUfstate,
  fromUf.selectUfEntities
);

export const selectAllUfs = createSelector(
  selectUfstate,
  fromUf.selectAllUfs
);

export const selectMetaDataUfs = createSelector(
  selectUfstate,
  fromUf.getMetaDataUfs
);

export const selectTotalUfs = createSelector(
  selectUfstate,
  fromUf.getTotalUfs
); 

export const selectCurrentUfId = createSelector(
  selectUfstate,
  fromUf.getSelectedUfId
);

export const selectLoadingUfs = createSelector(
  selectUfstate,
  fromUf.getLoadingUfs
); 

export const selectCurrentUf = createSelector(
  selectUfEntities,
  selectCurrentUfId,
  (UfEntities, UfId) => {
    return UfId ? UfEntities[UfId] : emptyUf;
  }
);

export const selectCurrentUfIds = createSelector(
  selectUfstate,
  fromUf.getSelectedUfIds
);

export const selectCurrentUfs = createSelector(
  selectUfEntities,
  selectCurrentUfIds,
  (UfEntities, UfIds) => {

    let list: Uf[] = [];

    UfIds.forEach(element => {
      list.push(UfEntities[element]);
    });

    return list;
  }
);