import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as Highcharts from 'highcharts';
import { CalendarioSerieService } from '../../../shared/services/calendario-serie.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-calendario-grafico-serie',
  templateUrl: './calendario-grafico-serie.component.html',
  styleUrls: ['./calendario-grafico-serie.component.css']
})
export class CalendarioGraficoSerieComponent implements OnInit {

  private subscription: Subscription;
  public title: string;
  private dados;
  public total: Number;

  public highcharts = Highcharts;
  public chartOptions = {   
      chart: {
        type: 'column',
        // width: 600,
        reflow: true,
        plotBorderColor: '#C9C9C9',
        plotBorderWidth: 2,
      },
      colors: ['#468847'],
      title: {
        text: ""
      },
      subtitle: {
          text: null
      },
      credits: {
          enabled: true,
          style: {
              color: '#4F4F4F',
          },
          text: '',
      },
      xAxis:{
        categories:[],
        tickmarkPlacement: 'on',
        title: {
            enabled: false
        },
      },
      yAxis: {          
        title:{
            text:"Precipitação (mm)"
        },
      },
      tooltip: {
        valueSuffix:" (mm)",
        shared: true,
      },
      plotOptions: {
          area: {
              lineColor: '#666666',
              lineWidth: 1,
              marker: {
                  enabled: false
              },
          },
      },
      series: [
        {
            name: 'Precipitação',
            data: []
        }
      ]
  };

  public updateFlag = false;

  constructor(
    public dialogRef: MatDialogRef<CalendarioGraficoSerieComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private service : CalendarioSerieService
  ) {
    this.subscription = this.service.serviceListener$.subscribe(response => {
      this.dados = response.data.list
      this.total = response.data.list.reduce((total,dado) => total + Number(dado.valor),0);
      this.draw() 
    })
  }

  ngOnInit() {
    let date1 = new Date(this.data.ano,this.data.mes-1,1);
    let date2 = new Date(date1);

    date2.setMonth(date2.getMonth()+1);

    this.service
      .atPosto(this.data.id)
      .startsAt(date1.getFullYear()+'-'+(date1.getMonth()+1)+'-'+date1.getDate())
      .endsAt(date2.getFullYear()+'-'+(date2.getMonth()+1)+'-'+date2.getDate())
      .get()
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  close(): void { 
    this.dialogRef.close();
  }

  private draw() 
  {
    if (!this.dados[0])
      return;
      
    let title = this.data.nome;
    let subtitle = 'Chuvas do Mês de '+this.getMes(this.data.mes)+' de '+this.data.ano+' - Total: '+(this.total.toFixed(2))+'mm'
    let xLabels = [];
    let data = []

    this.dados.map(dado => {
      xLabels.push(new Date(dado.data+"T00:00:00").getDate());
      data.push(Number(dado.valor));
    });

    this.title = title;

    this.chartOptions.title.text = title;
    this.chartOptions.subtitle.text = subtitle;
    this.chartOptions.xAxis.categories = xLabels;
    this.chartOptions.series[0].data = data;

    this.updateFlag = true;
    
  }

  private getMes(key)
  {
    switch (key) {
      case 1:
        return 'Janeiro';
      case 2:
        return 'Fevereiro';
      case 3:
        return 'Março';
      case 4:
        return 'Abril';
      case 5:
        return 'Maio';
      case 6:
        return 'Junho';
      case 7:
        return 'Julho';
      case 8:
        return 'Agosto';
      case 9:
        return 'Setembro';
      case 10:
        return 'Outubro';
      case 11:
        return 'Novembro';
      case 12:
        return 'Dezembro';
    }
  }
}
