import { Component, OnInit } from '@angular/core';
import { ConfigFacade } from 'libs/core-app/src/lib/state/config/config.facade';
import { emptyConfig } from 'libs/core-app/src/lib/state/config/config.model';

@Component({
  selector: 'app-erro',
  templateUrl: './erro.component.html',
  styleUrls: ['./erro.component.css']
})
export class ErroComponent implements OnInit {

  constructor(
    private configFacade: ConfigFacade
  ) {
  }
  
  ngOnInit() {
    this.configFacade.save(emptyConfig)
  }

}
