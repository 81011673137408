import { Component, OnInit } from '@angular/core';
import { Config, ConfigFacade } from '@funceme/core-app';
import { Subscription } from 'rxjs';
import { Mapa } from '../../../shared/models/mapa.model';
import { NavigatorService } from '../../../shared/services/navigator.service';

@Component({
  selector: 'app-calendario-view-mensal',
  templateUrl: './calendario-view-mensal.component.html',
  styleUrls: ['./calendario-view-mensal.component.css']
})
export class CalendarioViewMensalComponent implements OnInit {

  public loading: boolean = false;
  public iterationFlag = false;
  public config: Config;

  private subscription: Subscription;

  public hasPrevious: boolean = true;
  public hasNext: boolean = true;

  public mapas: Mapa[] = [];

  constructor( 
    private navigator: NavigatorService,
    private configFacade: ConfigFacade
  ) { 
    this.subscription = this.configFacade.current$.subscribe(value => {
      this.config = value;
      this.hasPrevious = this.navigator.hasPreviousYear();
      this.hasNext = this.navigator.hasNextYear();
      if(!this.iterationFlag)
        this.make();
    });
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  backward() {
    this.navigator.yearBackward();
  }

  forward() {
    this.navigator.yearForward();
  }

  disableButtons(value){
    if (value)
      this.hasPrevious = false;
    else
      this.hasPrevious = this.navigator.hasPreviousYear();

    if (value)
      this.hasNext = false;
    else
      this.hasNext = this.navigator.hasNextYear();
  }

  up() {

  }

  private async make(){

    this.disableButtons(true);
    this.iterationFlag = true;

    this.mapas = [];
    const urls = new Array(12);

    //cria grid com valores em branco e mapa de imagem padrão
    for (const [idx, url] of urls.entries()) {
      this.mapas.push({
        ativo: true,
        label: '',
        link: new Date(this.config.ano,idx,1),
        path: '',
        date: new Date(this.config.ano,idx,1)
      })
    }

    let currentDate = new Date();

    for (const [idx, url] of urls.entries()) {

      let _metrica = (this.config.metrica == 'media')? 'thiessen' : 'chuva';

      let _mes = (idx + 1)+''
      let _data = this.getMes(idx)

      if (this.config.periodo == 'bimestral'){
        if ((idx+2) % 12 != 0)
          _mes = _mes + '-' + (idx+2) % 12;
        else   
          _mes = _mes + '-12';

        _data = this.getMes(idx)+" - "+this.getMes((idx+1) % 12)
      } else if (this.config.periodo == 'trimestral'){
        if ((idx+3) % 12 != 0)
          _mes = _mes + '-' + (idx+3) % 12;
        else   
          _mes = _mes + '-12';

        _data = this.getMes(idx)+" - "+this.getMes((idx+2) % 12)
      } else if (this.config.periodo == 'quadrimestral'){
        if ((idx+4) % 12 != 0)
          _mes = _mes + '-' + (idx+4) % 12;
        else 
          _mes = _mes + '-12';

        _data = this.getMes(idx)+" - "+this.getMes((idx+3) % 12)
      }

      let produtoNome = '';

      if (this.config.produto == 'reservatorios')
      {
        produtoNome = 'acudes';
      }
      else
      {
        produtoNome = this.config.produto;
      }

      let date = new Date(this.config.ano,idx,1);

      if (date <= currentDate)
      {
        var path = 'https://cdn.funceme.br/calendario/chuvas/' + produtoNome + '/' + _metrica + '/anos/' + this.config.ano + '/' + _mes + '/mapa.gif';
        const resp = await fetch(path);
        
        delete this.mapas[idx];

        if (resp['status'] == 200){
          //this.mapas.push({
          this.mapas[idx] = ({
            ativo: true,
            label: _data,
            link: new Date(this.config.ano,idx,1),
            path: path,
            date: new Date(this.config.ano,idx,1)
          })
        } else {
          //this.mapas.push({
          this.mapas[idx] = ({
            ativo: true,
            label: _data,
            link: new Date(this.config.ano,idx,1),
            path: 'https://cdn.funceme.br/calendario/chuvas/mapa.gif',
            date: new Date(this.config.ano,idx,1)
          })  
        }
      }
      else
      {
        this.mapas[idx] = ({
          ativo: true,
          label: _data,
          link: new Date(this.config.ano,idx,1),
          path: 'https://cdn.funceme.br/calendario/chuvas/mapa.gif',
          date: new Date(this.config.ano,idx,1)
        })
      }
    }

    this.iterationFlag = false;
    this.disableButtons(false);
  }

  private getMes(key)
  {
    switch (key) {
      case 0:
        return 'Janeiro';
      case 1:
        return 'Fevereiro';
      case 2:
        return 'Março';
      case 3:
        return 'Abril';
      case 4:
        return 'Maio';
      case 5:
        return 'Junho';
      case 6:
        return 'Julho';
      case 7:
        return 'Agosto';
      case 8:
        return 'Setembro';
      case 9:
        return 'Outubro';
      case 10:
        return 'Novembro';
      case 11:
        return 'Dezembro';
    }
  }

}