export interface Orgao {
    id: number;
    nome: string;
    sigla: string;
}

export const emptyOrgao: Orgao = {
    id: null,
    nome: null,
    sigla: null
}
