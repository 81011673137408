import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/nx';
import { map } from 'rxjs/operators';
import { Uf } from './uf.model';
import { UfService } from './uf.service';

import {
  LoadUfs, UfsLoaded,
  UfActionTypes,
  UfsPaginated,
  PaginateUfs,
  UfsRequestDone,
  UfsRequestFailed
} from './uf.actions';
import { UfState } from './uf.reducer';
import { NotificationsService } from '../../notifications/notifications.service';
import { UfFacade } from './uf.facade';
import { ApiResponse, paginationEquals } from '../../generics/pagination.model';

@Injectable({providedIn: 'root'})
export class UfEffects 
{
  //@Effect() effect$ = this.actions$.ofType(UfsActionTypes.UfsAction);

  @Effect()
  loadUfs$ = this.dataPersistence.fetch(UfActionTypes.LoadUfs, {
    run: (action: LoadUfs, state: any) => {

      if(Object.keys(state.uf.entities).length > 0){
        let allUfs;

        this.ufFacade.all$.subscribe(ufs => allUfs = ufs )
        return new UfsLoaded(allUfs);
      }

      return this.ufService
        .all()
        .pipe(
          map((res: Uf[]) => new UfsLoaded(res['data']['list']))
        )
    },

    onError: (action: LoadUfs, error) => {
      this.notifyError.emit(error.statusText);
      return new UfsRequestFailed;
    }
  });

  @Effect()
  paginateUfs$ = this.dataPersistence.fetch(UfActionTypes.PaginateUfs, {
    run: (action: PaginateUfs, state: any) => {

      if (state.uf.last_pagination && paginationEquals(action.payload, JSON.parse(state.uf.last_pagination))) 
        return new UfsRequestDone;
      
      return this.ufService
        .paginate(action.payload)
        .pipe(
          map((res: ApiResponse<Uf>) => new UfsPaginated(res))
        )
    },

    onError: (action: PaginateUfs, error) => {
      this.notifyError.emit(error.statusText);
      return new UfsRequestFailed;
    }
  });

  constructor(
    private actions$: Actions,
    private dataPersistence: DataPersistence<UfState>,
    private ufService: UfService,
    private ufFacade: UfFacade,
    private notifyError: NotificationsService
  ) {}
}
