import { Component, OnInit } from '@angular/core';
import { NavigatorService } from '../../../shared/services/navigator.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-calendario-quadrimestre',
  templateUrl: './calendario-quadrimestre.component.html',
  styleUrls: ['./calendario-quadrimestre.component.css']
})
export class CalendarioQuadrimestreComponent implements OnInit {

  constructor(
    private navigator: NavigatorService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.navigator.parse('quadrimestre', this.route.snapshot);
  }
}