import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/nx';
import { map } from 'rxjs/operators';

import { Orgao } from './orgao.model';
import { OrgaoService } from './orgao.service';

import {
  LoadOrgaos, OrgaosLoaded,
  OrgaoActionTypes,
  OrgaosPaginated,
  PaginateOrgaos,
  OrgaosRequestDone,
  OrgaosRequestFailed
} from './orgao.actions';
import { OrgaoState } from './orgao.reducer';
import { NotificationsService } from '../../notifications/notifications.service';
import { OrgaoFacade } from './orgao.facade';
import { ApiResponse, paginationEquals } from '../../generics/pagination.model';

@Injectable({providedIn: 'root'})
export class OrgaoEffects 
{
  //@Effect() effect$ = this.actions$.ofType(OrgaosActionTypes.OrgaosAction);

  @Effect()
  loadOrgaos$ = this.dataPersistence.fetch(OrgaoActionTypes.LoadOrgaos, {
    run: (action: LoadOrgaos, state: any) => {

      if(Object.keys(state.orgao.entities).length > 0){
        let allOrgaos;

        this.orgaoFacade.all$.subscribe(orgaos => allOrgaos = orgaos )
        return new OrgaosLoaded(allOrgaos);
      }

      return this.orgaoservice
        .all()
        .pipe(
          map((res: Orgao[]) => new OrgaosLoaded(res['data']['list']))
        )
    },

    onError: (action: LoadOrgaos, error) => {
      this.notifyError.emit(error.statusText);
      return new OrgaosRequestFailed;
    }
  });

  @Effect()
  paginateOrgaos$ = this.dataPersistence.fetch(OrgaoActionTypes.PaginateOrgaos, {
    run: (action: PaginateOrgaos, state: any) => {

      if (state.orgao.last_pagination && paginationEquals(action.payload, JSON.parse(state.orgao.last_pagination))) 
        return new OrgaosRequestDone;

      return this.orgaoservice
        .paginate(action.payload)
        .pipe(
          map((res: ApiResponse<Orgao>) => new OrgaosPaginated(res))
        )
    },

    onError: (action: PaginateOrgaos, error) => {
      this.notifyError.emit(error.statusText);
      return new OrgaosRequestFailed;
    }
  });

  constructor(
    private actions$: Actions,
    private dataPersistence: DataPersistence<OrgaoState>,
    private orgaoservice: OrgaoService,
    private orgaoFacade: OrgaoFacade,
    private notifyError: NotificationsService
  ) {}
}
