import { Component, OnInit, Input } from '@angular/core';
import { ConfigFacade } from '@funceme/core-app';
import { Config, emptyConfig } from 'libs/core-app/src/lib/state/config/config.model';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  @Input() sidenav;

  public config: Config;

  constructor(private configFacade: ConfigFacade) { }

  ngOnInit() {
  }

  goHome() {
    this.config = emptyConfig;
    this.config.produto = 'municipios'
    this.config.periodo = 'diario'
    this.config.metrica = 'maxima'
    this.configFacade.save(this.config);
  }
  
}
