import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { MacrorregioesActions, MacrorregioesActionTypes } from './macrorregiao.actions';
import { Macrorregiao } from './macrorregiao.model';
import { ApiMetaData } from '../../generics/pagination.model';

/**
 * Interface to the part of the Store containing MacrorregiaoState
 * and other information related to MacrorregiaoData.
 */
export interface MacrorregiaoState extends EntityState<Macrorregiao> {
  selectedId: number;
  selectedIds: number[];
  loading: boolean;
  loaded_at: Date | null;
  meta_data: ApiMetaData;
  total: number;
  last_pagination: string
}

export const adapter: EntityAdapter<Macrorregiao> = createEntityAdapter<Macrorregiao>({
  selectId: (macrorregiao: Macrorregiao) => macrorregiao.id
});

export const initialState: MacrorregiaoState = adapter.getInitialState({
  selectedId: null,
  selectedIds: [],
  loading: false,
  loaded_at: null,
  meta_data: null,
  total: null,
  last_pagination: null
});

export function macrorregiaoReducer(state = initialState, action: MacrorregioesActions): MacrorregiaoState {

  switch (action.type) {
    
    case MacrorregioesActionTypes.MacrorregiaoSelected: {
      return Object.assign({}, state, { selectedId: action.payload });
    }

    case MacrorregioesActionTypes.MacrorregioesSelected: {
      return Object.assign({}, state, { selectedIds: [...state.selectedIds, action.payload] });
    }

    case MacrorregioesActionTypes.MacrorregiaoUnselected: {
      return Object.assign({}, state, { 
        selectedMacrorregiaoIds: state.selectedIds.filter(id => !action.payload.includes(id))
      });
    }

    case MacrorregioesActionTypes.LoadMacrorregiao: {
      return Object.assign({}, state, { loading: true });
    }

    case MacrorregioesActionTypes.MacrorregiaoLoaded: {
      return adapter.addAll(action.payload, Object.assign({}, state, { loading: false }));
    }

    case MacrorregioesActionTypes.PaginateMacrorregiao: {
      return Object.assign({}, state, { loading: true });
    }

    case MacrorregioesActionTypes.MacrorregiaoPaginated: {          
      return adapter.addAll(action.payload.data.list, Object.assign({}, state, { 
        meta_data: action.payload.meta, 
        total: action.payload.data.total_results,
        loading: false,
        loaded_at: new Date().toISOString(),
        last_pagination: JSON.stringify(action.payload.meta.request.query_params),
      }));  
    }

    case MacrorregioesActionTypes.MacrorregiaoRequestDone:
      case MacrorregioesActionTypes.MacrorregiaoRequestFailed: {
      return Object.assign({}, state, { loading: false });
    }
    
    default:
      return state;
  }
}

export const getSelectedMacrorregiaoIds = (state: MacrorregiaoState) => state.selectedIds;
export const getSelectedMacrorregiaoId = (state: MacrorregiaoState) => state.selectedId;
export const getMetaDataMacrorregiao = (state: MacrorregiaoState) => state.meta_data;
export const getTotalMacrorregiao = (state: MacrorregiaoState) => state.total;
export const getLoadingMacrorregiao = (state: MacrorregiaoState) => state.loading;

// get the selectors
const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

// select the array of Macrorregiao ids
export const selectMacrorregiaoIds = selectIds;

// select the dictionary of Macrorregiao entities
export const selectMacrorregiaoEntities = selectEntities;

// select the array of Macrorregiao
export const selectAllMacrorregiao = selectAll;

// select the total Macrorregiao count
export const selectMacrorregiaoTotal = selectTotal;
