import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { StateModule } from './state/state.module';
import { NotificationsService } from './notifications/notifications.service';
import { UfService } from './state/uf/uf.service';
import { UserService } from './state/user/user.service';
import { MaterialModule } from '@funceme/material';
import { PostoService } from './state/posto/posto.service';
import { SelectOneComponent } from './generics/select-one/select-one.component';
import { OrgaoService } from './state/orgao/orgao.service';
import { MunicipioService } from './state/municipio/municipio.service';
import { BaciaFuncemeService } from './state/bacia-funceme/bacia-funceme.service';
import { PluviometriaNormalizadaFuncemeService } from './state/pluviometria-normalizada-funceme/pluviometria-normalizada-funceme.service';
import { RegiaoHidrograficaService } from './state/regiao-hidrografica/regiao-hidrografica.service';
import { DatepickerMonthComponent, CustomDateMonthFormat } from './generics/datepicker-month/datepicker-month.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DatepickerDayComponent } from './generics/datepicker-day/datepicker-day.component';
import { DatepickerYearComponent, CustomDateYearFormat } from './generics/datepicker-year/datepicker-year.component';
import { DatepickerDecadeComponent } from './generics/datepicker-decade/datepicker-decade.component';
import { MapComponent } from './generics/map/map.component';

@NgModule({
  providers: [
    UfService,
    UserService,
    PostoService,
    OrgaoService,
    MunicipioService,
    BaciaFuncemeService,
    NotificationsService,
    PluviometriaNormalizadaFuncemeService,
    RegiaoHidrograficaService
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    StateModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    SelectOneComponent, 
    DatepickerDayComponent, 
    DatepickerMonthComponent,
    DatepickerYearComponent,
    DatepickerDecadeComponent,
    MapComponent
  ],
  declarations: [
    SelectOneComponent, 
    DatepickerDayComponent, 
    DatepickerMonthComponent,
    CustomDateMonthFormat,    
    DatepickerYearComponent, 
    CustomDateYearFormat,
    DatepickerDecadeComponent, 
    MapComponent
  ],
  entryComponents: []
})
export class CoreDataModule {}
