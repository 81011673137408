import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConfigFacade, Config } from '@funceme/core-app';
import { NavigatorService } from '../../../shared/services/navigator.service';
import { Mapa } from '../../../shared/models/mapa.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-calendario-anual',
  templateUrl: './calendario-anual.component.html',
  styleUrls: ['./calendario-anual.component.css']
})
export class CalendarioAnualComponent implements OnInit, OnDestroy {

  public loading: boolean = false;
  public iterationFlag: boolean = false;
  public config: Config;

  private subscription: Subscription;

  public hasPrevious: boolean = true;
  public hasNext: boolean = true;

  public mapas: Mapa[] = [];

  constructor(
    private route: ActivatedRoute,
    private navigator: NavigatorService,
    private configFacade: ConfigFacade
  ) { 
    this.subscription = this.configFacade.current$.subscribe(value => {
      this.config = value;
      if(!this.iterationFlag)
        this.make();
    });
  }

  ngOnInit() {
    this.navigator.parse('anual', this.route.snapshot);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  backward() {
    this.navigator.decadeBackward();
  }

  forward() {
    this.navigator.decadeForward();
  }
 
  disableButtons(value){
    if (value)
      this.hasPrevious = false;
    else
      this.hasPrevious = this.navigator.hasPreviousYear();

    if (value)
      this.hasNext = false;
    else
      this.hasNext = this.navigator.hasNextYear();
  }

  private async make(){
    
    this.disableButtons(true);
    this.iterationFlag = true;
    this.mapas = [];
    let _metrica = (this.config.metrica == 'media')? 'thiessen' : 'chuva';
    let year = this.config.ano;

    year = (year % 10) ? Math.floor((year) / 10) * 10 : Math.floor((year-1) / 10) * 10;
    const urls = new Array(10);

    //cria grid com valores em branco e mapa de imagem padrão
    for (const [idx, url] of urls.entries()) {
      var ano = idx+year+1;
      var produto = this.config.produto == 'reservatorios' ? 'acudes' : this.config.produto;
      this.mapas.push({
        ativo: false,
        label: '',
        link: new Date(ano,0,1),
        path: '',
        date: new Date(this.config.ano,0,1),
      })  
    }

    let currentDate = new Date();

    //popula a grid com os valores corretos do mapa
    for (const [idx, url] of urls.entries()) {
      var ano = idx+year+1;
      var produto = this.config.produto == 'reservatorios' ? 'acudes' : this.config.produto;
      var path = 'https://cdn.funceme.br/calendario/chuvas/' + produto + '/' + _metrica + '/anos/' + ano + '/mapa.gif';
      
      let date = new Date(ano, 0, 1);

      if (date <= currentDate)
      {
        const resp = await fetch(path);

        delete this.mapas[idx];
      
        if (resp['status'] == 200){
          //this.mapas.push({
          this.mapas[idx] = ({
            ativo: true,
            label: ano+'',
            link: new Date(ano,0,1),
            path: path,
            date: new Date(this.config.ano,0,1),
          })
        } else {
          //this.mapas.push({
          this.mapas[idx] = ({
            ativo: true,
            label: ano+'',
            link: new Date(ano,0,1),
            path: 'https://cdn.funceme.br/calendario/chuvas/mapa.gif',
            date: new Date(this.config.ano,0,1),
          }) 
        }
      }
      else
      {
        this.mapas[idx] = ({
          ativo: true,
          label: ano+'',
          link: new Date(ano,0,1),
          path: 'https://cdn.funceme.br/calendario/chuvas/mapa.gif',
          date: new Date(this.config.ano,0,1),
        }) 
      }
    }
    //this.mapas.reverse();

    this.iterationFlag = false;
    this.disableButtons(false);
  }

}