import { Component, Directive, OnInit, OnDestroy } from '@angular/core';
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {FormControl} from '@angular/forms';

import * as _moment from 'moment';
import {default as _rollupMoment, Moment} from 'moment';
import { MatDatepicker } from '@angular/material/datepicker';
import { ConfigFacade, Config } from '@funceme/core-app';
import { Subscription } from 'rxjs';

const moment = _rollupMoment || _moment;

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const DAY_FORMAT = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  }
};

export const MONTH_FORMAT = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  }
};

export const YEAR_FORMAT = {
  parse: {
    dateInput: 'YYYY',
  },
  display: {
    dateInput: 'YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  }
};

@Component({
  selector: 'app-calendario-param-data',
  templateUrl: './calendario-param-data.component.html',
  styleUrls: ['./calendario-param-data.component.css'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_DATE_FORMATS, useValue: DAY_FORMAT},
  ]
})
export class CalendarioParamDataComponent implements OnInit, OnDestroy {

  public config: Config;
  private subscription: Subscription;

  public dataMin: Date = new Date('1973/1/1');
  public dataMax: Date = new Date();

  public inputDate: string;

  public decades = []

  public selected_decade;

  date = new FormControl(moment());

  constructor(private configFacade: ConfigFacade) 
  {   
    this.subscription = this.configFacade.current$.subscribe(value => {

      this.config = value

      this.selected_decade = 
        (this.config.ano % 10) ? 
        Math.floor((this.config.ano) / 10) * 10 + 10: 
        Math.floor((this.config.ano-1) / 10) * 10 + 10;

      const ctrlValue = this.date.value;
      ctrlValue.year(value.ano);

      if (this.config.mes)
        ctrlValue.month(value.mes-1);
      
      if (this.config.dia)
        ctrlValue.date(value.dia);

      this.date.setValue(ctrlValue);
    });
  }

  ngOnInit() {
    this.makeSelect()
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  keyUp(event: KeyboardEvent) 
  {
    event.preventDefault();

    if (event.keyCode == 13) {
      if (this.config.periodo == 'dia') {
        this.config.ano = this.date.value.year();
        this.config.mes = this.date.value.month()+1;
        this.config.dia = this.date.value.date();
        this.configFacade.save(this.config);
      } else if (this.config.periodo == 'diario'
        || this.config.periodo == 'mes' 
        || this.config.periodo == 'bimestre' 
        || this.config.periodo == 'trimestre'
        || this.config.periodo == 'quadrimestre'
      ) {
        this.config.ano = this.date.value.year();
        this.config.mes = this.date.value.month()+1;
        this.configFacade.save(this.config);
      } else if(this.config.periodo == 'ano' 
        || this.config.periodo == 'mensal' 
        || this.config.periodo == 'bimestral' 
        || this.config.periodo == 'trimestral'
        || this.config.periodo == 'quadrimestral') {
        this.config.ano = this.date.value.year();
        this.configFacade.save(this.config);
      }
    }
  }

  chosenYearHandler(normalizedYear: Moment, datepicker: MatDatepicker<Moment>) 
  {
    const ctrlValue = this.date.value;

    if (!ctrlValue) return;

    ctrlValue.year(normalizedYear.year());
    this.date.setValue(ctrlValue);

    if (this.config.periodo == 'ano' 
      || this.config.periodo == 'mensal' 
      || this.config.periodo == 'bimestral' 
      || this.config.periodo == 'trimestral'
      || this.config.periodo == 'quadrimestral'
    ) {
      datepicker.close();
      
      this.config.ano = normalizedYear.year();
      this.configFacade.save(this.config);
    }
  }

  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) 
  {
    const ctrlValue = this.date.value;

    if (!ctrlValue) return;

    ctrlValue.month(normalizedMonth.month());
    this.date.setValue(ctrlValue);

    if (this.config.periodo == 'diario'
      || this.config.periodo == 'mes' 
      || this.config.periodo == 'bimestre' 
      || this.config.periodo == 'trimestre'
      || this.config.periodo == 'quadrimestre'
    ) {
      datepicker.close();

      this.config.ano = normalizedMonth.year();
      this.config.mes = normalizedMonth.month()+1;

      this.configFacade.save(this.config);
    }
  }

  chosenDayHandler(event, datepicker: MatDatepicker<Moment>) 
  {
    const ctrlValue = this.date.value;
    let normalizedDay = event.value;

    if (!ctrlValue || !normalizedDay) return;
    
    ctrlValue.date(normalizedDay.date());
    this.date.setValue(ctrlValue);

    if (this.config.periodo == 'dia') {
      this.config.ano = normalizedDay.year();
      this.config.mes = normalizedDay.month()+1;
      this.config.dia = normalizedDay.date();

      this.configFacade.save(this.config);
    }
  }

  selectDecade(event){
    this.config.ano = event.value;
    this.configFacade.save(this.config);
  }

  makeSelect(){
    let year = new Date().getFullYear();
    let decade = (year % 10) ? Math.floor((year) / 10) * 10 + 10: Math.floor((year-1) / 10) * 10 + 10;

    let diff = Math.floor((decade - 1970) / 10)

    while (diff) {
      let dec = {value:decade,viewValue:(decade-9)+' - '+decade}
      this.decades.push(dec);
      decade = decade - 10;
      diff--;
    }
  }
}

@Directive({
  selector: '[dateFormatMonth]',
  providers: [
    {provide: MAT_DATE_FORMATS, useValue: MONTH_FORMAT},
  ],
})
export class CustomDateMonthFormat {
}

@Directive({
  selector: '[dateFormatYear]',
  providers: [
    {provide: MAT_DATE_FORMATS, useValue: YEAR_FORMAT},
  ],
})
export class CustomDateYearFormat {
}
