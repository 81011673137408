import { Injectable } from '@angular/core';
import { ActionsSubject, select, Store } from '@ngrx/store';
import * as PluviometriaNormalizadaFuncemeActions from './pluviometria-normalizada-funceme.actions';
import { PluviometriaNormalizadaFuncemeState } from './pluviometria-normalizada-funceme.reducer';
import { selectAllPluviometriasNormalizadasFunceme, selectCurrentPluviometriasNormalizadasFunceme, selectCurrentPluviometriaNormalizadaFunceme, selectMetaDataPluviometriasNormalizadasFunceme, selectTotalPluviometriasNormalizadasFunceme, selectLoadingPluviometriasNormalizadasFunceme } from './pluviometria-normalizada-funceme.selectors';
import { Pagination } from '../../generics/pagination.model';
import { GenericFacade } from '../../generics/generic-facade';

@Injectable({ providedIn: 'root' })
export class PluviometriaNormalizadaFuncemeFacade implements GenericFacade
{
  all$ = this.store.pipe(select(selectAllPluviometriasNormalizadasFunceme));
  current$ = this.store.pipe(select(selectCurrentPluviometriaNormalizadaFunceme));
  currentList$ = this.store.pipe(select(selectCurrentPluviometriasNormalizadasFunceme));
  metaData$ = this.store.pipe(select(selectMetaDataPluviometriasNormalizadasFunceme));
  total$ = this.store.pipe(select(selectTotalPluviometriasNormalizadasFunceme));
  loading$ = this.store.pipe(select(selectLoadingPluviometriasNormalizadasFunceme));

  constructor(private store: Store<PluviometriaNormalizadaFuncemeState>, private actions$: ActionsSubject) {}

  selectOne(pluviometrianormalizadafuncemeId: number) {
    this.store.dispatch(new PluviometriaNormalizadaFuncemeActions.PluviometriasNormalizadasFuncemeelected(pluviometrianormalizadafuncemeId));
  }

  selectMany(pluviometrianormalizadafuncemeIds: number[]) {
    this.store.dispatch(new PluviometriaNormalizadaFuncemeActions.PluviometriasNormalizadasFuncemeSelected(pluviometrianormalizadafuncemeIds));
  }

  unselectMany(pluviometrianormalizadafuncemeIds: number[]) {
    this.store.dispatch(new PluviometriaNormalizadaFuncemeActions.PluviometriasNormalizadasFuncemeUnselected(pluviometrianormalizadafuncemeIds));
  }

  load(reset: boolean) {
    this.store.dispatch(new PluviometriaNormalizadaFuncemeActions.LoadPluviometriasNormalizadasFunceme());
  }

  paginate(pagination: Pagination) {
    this.store.dispatch(new PluviometriaNormalizadaFuncemeActions.PaginatePluviometriasNormalizadasFunceme(pagination));
  }

  download(pagination: Pagination) {
    this.store.dispatch(new PluviometriaNormalizadaFuncemeActions.DownloadPluviometriasNormalizadasFunceme(pagination));
  }

  select(id: number) {
    this.selectOne(id);
  }

  pick(id: number) {}
  destroy(id: number) {};
  save(model: any) {};
  index() {};
  edit(id: number) {};
  show(id: number) {};
  create(): void {};
  refresh(): void {};
}
