import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MacrorregiaoSelectComponent } from './macrorregiao-select/macrorregiao-select.component';
import { CoreDataModule } from '../../core-app.module';

@NgModule({
  declarations: [
    MacrorregiaoSelectComponent
  ],
  imports: [
    CommonModule,
    CoreDataModule
  ],
  exports: [
    MacrorregiaoSelectComponent
  ]
})
export class MacrorregiaoModule { }
