import { Component, OnInit, Input } from '@angular/core';
import { SelectOneSchema } from '../../../generics/select-one/select-one.schema';
import { Pagination, getPaginationDefault } from '../../../generics/pagination.model';
import { MacrorregiaoFacade } from '../../../state/macrorregiao/macrorregiao.facade';
import { Macrorregiao } from '../../../state/macrorregiao/macrorregiao.model';

@Component({
  selector: 'macrorregiao-select',
  templateUrl: './macrorregiao-select.component.html',
  styleUrls: ['./macrorregiao-select.component.css']
})
export class MacrorregiaoSelectComponent implements OnInit {

  public schema: SelectOneSchema;
  private pagination: Pagination = getPaginationDefault();

  @Input() showItensWithIds: number[] = [];
  @Input() hideItensWithIds: number[] = [];

  constructor(public facade: MacrorregiaoFacade) { 
    this.pagination.paginator.limit = 999999;
  }

  ngOnInit() 
  {
    this.schema = {
      label: 'Macrorregião',
      placeholder: 'Selecionar Macrorregião',
      facade: this.facade,
      include_list: this.showItensWithIds,
      exclude_list: this.hideItensWithIds,
      getId(item: Macrorregiao) {
        return item.id;
      },
      getName(item: Macrorregiao) {
        return item.nome
      }
    };   

    this.facade.paginate(this.pagination);
  }

}
