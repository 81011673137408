import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CalendarioSerieService {

    private apiEndpoint = 'https://apil5.funceme.br/'
    private apiPath = 'rest/pluvio/pluviometria-funceme-normalizada';

    private posto: string = '';

    private _order_by: string;
    private _limit;
    private data_inicial: string;
    private data_final: string;

    private serviceListener = new Subject<any>();
    public  serviceListener$ = this.serviceListener.asObservable();

    constructor(private http: HttpClient) 
    { 

    }

    public atPosto(posto: string)
    {
        this.posto = posto;
        return this;
    }

    public startsAt(date: string) 
    {
        this.data_inicial = date;
        return this;
    }

    public endsAt(date: string) 
    {
        this.data_final = date;
        return this;
    }

    public limit(limit) 
    {
        this._limit = limit;
        return this;
    }

    getUrl() {
      return `${this.apiEndpoint}${this.apiPath}`;
    }

    public get(callback?, errorHandler?): void
    {
        let url = this.getUrl() + '?posto=' + this.posto
          
        if (this.data_inicial)
            url += '&data-gte=' + this.data_inicial; 
            
        if (this.data_final)
            url += '&data-lt=' + this.data_final; 
        
        if (!this._limit)
            this._limit = 'no';

        url += '&limit=' + this._limit;   

        if (!this._order_by)
            this._order_by = 'data,asc';

        url += '&orderBy=' + this._order_by;    
        
        this.http.get(url).subscribe((response) => {
            
            if (callback)
                callback(response);
            this.serviceListener.next(response);   
        }, (error) => {
            if (errorHandler)
                errorHandler(error);
        });
    }
}
