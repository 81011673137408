import { Component, Inject, OnDestroy, OnInit, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PluviometriaNormalizadaFunceme } from '../../../state/pluviometria-normalizada-funceme/pluviometria-normalizada-funceme.model';
import { PluviometriaNormalizadaFuncemeFacade } from '../../../state/pluviometria-normalizada-funceme/pluviometria-normalizada-funceme.facade';
import { Pagination, getPaginationDefault } from '../../../generics/pagination.model';
import { Subscription } from 'rxjs';
import { PostoFacade } from '../../../state/posto/posto.facade';
import { Posto } from '../../../state/posto/posto.model';

import * as _moment from 'moment';
import {default as _rollupMoment, Moment} from 'moment';
import { DatepickerSchema } from '../../../generics/zmat-datepicker/zmat-datepicker.schema';

const moment = _rollupMoment || _moment;

@Component({
  selector: 'funceme-pluviometria-normalizada-funceme-modal',
  templateUrl: './pluviometria-normalizada-funceme-modal.component.html',
  styleUrls: ['./pluviometria-normalizada-funceme-modal.component.css']
})
export class PluviometriaNormalizadaFuncemeModalComponent implements OnInit, OnDestroy, AfterViewInit{

  public filters: any[] = [];
  public dados: PluviometriaNormalizadaFunceme[];

  private subscriptions = new Subscription();

  private pagination: Pagination = getPaginationDefault();

  public loading: boolean = false;

  public postoNome: string = '';
  public postoId: string = '';

  public selectedTab: string = 'last';

  public dayFromSchema: DatepickerSchema;
  public dayToSchema: DatepickerSchema;
  public monthSchema: DatepickerSchema;

  private dayFrom: Moment;
  private dayTo: Moment;

  constructor(
    public dialogRef: MatDialogRef<PluviometriaNormalizadaFuncemeModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Posto,
    private facade: PluviometriaNormalizadaFuncemeFacade,
    public postoFacade: PostoFacade
  ) { 

    this.dayFromSchema = {
      placeholder: 'Data inícial',
      value: (new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)), // 7 days ago
      max: new Date()
    }

    this.dayToSchema = {
      placeholder: 'Data final',
      value: new Date(),
      max: new Date()
    }

    this.monthSchema = {
      placeholder: 'Selecione o mês',
      value: new Date(),
      max: new Date()
    }

    this.subscriptions
    .add(this.facade.all$.subscribe(values =>      
      this.dados = values      
    ))
    .add(this.facade.metaData$.subscribe(
      value => {
        if (!value) return;
        this.pagination = value.request.query_params;
      }
    ))
    .add(this.facade.loading$.subscribe(value => 
      this.loading = value
    ))
    .add(this.postoFacade.current$.subscribe(value => {
      this.postoNome = (value)? value.nome : '';
      this.postoId = (value)? value.id +'' : '';
    }));
  }

  ngOnInit() {    
    this.postoFacade.select(this.data.id);    
    this.loadLastDaysData();
  }

  ngOnDestroy()
  {
    this.subscriptions.unsubscribe();
  }

  ngAfterViewInit() {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  close(): void {
    this.dialogRef.close();
  }

  public dayFromSelected($event: Moment) 
  {
    this.dayFrom = $event;
    if (this.dayTo)
      this.loadLastDaysData();
  }

  public dayToSelected($event: Moment) 
  {
    this.dayTo = $event;
    if (this.dayFrom)
      this.loadLastDaysData();
  }

  public monthSelected($event: Moment) 
  {
    this.loadMonthlyData($event);
  }

  public yearSelected($event: Moment) 
  {
    this.loadJan2MaiData($event);
  }

  public optionChanged($event) 
  {
    this.selectedTab = $event.value;

    switch ($event.value) {
      case 'last':    this.loadLastDaysData(); break;
      case 'month':   this.loadMonthlyData(); break;
      case 'jan2mai': this.loadJan2MaiData(); break;
      case 'greater': this.loadGreaterData(); break;
    }
  }

  private loadLastDaysData()
  {
    if (!this.dayFrom || !this.dayTo)
      return;

    this.dados = [];

    this.pagination.filter_list['posto'] = this.data.id; 

    this.pagination.filter_list['data-gte'] = this.dayFrom.year() + '-' + (this.dayFrom.month()+1) + '-' + this.dayFrom.date();
    this.pagination.filter_list['data-lte'] = this.dayTo.year() + '-' + (this.dayTo.month()+1) + '-' + this.dayTo.date();

    this.pagination.paginator.order_by = 'data,desc';    
    this.pagination.paginator.limit = 9999999;
    this.facade.paginate(this.pagination);
  }

  private loadMonthlyData(date: Moment = null)
  {
    if (!date)
      date = moment();

    this.dados = [];

    this.pagination.paginator.order_by = 'data';
    this.pagination.filter_list['posto'] = this.data.id; 
    this.pagination.filter_list['data-gte'] = date.year() + '-' + (date.month()+1) + '-1';
    this.pagination.filter_list['data-lte'] = date.year() + '-' + (date.month()+1) + '-' + date.daysInMonth();
    this.pagination.paginator.limit = 31;
    this.facade.paginate(this.pagination);
  }

  private loadJan2MaiData(date: Moment = null)
  {
    if (!date)
      date = moment();

    this.dados = [];

    this.pagination.paginator.order_by = 'data';
    this.pagination.filter_list['posto'] = this.data.id; 
    this.pagination.filter_list['data-gte'] = date.year() + '-01-01';
    this.pagination.filter_list['data-lte'] = date.year() + '-05-31';
    this.pagination.paginator.limit = 999;
    this.facade.paginate(this.pagination);
  }

  private loadGreaterData()
  {
    this.dados = [];

    this.pagination.filter_list['posto'] = this.data.id; 

    delete(this.pagination.filter_list['data-gte']);
    delete(this.pagination.filter_list['data-lte']);

    this.pagination.paginator.order_by = 'valor,desc';
    this.pagination.paginator.limit = 10;
    this.facade.paginate(this.pagination);
  }

  download() {
    this.facade.download(this.pagination);
  }
}
