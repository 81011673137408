import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/nx';
import { map } from 'rxjs/operators';

import { RegiaoHidrografica } from './regiao-hidrografica.model';
import { RegiaoHidrograficaService } from './regiao-hidrografica.service';

import {
  LoadRegiaoHidrografica, RegiaoHidrograficaLoaded,
  RegioesHidrograficasActionTypes,
  RegiaoHidrograficaPaginated,
  PaginateRegiaoHidrografica,
  RegiaoHidrograficaRequestDone,
  DownloadRegiaoHidrografica,
  RegiaoHidrograficaRequestFailed
} from './regiao-hidrografica.actions';
import { RegiaoHidrograficaState } from './regiao-hidrografica.reducer';
import { NotificationsService } from '../../notifications/notifications.service';
import { RegiaoHidrograficaFacade } from './regiao-hidrografica.facade';
import { ApiResponse, paginationEquals } from '../../generics/pagination.model';

@Injectable({providedIn: 'root'})
export class RegiaoHidrograficaEffects 
{
  //@Effect() effect$ = this.actions$.ofType(RegiaoHidrograficaActionTypes.RegiaoHidrograficaAction);

  @Effect()
  loadRegiaoHidrografica$ = this.dataPersistence.fetch(RegioesHidrograficasActionTypes.LoadRegiaoHidrografica, {
    run: (action: LoadRegiaoHidrografica, state: any) => {

      if(Object.keys(state.regiaoHidrografica.entities).length > 0){
        let allRegiaoHidrografica;

        this.regiaoHidrograficaFacade.all$.subscribe(regiaoHidrografica => allRegiaoHidrografica = regiaoHidrografica )
        return new RegiaoHidrograficaLoaded(allRegiaoHidrografica);
      }

      return this.regiaoHidrograficaService
        .all()
        .pipe(
          map((res: RegiaoHidrografica[]) => new RegiaoHidrograficaLoaded(res['data']['list']))
        )
    },

    onError: (action: LoadRegiaoHidrografica, error) => {
      this.notifyError.emit(error.statusText);
      return new RegiaoHidrograficaRequestFailed;
    }
  });

  @Effect()
  paginateRegiaoHidrografica$ = this.dataPersistence.fetch(RegioesHidrograficasActionTypes.PaginateRegiaoHidrografica, {
    run: (action: PaginateRegiaoHidrografica, state: any) => {

      if (state.regiaoHidrografica.last_pagination && paginationEquals(action.payload, JSON.parse(state.regiaoHidrografica.last_pagination))) 
        return new RegiaoHidrograficaRequestDone;

      return this.regiaoHidrograficaService
        .paginate(action.payload)
        .pipe(
          map((res: ApiResponse<RegiaoHidrografica>) => new RegiaoHidrograficaPaginated(res))
        )
    },

    onError: (action: PaginateRegiaoHidrografica, error) => {
      this.notifyError.emit(error.statusText);
      return new RegiaoHidrograficaRequestFailed;
    }
  });

  @Effect()
  downloadRegiaoHidrografica$ = this.dataPersistence.fetch(RegioesHidrograficasActionTypes.DownloadRegiaoHidrografica, {
    run: (action: DownloadRegiaoHidrografica, state: any) => {
      this.regiaoHidrograficaService.download(action.payload);
      return new RegiaoHidrograficaRequestDone;
    },

    onError: (action: DownloadRegiaoHidrografica, error) => {
      this.notifyError.emit(error.statusText);
      return new RegiaoHidrograficaRequestFailed;
    }
  });

  constructor(
    private actions$: Actions,
    private dataPersistence: DataPersistence<RegiaoHidrograficaState>,
    private regiaoHidrograficaService: RegiaoHidrograficaService,
    private regiaoHidrograficaFacade: RegiaoHidrograficaFacade,
    private notifyError: NotificationsService
  ) {}
}
