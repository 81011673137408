import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/nx';
import { map } from 'rxjs/operators';

import { PluviometriaNormalizadaFunceme } from './pluviometria-normalizada-funceme.model';
import { PluviometriaNormalizadaFuncemeService } from './pluviometria-normalizada-funceme.service';

import {
  LoadPluviometriasNormalizadasFunceme, PluviometriasNormalizadasFuncemeLoaded,
  PluviometriaNormalizadaFuncemeActionTypes,
  PluviometriasNormalizadasFuncemePaginated,
  PaginatePluviometriasNormalizadasFunceme,
  PluviometriasNormalizadasFuncemeRequestDone,
  DownloadPluviometriasNormalizadasFunceme,
  PluviometriasNormalizadasFuncemeRequestFailed
} from './pluviometria-normalizada-funceme.actions';
import { PluviometriaNormalizadaFuncemeState } from './pluviometria-normalizada-funceme.reducer';
import { NotificationsService } from '../../notifications/notifications.service';
import { PluviometriaNormalizadaFuncemeFacade } from './pluviometria-normalizada-funceme.facade';
import { ApiResponse, paginationEquals } from '../../generics/pagination.model';

@Injectable({providedIn: 'root'})
export class PluviometriaNormalizadaFuncemeEffects 
{
  //@Effect() effect$ = this.actions$.ofType(PluviometriasNormalizadasFuncemeActionTypes.PluviometriasNormalizadasFuncemeAction);

  @Effect()
  loadPluviometriasNormalizadasFunceme$ = this.dataPersistence.fetch(PluviometriaNormalizadaFuncemeActionTypes.LoadPluviometriasNormalizadasFunceme, {
    run: (action: LoadPluviometriasNormalizadasFunceme, state: any) => {

      if(Object.keys(state.pluviometriaNormalizadaFunceme.entities).length > 0){
        let allPluviometriasNormalizadasFunceme;

        this.pluviometriaNormalizadaFuncemeFacade.all$.subscribe(pluviometriasNormalizadasFunceme => allPluviometriasNormalizadasFunceme = pluviometriasNormalizadasFunceme )
        return new PluviometriasNormalizadasFuncemeLoaded(allPluviometriasNormalizadasFunceme);
      }

      return this.pluviometriaNormalizadaFuncemService
        .all()
        .pipe(
          map((res: PluviometriaNormalizadaFunceme[]) => new PluviometriasNormalizadasFuncemeLoaded(res['data']['list']))
        )
    },

    onError: (action: LoadPluviometriasNormalizadasFunceme, error) => {
      this.notifyError.emit(error.statusText);
      return new PluviometriasNormalizadasFuncemeRequestFailed;
    }
  });

  @Effect()
  paginatePluviometriasNormalizadasFunceme$ = this.dataPersistence.fetch(PluviometriaNormalizadaFuncemeActionTypes.PaginatePluviometriasNormalizadasFunceme, {
    run: (action: PaginatePluviometriasNormalizadasFunceme, state: any) => {

      if (state.pluviometriaNormalizadaFunceme.last_pagination && paginationEquals(action.payload, JSON.parse(state.pluviometriaNormalizadaFunceme.last_pagination))) 
        return new PluviometriasNormalizadasFuncemeRequestDone;

      return this.pluviometriaNormalizadaFuncemService
        .paginate(action.payload)
        .pipe(
          map((res: ApiResponse<PluviometriaNormalizadaFunceme>) => new PluviometriasNormalizadasFuncemePaginated(res))
        )
    },

    onError: (action: PaginatePluviometriasNormalizadasFunceme, error) => {
      this.notifyError.emit(error.statusText);
      return new PluviometriasNormalizadasFuncemeRequestFailed;
    }
  });

  @Effect()
  downloadPluviometriasNormalizadasFunceme$ = this.dataPersistence.fetch(PluviometriaNormalizadaFuncemeActionTypes.DownloadPluviometriasNormalizadasFunceme, {
    run: (action: DownloadPluviometriasNormalizadasFunceme, state: any) => {
      this.pluviometriaNormalizadaFuncemService.download(action.payload);
      return new PluviometriasNormalizadasFuncemeRequestDone;
    },

    onError: (action: DownloadPluviometriasNormalizadasFunceme, error) => {      
      this.notifyError.emit(error.statusText);
      return new PluviometriasNormalizadasFuncemeRequestFailed;
    }
  });

  constructor(
    private actions$: Actions,
    private dataPersistence: DataPersistence<PluviometriaNormalizadaFuncemeState>,
    private pluviometriaNormalizadaFuncemService: PluviometriaNormalizadaFuncemeService,
    private pluviometriaNormalizadaFuncemeFacade: PluviometriaNormalizadaFuncemeFacade,
    private notifyError: NotificationsService
  ) {}
}
