import { Component, OnInit, OnDestroy, Input, ViewChild } from '@angular/core';
import { PostoFacade } from '../../../state/posto/posto.facade';
import { Posto } from '../../../state/posto/posto.model';
import { LazyGridTdTextComponent } from '../../../generics/lazy-grid/lazy-grid-td-text/lazy-grid-td-text.component';
import { TableSchema } from '../../../generics/lazy-grid/table.schema';
import { OrgaoFacade } from '../../../state/orgao/orgao.facade';
import { getPaginationDefault, Pagination } from '../../../generics/pagination.model';
import { Subscription } from 'rxjs';
import { BaciaFuncemeFacade } from '../../../state/bacia-funceme/bacia-funceme.facade';
import { MunicipioFacade } from '../../../state/municipio/municipio.facade';
import { PluviometriaNormalizadaFuncemeModalButtonComponent } from '../../pluviometria-normalizada-funceme/pluviometria-normalizada-funceme-modal-button/pluviometria-normalizada-funceme-modal-button.component';
import { PluviometriaNormalizadaFuncemeDownloadButtonComponent } from '../../pluviometria-normalizada-funceme/pluviometria-normalizada-funceme-download-button/pluviometria-normalizada-funceme-download-button.component';
import { RegiaoHidrograficaFacade } from '../../../state/regiao-hidrografica/regiao-hidrografica.facade';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { MapComponent } from '../../../generics/map/map.component';
import { MapSchema, PointSchema } from '../../../generics/map/map.schema';
import { PluviometriaNormalizadaFuncemeModalComponent } from '../../pluviometria-normalizada-funceme/pluviometria-normalizada-funceme-modal/pluviometria-normalizada-funceme-modal.component';

@Component({
  selector: 'posto-lgrid',
  templateUrl: './posto-lgrid.component.html',
  styleUrls: ['./posto-lgrid.component.css']
})
export class PostoLgridComponent implements OnInit, OnDestroy {

  private subscriptions = new Subscription();

  public postos: Posto[] = [];
  public localizar: string;
  public statusFilter: boolean = false;

  @Input() enableActions: boolean = true;
  @Input() showColumns: string[] = [];
  @Input() showFilters: string[] = [];

  @ViewChild('_map_', { static: true }) map: MapComponent;

  private pagination: Pagination;

  private cleaning: boolean = false;

  public tableSchema: TableSchema;

  public mapSchema: MapSchema;

  private limitDefault: number = 10;

  private dialogRef: MatDialogRef<PluviometriaNormalizadaFuncemeModalComponent, any>;

  constructor(
    public postoFacade: PostoFacade,
    private orgaoFacade: OrgaoFacade,
    private baciaFuncemeFacade: BaciaFuncemeFacade,
    private municipioFacade: MunicipioFacade,
    private regiaoHidrograficaFacade: RegiaoHidrograficaFacade,
    public dialog: MatDialog
  ) {

    this.mapSchema = {
      center: [-39.6, -5.3],
      zoom: 8,
      marker: 'assets/img/posto.png',
      parsePointData: (values: Posto[]) => {
        return values.map(item => {
          return {
            lon: item.geom.coordinates[0],
            lat: item.geom.coordinates[1],
            name: item.nome,
            data: item
          }
        })
      },
      parseColor: (value: Posto) => {
        if (value.ativo)
          return '#0067C0';
        else
          return '#B81B22';
      },
      onClick: (point: PointSchema) => {
        this.openModal(point.data)
      }
    }

    this.tableSchema = {
      columns: [
        {
          title: "ID",
          field: 'id',
          ordenable: true,
          render: LazyGridTdTextComponent,
          getData: function(obj: Posto) {
            return obj.id;
          }
        },
        {
          title: "Posto",
          field: 'nome',
          ordenable: true,
          render: LazyGridTdTextComponent,
          getData: function(obj: Posto) {
            return obj.nome;
          }
        },
        {
          title: "Município",
          field: 'municipio_id',
          ordenable: true,
          render: LazyGridTdTextComponent,
          getData: function(obj: Posto) {
            return (obj.municipio)? obj.municipio.nome : '';
          }
        },
        // {
        //   title: "Macrorregiao",
        //   field: 'bacia_id',
        //   ordenable: true,
        //   render: LazyGridTdTextComponent,
        //   getData: function(obj: Posto) {
        //     return (obj.bacia)? obj.bacia.nome : '';
        //   }
        // },
        {
          title: "Região",
          field: 'regiaoHidrografica',
          ordenable: false,
          render: LazyGridTdTextComponent,
          getData: function(obj: Posto) {
            return (obj.regiao_hidrografica && obj.regiao_hidrografica.length > 0)? obj.regiao_hidrografica[0].nome : '--';
          }
        },
        {
          title: "Orgao",
          field: 'orgao_id',
          ordenable: true,
          render: LazyGridTdTextComponent,
          getData: function(obj: Posto) {
            return (obj.orgao)? obj.orgao.sigla : '';
          }
        },
        {
          title: "Lon",
          field: '',
          ordenable: false,
          render: LazyGridTdTextComponent,
          getData: function(obj: Posto) {
            return obj.geom.coordinates[0].toFixed(4);
          }
        },
        {
          title: "Lat",
          field: '',
          ordenable: false,
          render: LazyGridTdTextComponent,
          getData: function(obj: Posto) {
            return obj.geom.coordinates[1].toFixed(4);
          }
        },
        {
          title: "Status",
          field: 'ativo',
          ordenable: false,
          render: LazyGridTdTextComponent,
          getData: function(obj: Posto) {
            return (obj.ativo)? 'ATIVO' : 'INATIVO';
          }
        }
      ],
      actions: [
        {
          title: "Dados",
          label: "Visualizar dados",
          color: "primary",
          icon: 'list',
          render: PluviometriaNormalizadaFuncemeModalButtonComponent,
          action: function(obj: Posto) { },
          getData: function() {}
        },
        {
          title: "Dados",
          label: "Visualizar dados",
          color: "primary",
          icon: 'list',
          render: PluviometriaNormalizadaFuncemeDownloadButtonComponent,
          action: function(obj: Posto) { },
          getData: function() {}
        }
      ]
    }
  }

  openModal(posto: Posto): void
  {
    this.dialogRef = this.dialog.open(PluviometriaNormalizadaFuncemeModalComponent, {
      width: '800px',
      data: posto
    });

    this.dialogRef.afterClosed().subscribe(result => {

    });
  }

  ngOnInit()
  {
    this.load();

    this.subscriptions.add(
      this.postoFacade.all$.subscribe(values => {
        this.postos = values;
        this.cleaning = false;
      })
    ).add(
      this.postoFacade.metaData$.subscribe(value => {
        if (value == null || value === undefined || !value) return;
        this.pagination = value.request.query_params;
        this.pagination.paginator.limit = this.limitDefault;
        this.localizar = value.request.query_params.filter_list['nome-lk'];
      })
    ).add(
      this.orgaoFacade.current$.subscribe(value => {
        if (value == null || value === undefined || !value.id || this.cleaning) return;
        if (this.pagination.filter_list.hasOwnProperty('orgao_id') && this.pagination.filter_list['orgao_id'] == value.id) return;

        this.pagination.filter_list['orgao_id'] = value.id;
        this.pagination.paginator.page = 1;
        this.pagination.paginator.limit = this.limitDefault;

        this.postoFacade.paginate(this.pagination);
      })
    ).add(
      this.baciaFuncemeFacade.current$.subscribe(value => {
        if (value == null || value === undefined || !value.id || this.cleaning) return;
        if (this.pagination.filter_list.hasOwnProperty('bacia_id') && this.pagination.filter_list['bacia_id'] == value.id) return;

        this.pagination.filter_list['bacia_id'] = value.id;
        this.pagination.paginator.page = 1;
        this.pagination.paginator.limit = this.limitDefault;

        this.postoFacade.paginate(this.pagination);
      })
    ).add(
      this.municipioFacade.current$.subscribe(value => {
        if (value == null || value === undefined || !value.id || this.cleaning) return;
        if (this.pagination.filter_list.hasOwnProperty('municipio_id') && this.pagination.filter_list['municipio_id'] == value.id) return;

        this.pagination.filter_list['municipio_id'] = value.id;
        this.pagination.paginator.page = 1;
        this.pagination.paginator.limit = this.limitDefault;

        this.postoFacade.paginate(this.pagination);
      })
    ).add(
      this.regiaoHidrograficaFacade.current$.subscribe(value => {
        if (value == null || value === undefined || !value.id || this.cleaning) return;
        if (this.pagination.filter_list.hasOwnProperty('regiaoHidrografica') && this.pagination.filter_list['regiaoHidrografica'] == value.id) return;

        this.pagination.filter_list['regiaoHidrografica'] = value.id;
        this.pagination.paginator.page = 1;
        this.pagination.paginator.limit = this.limitDefault;

        this.postoFacade.paginate(this.pagination);
      })
    );

    //this.statusChanged();

  }

  tabChanged($event: MatTabChangeEvent) {

    this.postos = [];

    if ($event.tab.textLabel == "Mapa") {
      this.pagination.paginator.limit = 9999999;
      this.limitDefault = 9999999;
      this.postoFacade.paginate(this.pagination);
      this.map.init();

    } else if ($event.tab.textLabel == "Tabela") {
      this.pagination.paginator.limit = 10;
      this.limitDefault = 10;
      this.postoFacade.paginate(this.pagination);
    }
  }

  load()
  {
    this.pagination = getPaginationDefault();
    this.regiaoHidrograficaFacade.select(null);
    this.statusFilter = true;

    this.baciaFuncemeFacade.select(null);
    this.orgaoFacade.select(null);
    this.municipioFacade.select(null);

    this.pagination.with_list = ['municipio.nome', 'orgao.sigla', 'regiaoHidrografica.nome'];
    this.pagination.filter_list['ativo-eq'] = 1
    this.postoFacade.paginate(this.pagination);
  }

  cleanFilters()
  {
    this.cleaning = true;

    this.localizar = '';
    this.statusFilter = true;

    this.baciaFuncemeFacade.select(null);
    this.orgaoFacade.select(null);
    this.municipioFacade.select(null);
    this.regiaoHidrograficaFacade.select(null);

    this.pagination.filter_list = [];
    this.pagination.paginator.page = 1;
    this.pagination.filter_list['ativo-eq'] = this.statusFilter? 1 : 0;

    this.postoFacade.paginate(this.pagination);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  public statusChanged()
  {
    this.statusFilter = !this.statusFilter;

    this.pagination.filter_list['ativo-eq'] = this.statusFilter? 1 : 0;
    this.pagination.paginator.page = 1;

    this.postoFacade.paginate(this.pagination);
  }

  applyFilter($event)
  {
    if ($event.code == 'NumpadEnter' || $event.code == 'Enter') {
      this.pagination.paginator.page = 1;
      this.pagination.filter_list['nome-lk'] = $event.target.value;
      //this.pagination.filter_list['municipio_id'] = $event.target.value;
      this.postoFacade.paginate(this.pagination);
    }
  }

}
