import { Component, OnInit, OnDestroy } from '@angular/core';
import { ConfigFacade, Config } from '@funceme/core-app';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-calendario-param-periodo',
  templateUrl: './calendario-param-periodo.component.html',
  styleUrls: ['./calendario-param-periodo.component.css']
})
export class CalendarioParamPeriodoComponent implements OnInit, OnDestroy {

  public config: Config;
  private subscription: Subscription;

  public allPeriodos = [
    { 
      nome:"Diário", 
      abrev:"Diário",
      value:"diario",
      selected: false
    }, {
      nome:"Mensal",
      abrev:"Mensal",
      value:"mensal",
      selected: false
    }, {
      nome:"Bimestral",
      abrev:"Bim",
      value:"bimestral",
      selected: false
    }, {
      nome:"Trimestral",
      abrev:"Trim",
      value:"trimestral",
      selected: false
    }, {
      nome:"Quadrimestral",
      abrev:"Quad",
      value:"quadrimestral",
      selected: false
    }, {
      nome:"Anual",
      abrev:"Anual",
      value:"anual",
      selected: false
    }
  ];

  public periodos = [];

  constructor(public configFacade: ConfigFacade) 
  { 
    this.subscription = this.configFacade.current$.subscribe(value => {

      this.config = value;

      this.allPeriodos.map(item => {
        item.selected = ((item.value == 'diario' && (this.config.periodo == 'dia' || this.config.periodo == 'diario'))
          || (item.value == 'mensal' && (this.config.periodo == 'mes' || this.config.periodo == 'mensal'))
          || (item.value == 'bimestral' && (this.config.periodo == 'bimestre' || this.config.periodo == 'bimestral'))
          || (item.value == 'trimestral' && (this.config.periodo == 'trimestre' || this.config.periodo == 'trimestral'))
          || (item.value == 'quadrimestral' && (this.config.periodo == 'quadrimestre' || this.config.periodo == 'quadrimestral'))
          || (item.value == 'anual' && (this.config.periodo == 'ano' || this.config.periodo == 'anual')))
      });
      //value.produto == 'regioes'
      //value.produto == 'municipios' || 
      if (value.produto == 'bacias' || value.produto == 'reservatorios' || value.metrica == 'maxima') 
        this.periodos = this.allPeriodos.filter(item => 'diario-mensal-anual'.indexOf(item.value) != -1);
      else   
        this.periodos = this.allPeriodos;
      
    });
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  changedPeriodo(event) {
    this.config.periodo = event.value;
    this.configFacade.save(this.config);
  }
}
