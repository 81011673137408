import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RegiaoHidrograficaSelectComponent } from './regiao-hidrografica-select/regiao-hidrografica-select.component';
import { CoreDataModule } from '../../core-app.module';

@NgModule({
  declarations: [
    RegiaoHidrograficaSelectComponent
  ],
  imports: [
    CommonModule,
    CoreDataModule
  ],
  exports: [
    RegiaoHidrograficaSelectComponent
  ]
})
export class RegiaoHidrograficaModule { }
