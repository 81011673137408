import { Component, OnInit, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CalendarioItem } from '../../../shared/models/calendario.model';
import { Config, emptyConfig } from '@funceme/core-app';

@Component({
  selector: 'app-calendario-progress',
  templateUrl: './calendario-progress.component.html',
  styleUrls: ['./calendario-progress.component.css']
})
export class CalendarioProgressComponent implements OnInit {

  private _data = new BehaviorSubject<CalendarioItem[]>([]);

  @Input()
  set data(value) {
      this._data.next(value);
  };

  get data() {
    return this._data.getValue();
  }

  public postos_informados: number = 0; 
  public postos_com_chuva: number = 0; 

  
  constructor() { }

  ngOnInit() 
  {
    this._data.subscribe(values => {

      this.postos_informados = this.getPostosInformados();
      this.postos_com_chuva = this.getPostosComChuva();
    });
  }

  public getTotalPostos(): number
  {
    return this.data.length;
  }

  public getPostosInformados(): number
  {
    return this.data.filter(value => value.observado != null).length;
  }

  public getPostosComChuva(): number
  {
    return this.data.filter(value => value.observado > 0).length;
  }
}
