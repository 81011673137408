import { Component, OnInit, Input, ElementRef } from '@angular/core';
import { Config } from '@funceme/core-app';
import * as Highcharts from 'highcharts';

import { CalendarioItem } from '../../../shared/models/calendario.model';



@Component({
  selector: 'app-calendario-destaques',
  templateUrl: './calendario-destaques.component.html',
  styleUrls: ['./calendario-destaques.component.css']
})
export class CalendarioDestaquesComponent implements OnInit {

  chart = [];
    
  public title: string;
    
  public dados: CalendarioItem[];

  @Input()
  set setDados(value:CalendarioItem[]){
    this.dados = value;
    
    if (value[0] && value[0].municipio) this.draw();
  };

  @Input() public config: Config;

  public highcharts = Highcharts;
  public chartOptions = {   
    chart: {
       type: 'column',
      //  width: 600,
       reflow: true,
       plotBorderColor: '#C9C9C9',
       plotBorderWidth: 2,
    },
    colors: ['#468847'],
    title: {
       text: ""
    },
    subtitle: {
        text: null
    },
    credits: {
        enabled: true,
        style: {
            color: '#4F4F4F',
        },
        text: '',
    },
    xAxis:{
       categories:[],
       tickmarkPlacement: 'on',
       title: {
           enabled: false
       },
    },
    yAxis: {          
       title:{
          text:"Precipitação (mm)"
       },
       max: 0
    },
    tooltip: {
      //  headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
      //  pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}%</b> of total<br/>',
       valueSuffix:" (mm)",
       shared: true,
    },
    plotOptions: {
        area: {
            lineColor: '#666666',
            lineWidth: 1,
            marker: {
                enabled: false
            },
        },
    },
    series: [
       {
          name: 'Precipitação',
          data: []
       }
    ]
 };
  public updateFlag = false;

  constructor(private elementRef:ElementRef) {
  }

  ngOnInit() {
  }

  private draw() 
  {
    if (!this.config)
      return;
      
    let max = Math.ceil(this.dados[0].observado / 50) * 50;
    let title = '10 maiores chuvas ';
    let xLabels = [];
    let data = []

    this.dados.map(dado => {
      xLabels.push(dado.municipio);
      data.push(dado.observado);
    });

    if (this.config.periodo == 'dia')
      title = title + 'por posto do dia';
    else if (this.config.periodo == 'mes')
      title = title + 'acumuladas por posto do mês';
    else if (this.config.periodo == 'ano')
      title = title + 'acumuladas por posto do ano';

    this.title = title;

    this.chartOptions.title.text = title;
    this.chartOptions.yAxis.max = max;
    this.chartOptions.xAxis.categories = xLabels;
    this.chartOptions.series[0].data = data;

    this.updateFlag = true;

    
  }
}
