import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RootComponent } from './layout/root/root.component';
import { CalendarioDiaComponent } from './modules/calendario/calendario-dia/calendario-dia.component';
import { CalendarioDiarioComponent } from './modules/calendario/calendario-diario/calendario-diario.component';
import { CalendarioMensalComponent } from './modules/calendario/calendario-mensal/calendario-mensal.component';
import { CalendarioAnualComponent } from './modules/calendario/calendario-anual/calendario-anual.component';
import { CalendarioMesComponent } from './modules/calendario/calendario-mes/calendario-mes.component';
import { ErroComponent } from './pages/erro/erro.component';
import { CalendarioAnoComponent } from './modules/calendario/calendario-ano/calendario-ano.component';
import { CalendarioBimestreComponent } from './modules/calendario/calendario-bimestre/calendario-bimestre.component';
import { CalendarioBimestralComponent } from './modules/calendario/calendario-bimestral/calendario-bimestral.component';
import { CalendarioTrimestralComponent } from './modules/calendario/calendario-trimestral/calendario-trimestral.component';
import { CalendarioTrimestreComponent } from './modules/calendario/calendario-trimestre/calendario-trimestre.component';
import { CalendarioQuadrimestralComponent } from './modules/calendario/calendario-quadrimestral/calendario-quadrimestral.component';
import { CalendarioQuadrimestreComponent } from './modules/calendario/calendario-quadrimestre/calendario-quadrimestre.component';
import { CalendarioMiniComponent } from './modules/calendario/calendario-mini/calendario-mini.component';
import { PostosPluviometricosComponent } from './pages/postos-pluviometricos/postos-pluviometricos.component';
import { PostosPluviometricosIndexComponent } from './pages/postos-pluviometricos-index/postos-pluviometricos-index.component';
import { PluviometriaDadosComponent } from './pages/pluviometria-dados/pluviometria-dados.component';
import { CalendarioAppComponent } from './modules/calendario/calendario-app/calendario-app.component';
import { RepositorioChuvaComponent } from './pages/repositorio-chuva/repositorio-chuva.component';

const routes: Routes = [{ 
    path: 'cearaapp',
    component: CalendarioAppComponent, 
    pathMatch: 'full' 
  },
  {
    path: 'mini',
    component: CalendarioMiniComponent, 
    pathMatch: 'full' 
  },
  { 
    path: 'postos-index',
    component: PostosPluviometricosIndexComponent,
    pathMatch: 'full'
  },
  {
    path: '',
    component: RootComponent, 
    children:  [
      { 
        path: '', 
        redirectTo: 'diario/municipios/maxima', 
        pathMatch: 'full' 
      },

      { path: '404',                                            component: ErroComponent },

      { path: 'dia/:produto/:metrica',                          component: CalendarioDiaComponent },
      { path: 'dia/:produto/:metrica/:ano',                     component: CalendarioDiaComponent, data: { periodo: 'dia' } },
      { path: 'dia/:produto/:metrica/:ano/:mes',                component: CalendarioDiaComponent, data: { periodo: 'dia' } },
      { path: 'dia/:produto/:metrica/:ano/:mes/:dia',           component: CalendarioDiaComponent, data: { periodo: 'dia' } },

      { path: 'diario/:produto/:metrica',                       component: CalendarioDiarioComponent, data: { periodo: 'diario' } },
      { path: 'diario/:produto/:metrica/:ano',                  component: CalendarioDiarioComponent, data: { periodo: 'diario' } },
      { path: 'diario/:produto/:metrica/:ano/:mes',             component: CalendarioDiarioComponent, data: { periodo: 'diario' } },
      { path: 'diario/:produto/:metrica/:ano/:mes/:dia',        component: CalendarioDiarioComponent, data: { periodo: 'diario' } },

      { path: 'mensal/:produto/:metrica',                       component: CalendarioMensalComponent, data: { periodo: 'mensal' } },
      { path: 'mensal/:produto/:metrica/:ano',                  component: CalendarioMensalComponent, data: { periodo: 'mensal' } },
      { path: 'mensal/:produto/:metrica/:ano/:mes',             component: CalendarioMensalComponent, data: { periodo: 'mensal' } },
      { path: 'mensal/:produto/:metrica/:ano/:mes/:dia',        component: CalendarioMensalComponent, data: { periodo: 'mensal' } },

      { path: 'mes/:produto/:metrica',                          component: CalendarioMesComponent },
      { path: 'mes/:produto/:metrica/:ano',                     component: CalendarioMesComponent },
      { path: 'mes/:produto/:metrica/:ano/:mes',                component: CalendarioMesComponent },
      { path: 'mes/:produto/:metrica/:ano/:mes/:dia',           component: CalendarioMesComponent },

      { path: 'bimestral/:produto/:metrica',                    component: CalendarioBimestralComponent, data: { periodo: 'bimestral' } },
      { path: 'bimestral/:produto/:metrica/:ano',               component: CalendarioBimestralComponent, data: { periodo: 'bimestral' } },
      { path: 'bimestral/:produto/:metrica/:ano/:mes',          component: CalendarioBimestralComponent, data: { periodo: 'bimestral' } },
      { path: 'bimestral/:produto/:metrica/:ano/:mes/:dia',     component: CalendarioBimestralComponent, data: { periodo: 'bimestral' } },

      { path: 'bimestre/:produto/:metrica',                     component: CalendarioBimestreComponent, data: { periodo: 'bimestre' } },
      { path: 'bimestre/:produto/:metrica/:ano',                component: CalendarioBimestreComponent, data: { periodo: 'bimestre' } },
      { path: 'bimestre/:produto/:metrica/:ano/:mes',           component: CalendarioBimestreComponent, data: { periodo: 'bimestre' } },
      { path: 'bimestre/:produto/:metrica/:ano/:mes/:dia',      component: CalendarioBimestreComponent, data: { periodo: 'bimestre' } },
      
      { path: 'trimestral/:produto/:metrica',                   component: CalendarioTrimestralComponent, data: { periodo: 'trimestral' } },
      { path: 'trimestral/:produto/:metrica/:ano',              component: CalendarioTrimestralComponent, data: { periodo: 'trimestral' } },
      { path: 'trimestral/:produto/:metrica/:ano/:mes',         component: CalendarioTrimestralComponent, data: { periodo: 'trimestral' } },
      { path: 'trimestral/:produto/:metrica/:ano/:mes/:dia',    component: CalendarioTrimestralComponent, data: { periodo: 'trimestral' } },

      { path: 'trimestre/:produto/:metrica',                    component: CalendarioTrimestreComponent, data: { periodo: 'trimestre' } },
      { path: 'trimestre/:produto/:metrica/:ano',               component: CalendarioTrimestreComponent, data: { periodo: 'trimestre' } },
      { path: 'trimestre/:produto/:metrica/:ano/:mes',          component: CalendarioTrimestreComponent, data: { periodo: 'trimestre' } },
      { path: 'trimestre/:produto/:metrica/:ano/:mes/:dia',     component: CalendarioTrimestreComponent, data: { periodo: 'trimestre' } },

      { path: 'quadrimestral/:produto/:metrica',                component: CalendarioQuadrimestralComponent, data: { periodo: 'quadrimestral' } },
      { path: 'quadrimestral/:produto/:metrica/:ano',           component: CalendarioQuadrimestralComponent, data: { periodo: 'quadrimestral' } },
      { path: 'quadrimestral/:produto/:metrica/:ano/:mes',      component: CalendarioQuadrimestralComponent, data: { periodo: 'quadrimestral' } },
      { path: 'quadrimestral/:produto/:metrica/:ano/:mes/:dia', component: CalendarioQuadrimestralComponent, data: { periodo: 'quadrimestral' } },

      { path: 'quadrimestre/:produto/:metrica',                 component: CalendarioQuadrimestreComponent, data: { periodo: 'quadrimestre' } },
      { path: 'quadrimestre/:produto/:metrica/:ano',            component: CalendarioQuadrimestreComponent, data: { periodo: 'quadrimestre' } },
      { path: 'quadrimestre/:produto/:metrica/:ano/:mes',       component: CalendarioQuadrimestreComponent, data: { periodo: 'quadrimestre' } },
      { path: 'quadrimestre/:produto/:metrica/:ano/:mes/:dia',  component: CalendarioQuadrimestreComponent, data: { periodo: 'quadrimestre' } },

      { path: 'anual/:produto/:metrica',                        component: CalendarioAnualComponent, data: { periodo: 'anual' } },
      { path: 'anual/:produto/:metrica/:ano',                   component: CalendarioAnualComponent, data: { periodo: 'anual' } },
      { path: 'anual/:produto/:metrica/:ano/:mes',              component: CalendarioAnualComponent, data: { periodo: 'anual' } },
      { path: 'anual/:produto/:metrica/:ano/:mes/:dia',         component: CalendarioAnualComponent, data: { periodo: 'anual' } },

      { path: 'ano/:produto/:metrica',                          component: CalendarioAnoComponent, data: { periodo: 'ano' } },
      { path: 'ano/:produto/:metrica/:ano',                     component: CalendarioAnoComponent },
      { path: 'ano/:produto/:metrica/:ano/:mes',                component: CalendarioAnoComponent, data: { periodo: 'ano' } },
      { path: 'ano/:produto/:metrica/:ano/:mes/:dia',           component: CalendarioAnoComponent, data: { periodo: 'ano' } },
      { path: 'postos',                                         component: PostosPluviometricosComponent },
      { path: 'pluviometria-dados',                             component: PluviometriaDadosComponent },
      // { path: 'reconstructed-precipitation-ceara-silva-2020',   component: RepositorioChuvaComponent },
      { path: 'reconstructed-precipitation-ceara',   component: RepositorioChuvaComponent },
      { 
        path: '**',
        component: ErroComponent, 
        pathMatch: 'full' 
      }
    ],
    
  }];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
