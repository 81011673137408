import { Component, OnInit, OnDestroy } from '@angular/core';
import { ConfigFacade } from 'libs/core-app/src/lib/state/config/config.facade';
import { Config } from '@funceme/core-app';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-calendario-param-metrica',
  templateUrl: './calendario-param-metrica.component.html',
  styleUrls: ['./calendario-param-metrica.component.css']
})
export class CalendarioParamMetricaComponent implements OnInit, OnDestroy {

  public config: Config;
  private subscription: Subscription;

  private allMetricas = [
    {
      nome:"Média",
      value:"media",
      selected: false
    },
    {
      nome:"Máxima",
      value:"maxima",
      selected: false
    }
  ];

  public metricas = [];

  constructor(public configFacade: ConfigFacade) 
  { 
    this.subscription = this.configFacade.current$.subscribe(value => {

      this.config = value;

      this.allMetricas.map(item => {
        item.selected =  ((item.value == 'media' && this.config.metrica == 'media')
          || (item.value == 'maxima' && this.config.metrica == 'maxima'))
      });

      if (this.config.produto != 'municipios')
        this.metricas = this.allMetricas.filter(metrica => 'maxima'.indexOf(metrica.value));
      else   
        this.metricas = this.allMetricas;
        
      if (!this.metricas.some(metrica => metrica.value == this.config.metrica)) {
        this.config.metrica = 'media';

        this.configFacade.save(this.config);
      }
    });
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  changedMetrica(event) {
    this.config.metrica = event.value;
    this.configFacade.save(this.config);
  }
}
