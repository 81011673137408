import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'municipio-modal',
  templateUrl: './municipio-modal.component.html',
  styleUrls: ['./municipio-modal.component.css']
})
export class MunicipioModalComponent {

  constructor(
    public dialogRef: MatDialogRef<MunicipioModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {     
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  close(): void {
    this.dialogRef.close();
  }
}
