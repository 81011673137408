import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { PluviometriaNormalizadaFunceme } from '../../../state/pluviometria-normalizada-funceme/pluviometria-normalizada-funceme.model';
import { Pagination, getPaginationDefault } from '../../../generics/pagination.model';
import { TableSchema } from '../../../generics/lazy-grid/table.schema';
import { PluviometriaNormalizadaFuncemeFacade } from '../../../state/pluviometria-normalizada-funceme/pluviometria-normalizada-funceme.facade';
import { LazyGridTdTextComponent } from '../../../generics/lazy-grid/lazy-grid-td-text/lazy-grid-td-text.component';
import { PostoFacade } from '../../../state/posto/posto.facade';

@Component({
  selector: 'pluviometria-normalizada-funceme-lgrid',
  templateUrl: './pluviometria-normalizada-funceme-lgrid.component.html',
  styleUrls: ['./pluviometria-normalizada-funceme-lgrid.component.css']
})
export class PluviometriaNormalizadaFuncemeLgridComponent implements OnInit {

  private subscriptions = new Subscription();

  @Input() enableActions: boolean = true;
  @Input() enableFilters: boolean = false;  
  @Input() preSelectedPostoId: number = null;
  
  @Input() showColumns: string[] = [];
  @Input() showFilters: string[] = [];

  public pluviometria: PluviometriaNormalizadaFunceme[] = [];
  public localizar: string;
  public statusFilter: boolean = false;

  private pagination: Pagination = getPaginationDefault();

  private cleaning: boolean = false;

  public tableSchema: TableSchema;

  constructor(
    public facade: PluviometriaNormalizadaFuncemeFacade,
    private postoFacade: PostoFacade
  ) { 
    this.tableSchema = {    
      columns: [        
        {
          title: "Posto",
          field: 'nome',
          ordenable: true,
          render: LazyGridTdTextComponent,        
          getData: function(obj: PluviometriaNormalizadaFunceme) {
            return obj.posto.nome;
          }
        },
        {
          title: "Data",
          field: 'data',
          ordenable: true,
          render: LazyGridTdTextComponent,        
          getData: function(obj: PluviometriaNormalizadaFunceme) {
            return obj.data;
          }
        },
        {
          title: "Valor",
          field: 'valor',
          ordenable: true,
          render: LazyGridTdTextComponent,        
          getData: function(obj: PluviometriaNormalizadaFunceme) {
            return obj.valor;
          }
        }
      ],
      actions: []
    }
  }

  ngOnInit() 
  {
    this.subscriptions.add(
      this.facade.all$.subscribe(values => { 
        this.pluviometria = values;
        this.cleaning = false;
      })
    );
  
    this.load();
  }

  load() 
  {
    this.pagination = getPaginationDefault();    

    this.pagination.with_list = ['posto.nome'];
    this.pagination.paginator.order_by = 'data,desc';
    this.pagination.filter_list['posto'] = this.preSelectedPostoId;

    this.facade.paginate(this.pagination);
  }

  download()
  {
    this.facade.download(this.pagination);
  }

  cleanFilters()
  {
    this.cleaning = true;

    this.statusFilter = false;

    this.pagination.filter_list = [];
    this.pagination.paginator.page = 1;

    this.facade.paginate(this.pagination);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}