import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/nx';
import { map } from 'rxjs/operators';

import { Macrorregiao } from './macrorregiao.model';
import { MacrorregiaoService } from './macrorregiao.service';

import {
  LoadMacrorregiao, MacrorregiaoLoaded,
  MacrorregioesActionTypes,
  MacrorregiaoPaginated,
  PaginateMacrorregiao,
  MacrorregiaoRequestDone,
  DownloadMacrorregiao,
  MacrorregiaoRequestFailed
} from './macrorregiao.actions';
import { MacrorregiaoState } from './macrorregiao.reducer';
import { NotificationsService } from '../../notifications/notifications.service';
import { MacrorregiaoFacade } from './macrorregiao.facade';
import { ApiResponse, paginationEquals } from '../../generics/pagination.model';

@Injectable({providedIn: 'root'})
export class MacrorregiaoEffects 
{
  constructor(
    private actions$: Actions,
    private dataPersistence: DataPersistence<MacrorregiaoState>,
    private service: MacrorregiaoService,
    private facade: MacrorregiaoFacade,
    private notifyError: NotificationsService
  ) {}

  //@Effect() effect$ = this.actions$.ofType(MacrorregiaoActionTypes.MacrorregiaoAction);

  @Effect()
  loadMacrorregiao$ = this.dataPersistence.fetch(MacrorregioesActionTypes.LoadMacrorregiao, {
    run: (action: LoadMacrorregiao, state: any) => {

      if(Object.keys(state.macrorregiao.entities).length > 0){
        let allMacrorregiao;

        this.facade.all$.subscribe(macrorregiao => allMacrorregiao = macrorregiao )
        return new MacrorregiaoLoaded(allMacrorregiao);
      }

      return this.service
        .all()
        .pipe(
          map((res: Macrorregiao[]) => new MacrorregiaoLoaded(res['data']['list']))
        )
    },

    onError: (action: LoadMacrorregiao, error) => {
      this.notifyError.emit(error.statusText);
      return new MacrorregiaoRequestFailed;
    }
  });

  @Effect()
  paginateMacrorregiao$ = this.dataPersistence.fetch(MacrorregioesActionTypes.PaginateMacrorregiao, {
    run: (action: PaginateMacrorregiao, state: any) => {

      if (state.macrorregiao.last_pagination && paginationEquals(action.payload, JSON.parse(state.macrorregiao.last_pagination))) 
        return new MacrorregiaoRequestDone;

      return this.service
        .paginate(action.payload)
        .pipe(
          map((res: ApiResponse<Macrorregiao>) => new MacrorregiaoPaginated(res))
        )
    },

    onError: (action: PaginateMacrorregiao, error) => {
      this.notifyError.emit(error.statusText);
      return new MacrorregiaoRequestFailed;
    }
  });

  @Effect()
  downloadMacrorregiao$ = this.dataPersistence.fetch(MacrorregioesActionTypes.DownloadMacrorregiao, {
    run: (action: DownloadMacrorregiao, state: any) => {
      this.service.download(action.payload);
      return new MacrorregiaoRequestDone;
    },

    onError: (action: DownloadMacrorregiao, error) => {
      console.log(error);
      this.notifyError.emit(error.statusText);
    }
  });


}
