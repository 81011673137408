import { Action } from '@ngrx/store';
import { BaciaFunceme } from './bacia-funceme.model';
import { Pagination, ApiResponse } from '../../generics/pagination.model';

export enum BaciaFuncemeActionTypes {
  BaciasFuncemeAction = '[BaciasFunceme] Action',
  LoadBaciasFunceme = '[BaciasFunceme] Load Data',
  BaciasFuncemeLoaded = '[BaciasFunceme] Data Loaded',
  BaciaFuncemeSelected = '[BaciaFunceme] Selected',
  BaciasFuncemeSelected = '[BaciasFunceme] Selected',
  BaciasFuncemeUnselected = '[BaciasFunceme] Unselected',
  PaginateBaciasFunceme = '[BaciasFunceme] Paginate',
  BaciasFuncemePaginated = '[BaciasFunceme] Paginated',
  BaciasFuncemeRequestDone = '[BaciasFunceme] RequestDone',
  DownloadBaciasFunceme = '[BaciasFunceme] Download',
  BaciasFuncemeRequestFailed = '[BaciasFunceme] Request Failed',

}

export class BaciasFunceme implements Action {
  readonly type = BaciaFuncemeActionTypes.BaciasFuncemeAction;
}

export class LoadBaciasFunceme implements Action {
  readonly type = BaciaFuncemeActionTypes.LoadBaciasFunceme;
  constructor() { }
}

export class BaciasFuncemeLoaded implements Action {
  readonly type = BaciaFuncemeActionTypes.BaciasFuncemeLoaded;
  constructor(public payload: BaciaFunceme[]) { }
}

export class BaciaFuncemeSelected implements Action {
  readonly type = BaciaFuncemeActionTypes.BaciaFuncemeSelected;
  constructor(public payload: number) { }
}

export class BaciasFuncemeSelected implements Action {
  readonly type = BaciaFuncemeActionTypes.BaciasFuncemeSelected;
  constructor(public payload: number[]) { }
}

export class BaciasFuncemeUnselected implements Action {
  readonly type = BaciaFuncemeActionTypes.BaciasFuncemeUnselected;
  constructor(public payload: number[]) { }
}

export class PaginateBaciasFunceme implements Action {
  readonly type = BaciaFuncemeActionTypes.PaginateBaciasFunceme;
  constructor(public payload: Pagination) { }
}

export class BaciasFuncemePaginated implements Action {
  readonly type = BaciaFuncemeActionTypes.BaciasFuncemePaginated;
  constructor(public payload: ApiResponse<BaciaFunceme>) { }
}

export class BaciasFuncemeRequestDone implements Action {
  readonly type = BaciaFuncemeActionTypes.BaciasFuncemeRequestDone;
  constructor() { }
}

export class BaciasFuncemeRequestFailed implements Action {
  readonly type = BaciaFuncemeActionTypes.BaciasFuncemeRequestFailed;
  constructor() { }
}

export class DownloadBaciasFunceme implements Action {
  readonly type = BaciaFuncemeActionTypes.DownloadBaciasFunceme;
  constructor(public payload: Pagination) { }
}

export type BaciaFuncemeActions = BaciasFunceme
  | LoadBaciasFunceme
  | BaciasFuncemeLoaded  
  | BaciaFuncemeSelected
  | BaciasFuncemeSelected
  | BaciasFuncemeUnselected
  | PaginateBaciasFunceme
  | BaciasFuncemePaginated
  | BaciasFuncemeRequestDone
  | DownloadBaciasFunceme
  | BaciasFuncemeRequestFailed
;