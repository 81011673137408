import { Component, OnInit, OnDestroy } from '@angular/core';
import { Config, ConfigFacade } from '@funceme/core-app';
import { ActivatedRoute } from '@angular/router';
import { NavigatorService } from '../../../shared/services/navigator.service';
import { Subscription } from 'rxjs';
import { Refresh } from '@ngrx/store-devtools/src/actions';
import { Router } from '@angular/router';

@Component({
  selector: 'app-calendario-diario',
  templateUrl: './calendario-diario.component.html',
  styleUrls: ['./calendario-diario.component.css']
})
export class CalendarioDiarioComponent implements OnInit, OnDestroy {

  public loading: boolean = false;
  public iterationFlag = false;
  public hasPrevious: boolean = true;
  public hasNext: boolean = true;

  public config: Config;

  private subscription: Subscription;

  public mapas = [];
  public semanas: [];

  constructor ( 
    private route: ActivatedRoute,
    private configFacade: ConfigFacade,
    private navigator: NavigatorService,
    private router : Router
  ) { 
    
    this.subscription = this.configFacade.current$.subscribe(value => {
      this.config = value;
      this.hasPrevious = this.navigator.hasPreviousMonth();
      this.hasNext = this.navigator.hasNextMonth();
      if(this.iterationFlag && this.config.mes){
        this.make();
      }
      this.iterationFlag = true
    })
  }

  private async make(){

    this.disableButtons(true);
    let date = new Date(this.config.ano, this.config.mes-1, 1);

    let initialDate = new Date(date);

    if (date.getDay()) {
      initialDate.setDate(initialDate.getDate() - initialDate.getDay());
    }
    
    date = new Date(this.config.ano, this.config.mes, 0);

    let finalDate = new Date(date);
    
    if (date.getDay() != 6) {
      finalDate.setDate(finalDate.getDate() + (6 - date.getDay()))
    }
    
    let diff = Math.ceil(Math.abs(finalDate.getTime() - initialDate.getTime()) / (1000 * 3600 * 24))+1;
    
    this.mapas.length = 0;
    let semana = [];
    
    date = new Date(initialDate);

    let _metrica = (this.config.metrica == 'media')? 'thiessen' : 'chuva';

    let produtoNome = '';

    if (this.config.produto == 'reservatorios')
    {
      produtoNome = 'acudes';
    }
    else
    {
      produtoNome = this.config.produto;
     
      if(window.location.pathname.includes('/diario/municipios/maxima'))
      {
        produtoNome = 'municipios';
        _metrica = 'chuva';
      }
    }

    let currentDate = new Date();

    for (let i = 0; i < diff; i++)
    {
      if (date <= currentDate)
      {
        var path = 'https://cdn.funceme.br/calendario/chuvas/' + produtoNome + '/' + _metrica + '/anos/' + date.getFullYear() + '/' + (date.getMonth()+1) + '/' + date.getDate() + '/mapa.gif';
        const resp = await fetch(path);

        if (resp['status'] == 200){
          semana.push({
            label: date.getDate(),
            ativo: date.getMonth()+1 == this.config.mes,
            path: path,
            link: new Date(date),
            date: new Date(date)
          })
        } else {
          semana.push({
            label: date.getDate(),
            ativo: date.getMonth()+1 == this.config.mes,
            path: 'https://cdn.funceme.br/calendario/chuvas/mapa.gif',
            link: new Date(date),
            date: new Date(date)
          })
        }
      }
      else
      {
        semana.push({
          label: date.getDate(),
          ativo: date.getMonth()+1 == this.config.mes,
          path: 'https://cdn.funceme.br/calendario/chuvas/mapa.gif',
          link: new Date(date),
          date: new Date(date)
        })
      }

      date.setDate(date.getDate()+ 1);

      if (semana.length % 7 == 0) {
        this.mapas.push([...semana]);
        semana.length = 0;
      }
    }
    this.disableButtons(false);
  }

  ngOnInit() {
    this.navigator.parse('diario', this.route.snapshot);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  backward() {
    this.navigator.monthBackward();
  }

  forward() {
    this.navigator.monthForward();
  }

  disableButtons(value){
    if (value)
      this.hasPrevious = false;
    else
      this.hasPrevious = this.navigator.hasPreviousMonth();

    if (value)
      this.hasNext = false;
    else
      this.hasNext = this.navigator.hasNextMonth();
  }
}