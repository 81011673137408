import { Component, OnInit, Input } from '@angular/core';
import { SelectOneSchema } from '../../../generics/select-one/select-one.schema';
import { Pagination, getPaginationDefault } from '../../../generics/pagination.model';
import { RegiaoHidrograficaFacade } from '../../../state/regiao-hidrografica/regiao-hidrografica.facade';
import { RegiaoHidrografica } from '../../../state/regiao-hidrografica/regiao-hidrografica.model';

@Component({
  selector: 'regiao-hidrografica-select',
  templateUrl: './regiao-hidrografica-select.component.html',
  styleUrls: ['./regiao-hidrografica-select.component.css']
})
export class RegiaoHidrograficaSelectComponent implements OnInit {

  public schema: SelectOneSchema;
  private pagination: Pagination = getPaginationDefault();

  @Input() showItensWithIds: number[] = [];
  @Input() hideItensWithIds: number[] = [0];

  constructor(public facade: RegiaoHidrograficaFacade) { 
    this.pagination.paginator.limit = 999999;
  }

  ngOnInit() 
  {
    this.schema = {
      label: 'Região Hidrográfica',
      placeholder: 'Selecionar Região',
      facade: this.facade,
      include_list: this.showItensWithIds,
      exclude_list: this.hideItensWithIds,
      getId(item: RegiaoHidrografica) {
        return item.id;
      },
      getName(item: RegiaoHidrografica) {
        return item.nome
      }
    };   

    this.facade.paginate(this.pagination);
  }
}
