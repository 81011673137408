export enum ConfigProduto {
    MUNICIPIOS              = 'municipios',
    MACRORREGIOES           = 'macrorregioes',
    SUB_BACIAS              = 'sub-bacias',
    REGIOES_HIDROGRAFICAS   = 'regioes-hidrograficas',
    CEARA                   = 'ceara',
    RESERVATORIOS           = 'reservatorios'
}

export enum ConfigPeriodo {
    DIA             = 'dia',
    DIARIO          = 'diario',
    MENSAL          = 'mensal',
    MES             = 'mes',
    BIMESTRAL       = 'bimestral',
    BIMESTRE        = 'bimestre',
    TRIMESTRAL      = 'trimestral',
    TRIMESTRE       = 'trimestre',
    QUADRIMESTRAL   = 'quadrimestral',
    QUADRIMESTRE    = 'quadrimestre',
    ANUAL           = 'anual'
}

export enum ConfigMetrica {
    MEDIA       = 'media',
    MAXIMA      = 'maxima',
    INTERPOLADA = 'interpolada'
}

export interface Config {
    id: number;
    produto: string;
    metrica: string;
    periodo: string;
    ano: number;
    mes: number;
    dia: number
}

export const emptyConfig: Config = {
    id: 1,
    produto: ConfigProduto.MUNICIPIOS,
    metrica: ConfigMetrica.MAXIMA,
    periodo: ConfigPeriodo.DIARIO,
    ano: null,
    mes: null,
    dia: null
}