import { ActionReducerMap} from '@ngrx/store';
import * as fromUf from './uf/uf.reducer';
import * as fromConfig from './config/config.reducer';
import * as fromUser from './user/user.reducer';
import * as fromPosto from './posto/posto.reducer';
import * as fromOrgao from './orgao/orgao.reducer';
import * as fromMunicipio from './municipio/municipio.reducer';
import * as fromBaciaFunceme from './bacia-funceme/bacia-funceme.reducer';
import * as fromPluviometriaNormalizadaFunceme from './pluviometria-normalizada-funceme/pluviometria-normalizada-funceme.reducer';
import * as fromRegiaoHidrografica from './regiao-hidrografica/regiao-hidrografica.reducer';
import * as fromMacrorregiao from './macrorregiao/macrorregiao.reducer';

// Single Source of Truth
export interface AppState {
    uf: fromUf.UfState,
    config: fromConfig.ConfigState,
    user: fromUser.UserState,
    posto: fromPosto.PostoState,
    orgao: fromOrgao.OrgaoState,
    municipio: fromMunicipio.MunicipioState,
    baciaFunceme: fromBaciaFunceme.BaciaFuncemeState,
    pluviometriaNormalizadaFunceme: fromPluviometriaNormalizadaFunceme.PluviometriaNormalizadaFuncemeState,
    regiaoHidrografica: fromRegiaoHidrografica.RegiaoHidrograficaState,
    macrorregiao: fromMacrorregiao.MacrorregiaoState
} 

export const reducers: ActionReducerMap<AppState> = { 
    uf: fromUf.ufReducer,
    config: fromConfig.configReducer,
    user: fromUser.userReducer,
    posto: fromPosto.postoReducer,
    orgao: fromOrgao.orgaoReducer,
    municipio: fromMunicipio.municipioReducer,
    baciaFunceme: fromBaciaFunceme.baciafuncemeReducer,
    pluviometriaNormalizadaFunceme: fromPluviometriaNormalizadaFunceme.pluviometrianormalizadafuncemeReducer,
    regiaoHidrografica: fromRegiaoHidrografica.regiaoHidrograficaReducer,
    macrorregiao: fromMacrorregiao.macrorregiaoReducer
};