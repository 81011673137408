import { Action } from '@ngrx/store';
import { PluviometriaNormalizadaFunceme } from './pluviometria-normalizada-funceme.model';
import { Pagination, ApiResponse } from '../../generics/pagination.model';

export enum PluviometriaNormalizadaFuncemeActionTypes {
  PluviometriasNormalizadasFuncemeAction = '[PluviometriasNormalizadasFunceme] Action',
  LoadPluviometriasNormalizadasFunceme = '[PluviometriasNormalizadasFunceme] Load Data',
  PluviometriasNormalizadasFuncemeLoaded = '[PluviometriasNormalizadasFunceme] Data Loaded',
  PluviometriasNormalizadasFuncemeelected = '[PluviometriaNormalizadaFunceme] Selected',
  PluviometriasNormalizadasFuncemeSelected = '[PluviometriasNormalizadasFunceme] Selected',
  PluviometriasNormalizadasFuncemeUnselected = '[PluviometriasNormalizadasFunceme] Unselected',
  PaginatePluviometriasNormalizadasFunceme = '[PluviometriasNormalizadasFunceme] Paginate',
  PluviometriasNormalizadasFuncemePaginated = '[PluviometriasNormalizadasFunceme] Paginated',
  PluviometriasNormalizadasFuncemeRequestDone = '[PluviometriasNormalizadasFunceme] RequestDone',
  PluviometriasNormalizadasFuncemeRequestFailed = '[PluviometriasNormalizadasFunceme] Request Failed',
  DownloadPluviometriasNormalizadasFunceme = '[PluviometriasNormalizadasFunceme] Download',
}

export class PluviometriasNormalizadasFunceme implements Action {
  readonly type = PluviometriaNormalizadaFuncemeActionTypes.PluviometriasNormalizadasFuncemeAction;
}

export class LoadPluviometriasNormalizadasFunceme implements Action {
  readonly type = PluviometriaNormalizadaFuncemeActionTypes.LoadPluviometriasNormalizadasFunceme;
  constructor() { }
}

export class PluviometriasNormalizadasFuncemeLoaded implements Action {
  readonly type = PluviometriaNormalizadaFuncemeActionTypes.PluviometriasNormalizadasFuncemeLoaded;
  constructor(public payload: PluviometriaNormalizadaFunceme[]) { }
}

export class PluviometriasNormalizadasFuncemeelected implements Action {
  readonly type = PluviometriaNormalizadaFuncemeActionTypes.PluviometriasNormalizadasFuncemeelected;
  constructor(public payload: number) { }
}

export class PluviometriasNormalizadasFuncemeSelected implements Action {
  readonly type = PluviometriaNormalizadaFuncemeActionTypes.PluviometriasNormalizadasFuncemeSelected;
  constructor(public payload: number[]) { }
}

export class PluviometriasNormalizadasFuncemeUnselected implements Action {
  readonly type = PluviometriaNormalizadaFuncemeActionTypes.PluviometriasNormalizadasFuncemeUnselected;
  constructor(public payload: number[]) { }
}

export class PaginatePluviometriasNormalizadasFunceme implements Action {
  readonly type = PluviometriaNormalizadaFuncemeActionTypes.PaginatePluviometriasNormalizadasFunceme;
  constructor(public payload: Pagination) { }
}

export class PluviometriasNormalizadasFuncemePaginated implements Action {
  readonly type = PluviometriaNormalizadaFuncemeActionTypes.PluviometriasNormalizadasFuncemePaginated;
  constructor(public payload: ApiResponse<PluviometriaNormalizadaFunceme>) { }
}

export class PluviometriasNormalizadasFuncemeRequestDone implements Action {
  readonly type = PluviometriaNormalizadaFuncemeActionTypes.PluviometriasNormalizadasFuncemeRequestDone;
  constructor() { }
}

export class PluviometriasNormalizadasFuncemeRequestFailed implements Action {
  readonly type = PluviometriaNormalizadaFuncemeActionTypes.PluviometriasNormalizadasFuncemeRequestFailed;
  constructor() { }
}

export class DownloadPluviometriasNormalizadasFunceme implements Action {
  readonly type = PluviometriaNormalizadaFuncemeActionTypes.DownloadPluviometriasNormalizadasFunceme;
  constructor(public payload: Pagination) {
  }
}

export type PluviometriaNormalizadaFuncemeActions = PluviometriasNormalizadasFunceme
  | LoadPluviometriasNormalizadasFunceme
  | PluviometriasNormalizadasFuncemeLoaded  
  | PluviometriasNormalizadasFuncemeelected
  | PluviometriasNormalizadasFuncemeSelected
  | PluviometriasNormalizadasFuncemeUnselected
  | PaginatePluviometriasNormalizadasFunceme
  | PluviometriasNormalizadasFuncemePaginated
  | PluviometriasNormalizadasFuncemeRequestDone
  | PluviometriasNormalizadasFuncemeRequestFailed
  | DownloadPluviometriasNormalizadasFunceme
;