import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavigatorService } from '../../../shared/services/navigator.service';

@Component({
  selector: 'app-calendario-trimestre',
  templateUrl: './calendario-trimestre.component.html',
  styleUrls: ['./calendario-trimestre.component.css']
})
export class CalendarioTrimestreComponent implements OnInit {
  
  constructor(
    private navigator: NavigatorService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.navigator.parse('trimestre', this.route.snapshot);
  }
}
