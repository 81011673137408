import { Component, OnInit } from '@angular/core';
import { ConfigFacade, Config } from '@funceme/core-app';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-calendario-titulo',
  templateUrl: './calendario-titulo.component.html',
  styleUrls: ['./calendario-titulo.component.css']
})
export class CalendarioTituloComponent implements OnInit {

  private subscription: Subscription;

  public config: Config;
  public titulo: string = 'mês';
  public periodo: string;
  public produto: string;
  public dataInicial: string;
  public dataFinal: string = null;


  constructor(
    private configFacade: ConfigFacade
  ) {
  this.subscription = this.configFacade.current$.subscribe(value => { 

    if (this.config != value)
      this.setTitle(value);
    });
  }

  ngOnInit() {
  }

  setTitle(config: Config) {
    this.config = config;

    this.titulo = 'Chuva';

    if (this.config.metrica == 'media')
      this.titulo = this.titulo+' média';
    else if (this.config.metrica == 'maxima')
      this.titulo = this.titulo+' máxima';
    else
      this.titulo = this.titulo+' '+this.config.metrica;

    if (this.config.periodo == 'dia' || this.config.periodo == 'diario') {
      this.titulo = this.titulo+' diária ';
      this.dataInicial = (this.config.dia != null) ? 
        this.config.dia+'/'+this.config.mes+'/'+this.config.ano :
        this.getMes(this.config.mes-1)+'/'+this.config.ano

    } else if (this.config.periodo == 'mensal' ||
              this.config.periodo == 'bimestral' ||
              this.config.periodo == 'trimestral' ||
              this.config.periodo == 'quadrimestral'){
      this.titulo = this.titulo+' '+this.config.periodo
      this.dataInicial = this.config.ano+'';

    } else if (this.config.periodo == 'mes'){
      this.titulo = this.titulo+' do mês'
      this.dataInicial = this.getMes(this.config.mes-1)+'/'+this.config.ano;

    } else if (this.config.periodo == 'bimestre'){
      this.titulo = this.titulo+' do '+this.config.periodo
      this.dataInicial = this.getMes(this.config.mes-1)+'/'+this.config.ano;
      this.dataFinal = this.getMes((this.config.mes) % 12)+'/'+((this.config.mes + 1) % 13 < this.config.mes ? this.config.ano+1 : this.config.ano); 
    
    } else if (this.config.periodo == 'trimestre'){
      this.titulo = this.titulo+' do '+this.config.periodo
      this.dataInicial = this.getMes(this.config.mes-1)+'/'+this.config.ano;
      this.dataFinal = this.getMes((this.config.mes+1) % 12)+'/'+((this.config.mes + 2) % 13 < this.config.mes ? this.config.ano+1 : this.config.ano);
    
    } else if (this.config.periodo == 'quadrimestre'){
      this.titulo = this.titulo+' do '+this.config.periodo
      this.dataInicial = this.getMes(this.config.mes-1)+'/'+this.config.ano;
      this.dataFinal = this.getMes((this.config.mes+2) % 12)+'/'+((this.config.mes + 3) % 13 < this.config.mes ? this.config.ano+1 : this.config.ano);

    } else if (this.config.periodo == 'anual'){
      this.titulo = this.titulo+' '+this.config.periodo
      let year = this.config.ano;
      let decade = (year % 10) ? Math.floor((year) / 10) * 10 + 10: Math.floor((year-1) / 10) * 10 + 10;
      this.dataInicial = (decade-9)+' a '+decade

    } else if (this.config.periodo == 'ano'){
      this.titulo = this.titulo+' do '+this.config.periodo
      this.dataInicial = this.config.ano+'';

    }

    if (this.config.produto == 'municipios')
      this.produto = ' por município - '

    else if (this.config.produto == 'macrorregioes')
      this.produto = ' por macrorregião - '

    else if (this.config.produto == 'bacias')
      this.produto = ' por sub-bacia - '

    else if (this.config.produto == 'regioes')
      this.produto = ' por região hidrográfica - '

    else
      this.produto = ' do Estado - '

    this.titulo = this.titulo+this.produto+this.dataInicial

    if (this.dataFinal)
      this.titulo = this.titulo+ ' a '+this.dataFinal
  }

  private getMes(key)
  {
    switch (key) {
      case 0:
        return 'Jan';
      case 1:
        return 'Fev';
      case 2:
        return 'Mar';
      case 3:
        return 'Abr';
      case 4:
        return 'Mai';
      case 5:
        return 'Jun';
      case 6:
        return 'Jul';
      case 7:
        return 'Ago';
      case 8:
        return 'Set';
      case 9:
        return 'Out';
      case 10:
        return 'Nov';
      case 11:
        return 'Dez';
    }
  }

}
