import { Injectable } from '@angular/core';
import { ActionsSubject, select, Store } from '@ngrx/store';
import * as MacrorregiaoActions from './macrorregiao.actions';
import { MacrorregiaoState } from './macrorregiao.reducer';
import { selectAllMacrorregiao, selectCurrentMacrorregiao, selectCurrentMacrorregioes, selectMetaDataMacrorregiao, selectTotalMacrorregiao, selectLoadingMacrorregiao } from './macrorregiao.selectors';
import { Pagination } from '../../generics/pagination.model';
import { GenericFacade } from '../../generics/generic-facade';

@Injectable({ providedIn: 'root' })
export class MacrorregiaoFacade implements GenericFacade
{
  all$ = this.store.pipe(select(selectAllMacrorregiao));
  current$ = this.store.pipe(select(selectCurrentMacrorregiao));
  currentList$ = this.store.pipe(select(selectCurrentMacrorregioes));
  metaData$ = this.store.pipe(select(selectMetaDataMacrorregiao));
  total$ = this.store.pipe(select(selectTotalMacrorregiao));
  loading$ = this.store.pipe(select(selectLoadingMacrorregiao));

  constructor(private store: Store<MacrorregiaoState>, private actions$: ActionsSubject) {}

  selectOne(macrorregiaoId: number) {
    this.store.dispatch(new MacrorregiaoActions.MacrorregiaoSelected(macrorregiaoId));
  }

  selectMany(regioesHidrograficasIds: number[]) {
    this.store.dispatch(new MacrorregiaoActions.MacrorregioesSelected(regioesHidrograficasIds));
  }

  unselectMany(regioesHidrograficasIds: number[]) {
    this.store.dispatch(new MacrorregiaoActions.MacrorregiaoUnselected(regioesHidrograficasIds));
  }

  load(reset: boolean) {
    this.store.dispatch(new MacrorregiaoActions.LoadMacrorregiao());
  }

  paginate(pagination: Pagination) {
    this.store.dispatch(new MacrorregiaoActions.PaginateMacrorregiao(pagination));
  }

  download(pagination: Pagination) {
    this.store.dispatch(new MacrorregiaoActions.DownloadMacrorregiao(pagination));
  }

  select(id: number) {
    this.selectOne(id);
  }

  pick(id: number) {}
  destroy(id: number) {};
  save(model: any) {};
  index() {};
  edit(id: number) {};
  show(id: number) {};
  create(): void {};
  refresh(): void {};
}
