import * as fromMacrorregiao from './macrorregiao.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Macrorregiao, emptyMacrorregiao } from './macrorregiao.model';

export const selectMacrorregiaoState = createFeatureSelector<fromMacrorregiao.MacrorregiaoState>('macrorregiao');

export const selectMacrorregiaoIds = createSelector(
  selectMacrorregiaoState,
  fromMacrorregiao.selectMacrorregiaoIds
);

export const selectMacrorregiaoEntities = createSelector(
  selectMacrorregiaoState,
  fromMacrorregiao.selectMacrorregiaoEntities
);

export const selectAllMacrorregiao = createSelector(
  selectMacrorregiaoState,
  fromMacrorregiao.selectAllMacrorregiao
);

export const selectMetaDataMacrorregiao = createSelector(
  selectMacrorregiaoState,
  fromMacrorregiao.getMetaDataMacrorregiao
);

export const selectTotalMacrorregiao = createSelector(
  selectMacrorregiaoState,
  fromMacrorregiao.getTotalMacrorregiao
); 

export const selectCurrentMacrorregiaoId = createSelector(
  selectMacrorregiaoState,
  fromMacrorregiao.getSelectedMacrorregiaoId
);

export const selectLoadingMacrorregiao = createSelector(
  selectMacrorregiaoState,
  fromMacrorregiao.getLoadingMacrorregiao
); 

export const selectCurrentMacrorregiao = createSelector(
  selectMacrorregiaoEntities,
  selectCurrentMacrorregiaoId,
  (MacrorregiaoEntities, MacrorregiaoId) => {
    return MacrorregiaoId ? MacrorregiaoEntities[MacrorregiaoId] : emptyMacrorregiao;
  }
);

export const selectCurrentMacrorregiaoIds = createSelector(
  selectMacrorregiaoState,
  fromMacrorregiao.getSelectedMacrorregiaoIds
);

export const selectCurrentMacrorregioes = createSelector(
  selectMacrorregiaoEntities,
  selectCurrentMacrorregiaoIds,
  (MacrorregiaoEntities, MacrorregiaoIds) => {

    let list: Macrorregiao[] = [];

    MacrorregiaoIds.forEach(element => {
      list.push(MacrorregiaoEntities[element]);
    });

    return list;
  }
);