import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/nx';
import { map } from 'rxjs/operators';

import { Posto } from './posto.model';
import { PostoService } from './posto.service';

import {
  LoadPostos, PostosLoaded,
  PostoActionTypes,
  PostosPaginated,
  PaginatePostos,
  PostosRequestDone,
  PostosRequestFailed
} from './posto.actions';
import { PostoState } from './posto.reducer';
import { NotificationsService } from '../../notifications/notifications.service';
import { PostoFacade } from './posto.facade';
import { ApiResponse, paginationEquals } from '../../generics/pagination.model';

@Injectable({providedIn: 'root'})
export class PostoEffects 
{
  //@Effect() effect$ = this.actions$.ofType(PostosActionTypes.PostosAction);

  @Effect()
  loadPostos$ = this.dataPersistence.fetch(PostoActionTypes.LoadPostos, {
    run: (action: LoadPostos, state: any) => {

      if(Object.keys(state.posto.entities).length > 0){
        let allPostos;

        this.postoFacade.all$.subscribe(postos => allPostos = postos )
        return new PostosLoaded(allPostos);
      }

      return this.postoservice
        .all()
        .pipe(
          map((res: Posto[]) => new PostosLoaded(res['data']['list']))
        )
    },

    onError: (action: LoadPostos, error) => {
      this.notifyError.emit(error.statusText);
      return new PostosRequestFailed;
    }
  });

  @Effect()
  paginatePostos$ = this.dataPersistence.fetch(PostoActionTypes.PaginatePostos, {
    run: (action: PaginatePostos, state: any) => {

      if (state.posto.last_pagination && paginationEquals(action.payload, JSON.parse(state.posto.last_pagination))) 
        return new PostosRequestDone;

      return this.postoservice
        .paginate(action.payload)
        .pipe(
          map((res: ApiResponse<Posto>) => new PostosPaginated(res))
        )
    },

    onError: (action: PaginatePostos, error) => {
      this.notifyError.emit(error.statusText);
      return new PostosRequestFailed;
    }
  });

  constructor(
    private actions$: Actions,
    private dataPersistence: DataPersistence<PostoState>,
    private postoservice: PostoService,
    private postoFacade: PostoFacade,
    private notifyError: NotificationsService
  ) {}
}
