import { Injectable } from '@angular/core';
import { ActionsSubject, select, Store } from '@ngrx/store';
import * as BaciaFuncemeActions from './bacia-funceme.actions';
import { BaciaFuncemeState } from './bacia-funceme.reducer';
import { selectAllBaciasFunceme, selectCurrentBaciasFunceme, selectCurrentBaciaFunceme, selectMetaDataBaciasFunceme, selectTotalBaciasFunceme, selectLoadingBaciasFunceme } from './bacia-funceme.selectors';
import { Pagination } from '../../generics/pagination.model';
import { GenericFacade } from '../../generics/generic-facade';

@Injectable({ providedIn: 'root' })
export class BaciaFuncemeFacade implements GenericFacade
{
  all$ = this.store.pipe(select(selectAllBaciasFunceme));
  current$ = this.store.pipe(select(selectCurrentBaciaFunceme));
  currentList$ = this.store.pipe(select(selectCurrentBaciasFunceme));
  metaData$ = this.store.pipe(select(selectMetaDataBaciasFunceme));
  total$ = this.store.pipe(select(selectTotalBaciasFunceme));
  loading$ = this.store.pipe(select(selectLoadingBaciasFunceme));

  constructor(private store: Store<BaciaFuncemeState>, private actions$: ActionsSubject) {}

  selectOne(baciafuncemeId: number) {
    this.store.dispatch(new BaciaFuncemeActions.BaciaFuncemeSelected(baciafuncemeId));
  }

  selectMany(baciafuncemeIds: number[]) {
    this.store.dispatch(new BaciaFuncemeActions.BaciasFuncemeSelected(baciafuncemeIds));
  }

  unselectMany(baciafuncemeIds: number[]) {
    this.store.dispatch(new BaciaFuncemeActions.BaciasFuncemeUnselected(baciafuncemeIds));
  }

  load(reset: boolean) {
    this.store.dispatch(new BaciaFuncemeActions.LoadBaciasFunceme());
  }

  paginate(pagination: Pagination) {
    this.store.dispatch(new BaciaFuncemeActions.PaginateBaciasFunceme(pagination));
  }

  select(id: number) {
    this.selectOne(id);
  }

  pick(id: number) {}
  destroy(id: number) {};
  save(model: any) {};
  index() {};
  edit(id: number) {};
  show(id: number) {};
  create(): void {};
  refresh(): void {};
}
