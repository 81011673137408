import { Injectable } from '@angular/core';
import { ActionsSubject, select, Store } from '@ngrx/store';
import * as PostoActions from './posto.actions';
import { PostoState } from './posto.reducer';
import { selectAllPostos, selectCurrentPostos, selectCurrentPosto, selectMetaDataPostos, selectTotalPostos, selectLoadingPostos } from './posto.selectors';
import { Pagination } from '../../generics/pagination.model';
import { GenericFacade } from '../../generics/generic-facade';

@Injectable({ providedIn: 'root' })
export class PostoFacade implements GenericFacade
{
  all$ = this.store.pipe(select(selectAllPostos));
  current$ = this.store.pipe(select(selectCurrentPosto));
  currentList$ = this.store.pipe(select(selectCurrentPostos));
  metaData$ = this.store.pipe(select(selectMetaDataPostos));
  total$ = this.store.pipe(select(selectTotalPostos));
  loading$ = this.store.pipe(select(selectLoadingPostos));

  constructor(private store: Store<PostoState>, private actions$: ActionsSubject) {}

  selectOne(postoId: number) {
    this.store.dispatch(new PostoActions.PostoSelected(postoId));
  }

  selectMany(postoIds: number[]) {
    this.store.dispatch(new PostoActions.PostosSelected(postoIds));
  }

  unselectMany(postoIds: number[]) {
    this.store.dispatch(new PostoActions.PostosUnselected(postoIds));
  }

  load(reset: boolean) {
    this.store.dispatch(new PostoActions.LoadPostos());
  }

  paginate(pagination: Pagination) {  
    this.store.dispatch(new PostoActions.PaginatePostos(pagination));
  }

  select(id: number) {
    this.selectOne(id);
  }

  pick(id: number) {}
  destroy(id: number) {};
  save(model: any) {};
  index() {};
  edit(id: number) {};
  show(id: number) {};
  create(): void {};
  refresh(): void {};
}
