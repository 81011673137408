import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { ConfigFacade, Config, emptyConfig } from '@funceme/core-app';
import { Subscription } from 'rxjs';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class NavigatorService {

  private config: Config;
  private parsed: boolean = false;

  constructor(
    private configFacade: ConfigFacade,
    private location: Location,
    private router: Router)
  {
    this.configFacade.current$.subscribe(value => {

      if (!value) {
        const today = new Date;

        emptyConfig.ano = today.getFullYear();
        emptyConfig.mes = today.getMonth()+1;
        emptyConfig.dia = today.getDate();

        this.configFacade.save(emptyConfig);
      } else {
        this.config = value;
        if (this.parsed)
          this.syncRoute();
      }
    });
  }

  public parse(periodo: string, route: ActivatedRouteSnapshot)
  {
    if (periodo == "postos"){
      this.parsed = true;

      let _config: Config = emptyConfig;
      _config.produto = periodo;

      this.configFacade.save(_config);
      return;
    }

    if (periodo == "repositorio-chuva"){
      this.parsed = true;
      return;
    }

    if (!route.params)
      return;

    let _config: Config = emptyConfig;

    _config.periodo = periodo;
    _config.produto = route.params.produto;
    _config.metrica = route.params.metrica;
    _config.ano = Number(route.params.ano);
    _config.mes = Number(route.params.mes);
    _config.dia = Number(route.params.dia);

    let now = new Date();

    if (!_config.ano)
      _config.ano = now.getFullYear();

    if ('municipios-macrorregioes-bacias-regioes-ceara-reservatorios'.indexOf(_config.produto) == -1)
      _config.produto = 'municipios';

    if (_config.ano > now.getFullYear())
      _config.ano = now.getFullYear();

    if (_config.mes && _config.ano == now.getFullYear() && _config.mes > now.getMonth()+1)
      _config.mes = now.getMonth()+1;

    if (_config.dia && _config.ano == now.getFullYear() && _config.mes == now.getMonth()+1 && _config.dia > now.getDate())
      _config.dia = now.getDate();

    if (_config.periodo == 'diario'
      || _config.periodo == 'dia'
      || _config.periodo == 'mes'
      || _config.periodo == 'bimestre'
      || _config.periodo == 'trimestre'
      || _config.periodo == 'quadrimestre'
    ) {
      if(!_config.mes)
        _config.mes = now.getMonth() + 1;
    } else
      _config.mes = null

    if (_config.periodo == 'dia') {
      if (!_config.dia)
        _config.dia = now.getDate();
    }
    else
      _config.dia = null;

    this.parsed = true;

    this.configFacade.save(_config);
  }

  public hasPreviousDay()
  {
    if (this.config.ano > 1973)
      return true;
    if (this.config.mes > 1)
      return true;
    return this.config.dia > 1;
  }

  public dayBackward()
  {
    if (!this.hasPreviousDay())
      return;

    let date = new Date(this.config.ano,this.config.mes-1,this.config.dia);

    date.setDate(date.getDate()-1);

    this.config.dia = date.getDate();
    this.config.mes = date.getMonth()+1;
    this.config.ano = date.getFullYear();

    this.configFacade.save(this.config);
  }

  public hasNextDay()
  {
    let date = new Date();

    if (this.config.ano < date.getFullYear())
      return true;
    if (this.config.mes < date.getMonth()+1)
      return true;
    return this.config.dia < date.getDate();
  }

  public dayForward()
  {
    if (!this.hasNextDay()) return;

    let date = new Date();

    date = new Date(this.config.ano,this.config.mes-1,this.config.dia);

    date.setDate(date.getDate() + 1);

    this.config.dia = date.getDate();
    this.config.mes = date.getMonth()+1;
    this.config.ano = date.getFullYear();

    this.configFacade.save(this.config);
  }

  public hasPreviousMonth()
  {
    if (this.config.ano > 1973)
      return true;
    return this.config.mes > 1;
  }

  public monthBackward()
  {
    if (!this.hasPreviousMonth()) return;

    let mes = this.config.mes - 1;
    let ano = this.config.ano;

    if (mes == 0) {
      mes = 12;
      ano = ano - 1;
    }

    this.config.mes = mes;
    this.config.ano = ano;

    this.configFacade.save(this.config);
  }

  public hasNextMonth()
  {
    let date = new Date();
    return (!(this.config.ano == date.getFullYear() && this.config.mes == date.getMonth()+1));
  }

  public monthForward()
  {
    if (!this.hasNextMonth()) return;

    let mes = this.config.mes + 1;
    let ano = this.config.ano;

    if (mes == 13) {
      mes = 1;
      ano = ano + 1;
    }
    this.config.ano = ano;
    this.config.mes = mes;

    this.configFacade.save(this.config);
  }

  public hasPreviousYear()
  {
    return this.config.ano > 1973;
  }

  public yearBackward()
  {
    if (!this.hasPreviousYear()) return;

    this.config.ano = this.config.ano - 1;

    this.configFacade.save(this.config);
  }

  public hasNextYear()
  {
    let date = new Date();
    return this.config.ano < date.getFullYear();
  }

  public yearForward()
  {
    if (!this.hasNextYear()) return;

    this.config.ano = this.config.ano + 1;

    this.configFacade.save(this.config);
  }

  public decadeBackward()
  {
    if (this.config.ano == 1980 || Math.floor(this.config.ano / 10) == 197)
      return;

    this.config.ano = this.config.ano - 10;

    this.configFacade.save(this.config);
  }

  public decadeForward()
  {
    let date = new Date();

    if (this.config.ano + 10 > date.getFullYear())
      return;

    this.config.ano = this.config.ano + 10;

    this.configFacade.save(this.config);
  }

  private syncRoute()
  {
    if (this.config.produto == "postos"){
      this.router.navigate(['/postos']);
      return;
    }
    if ((this.config.produto != 'macrorregioes' && this.config.produto != 'ceara' && this.config.produto != 'regioes' && this.config.produto != 'municipios' && this.config.produto != 'bacias') || this.config.metrica == 'maxima'
      && (this.config.periodo == 'bimestral'
          || this.config.periodo == 'bimestre'
          || this.config.periodo == 'trimestral'
          || this.config.periodo == 'trimestre'
          || this.config.periodo == 'quadrimestral'
          || this.config.periodo == 'quadrimestre'
        )
    ) {
      this.config.periodo = 'mensal';
    }

    let route = this.config.periodo + '/' + this.config.produto + '/' + this.config.metrica + '/' + this.config.ano;

    if (this.config.mes)
      route += '/' + this.config.mes;

    if (this.config.dia)
      route += '/' + this.config.dia;

    const _periodo = this.router.url.split('/')[1];
    const _produto = this.router.url.split('/')[2];

    if (_periodo == this.config.periodo && _produto == this.config.produto)
      this.location.replaceState(route);
    else
      this.router.navigate([route]);
  }

  up()
  {
    switch(this.config.periodo) {
      case 'dia':
        this.config.periodo = 'diario';
        break;
      case 'mes':
        this.config.periodo = 'mensal';
        break;
      case 'bimestre':
        this.config.periodo = 'bimestral';
        break;
      case 'trimestre':
        this.config.periodo = 'trimestral';
        break;
      case 'quadrimestre':
        this.config.periodo = 'quadrimestral';
        break;
      case 'ano':
        this.config.periodo = 'anual';
        break;
    }

    this.configFacade.save(this.config);
  }
}
