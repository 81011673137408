import { Component, OnInit, OnDestroy } from '@angular/core';
import { Config, ConfigFacade } from '@funceme/core-app';
import { ActivatedRoute } from '@angular/router';
import { NavigatorService } from '../../../shared/services/navigator.service';
import { Mapa } from '../../../shared/models/mapa.model';
import { CalendarioService } from '../../../shared/services/calendario.service';
import { CalendarioItem } from '../../../shared/models/calendario.model';
import { Subscription } from 'rxjs';
import { Location } from '@angular/common';

@Component({
  selector: 'app-calendario-ano',
  templateUrl: './calendario-ano.component.html',
  styleUrls: ['./calendario-ano.component.css']
})
export class CalendarioAnoComponent implements OnInit, OnDestroy {

  public loading: boolean = false;
  public mapa: Mapa;
  public dados: CalendarioItem[] = [];
  public destaques: CalendarioItem[] = [];
  public hasPrevious: boolean = true;
  public hasNext: boolean = true;

  public routeParams;

  public config: Config;

  public data_atualizacao:string = '';

  private subscription: Subscription;

  constructor( 
    private route: ActivatedRoute,
    private navigator: NavigatorService,
    private configFacade: ConfigFacade,
    private calendarioFacade: CalendarioService,
    private location: Location
  ) { 
    this.subscription = this.configFacade.current$.subscribe(value => { 

      this.routeParams = this.location.path().split('/');
      
      this.hasPrevious = this.navigator.hasPreviousYear();
      this.hasNext = this.navigator.hasNextYear();

      if (this.config != value && value.ano && !value.mes && !value.dia)
        this.make(value);
    });
  }

  ngOnInit() {
    this.navigator.parse('ano', this.route.snapshot);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  backward() {
    this.navigator.yearBackward();
  }

  forward() {
    this.navigator.yearForward();
  }

  disableButtons(value){
    if (value)
      this.hasPrevious = false;
    else
      this.hasPrevious = this.navigator.hasPreviousYear();

    if (value)
      this.hasNext = false;
    else
      this.hasNext = this.navigator.hasNextYear();
  }

  up() {
    this.navigator.up();
  }

  private make(config: Config) 
  {
    this.disableButtons(true);
    this.config = config;
    
    if (this.config && this.config.ano && !this.checkIfEqual(this.config, this.routeParams))
    {
        return;
    }

    this.dados = [];
    this.data_atualizacao = '';

    let _metrica = (this.config.metrica == 'media')? 'thiessen' : 'chuva';

    let produtoNome = '';

    if (this.config.produto == 'reservatorios')
    {
      produtoNome = 'acudes';
    }
    else
    {
      produtoNome = this.config.produto;
    }

    this.mapa = {
      ativo: true,
      label: null,
      link: null,
      path: 'https://cdn.funceme.br/calendario/chuvas/' 
        + produtoNome + '/'
            + _metrica + '/anos/' 
        + this.config.ano + '/mapa.gif',
      date: new Date(this.config.ano,0,1),
    }

    this.loading = true;

    this.calendarioFacade.get(this.config).subscribe(response => {
      this.dados = response.data.list;
      this.data_atualizacao = response.data.data_processamento;
      this.loading = false;

      this.destaques = this.dados.sort(
        (a,b) => {
          if (this.config.metrica == 'maxima')
            return b.observado - a.observado
          else 
            return b.media - a.media
        }).slice(0,10);
    }, (error) => {
      this.loading = false;
    });

    this.disableButtons(false);
  }
  
  checkIfEqual(config, params) {
      return (
        // config.produto == params[2] &&
        config.metrica == params[3] &&
        config.ano == params[4]
      )
  }
}
