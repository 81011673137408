import { Component, OnInit } from '@angular/core';
import { Mapa } from '../../../shared/models/mapa.model';
import { CalendarioItem } from '../../../shared/models/calendario.model';
import { Config, ConfigFacade } from '@funceme/core-app';
import { Subscription } from 'rxjs';
import { NavigatorService } from '../../../shared/services/navigator.service';
import { CalendarioService } from '../../../shared/services/calendario.service';

@Component({
  selector: 'app-calendario-mini',
  templateUrl: './calendario-mini.component.html',
  styleUrls: ['./calendario-mini.component.css']
})
export class CalendarioMiniComponent implements OnInit {

  public loading: boolean = false;
  public popup: boolean = false;

  public mapa: Mapa;
  public hasPrevious: boolean = true;
  public hasNext: boolean = true;

  public data_mapa:string = '';
  public data_atualizacao:string = '';

  public dados: CalendarioItem[] = [];
  public destaques: CalendarioItem[] = [];

  public config: Config;

  private subscription: Subscription;

  constructor( 
    private navigator: NavigatorService,
    private configFacade: ConfigFacade,
    private calendarioFacade: CalendarioService
  ) { 
    this.subscription = this.configFacade.current$.subscribe(value => {

      this.hasPrevious = this.navigator.hasPreviousDay();
      this.hasNext = this.navigator.hasNextDay();

      if (this.config != value)
        this.make(value);
    });    
  }

  ngOnInit() {
    
  }

  info() {
    this.popup = !this.popup
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  backward() {
    this.navigator.dayBackward();
  }

  forward() {
    this.navigator.dayForward();
  }

  disableButtons(value){
    if (value)
      this.hasPrevious = false;
    else
      this.hasPrevious = this.navigator.hasPreviousDay();

    if (value)
      this.hasNext = false;
    else
      this.hasNext = this.navigator.hasNextDay();
  }

  up() {
    this.navigator.up();
  }

  private make(config: Config) 
  {
    this.disableButtons(true);
    let today = new Date();

    if (this.config)
      this.config = config;
    else {
      this.config = config;
      this.config.dia = today.getDate();
      this.config.mes = today.getMonth()+1;
      this.config.ano = today.getFullYear();
      this.config.periodo = 'dia';
      this.config.produto = 'municipios';
      this.config.metrica = 'maxima';
    }
    
    this.data_mapa = this.config.dia+"/"+this.config.mes+"/"+this.config.ano;
    this.dados = [];
    this.data_atualizacao = '';

    let _metrica = (this.config.metrica == 'media')? 'thiessen' : 'chuva';

    let produtoNome = '';

    if (this.config.produto == 'reservatorios')
    {
      produtoNome = 'acudes';
    }
    else
    {
      produtoNome = this.config.produto;
    }


    this.mapa = {
      ativo: true,
      label: null,
      link: null,
      path: 'https://cdn.funceme.br/calendario/chuvas/' 
        + produtoNome + '/'
        + _metrica + '/anos/' 
        + this.config.ano + '/' 
        + this.config.mes + '/'
        + this.config.dia + '/mapa.gif',
      date: new Date(this.config.ano,this.config.mes-1,this.config.dia),
    }

    this.loading = true;

    this.calendarioFacade.get(this.config).subscribe(response => {
      this.dados = response.data.list;
      this.data_atualizacao = response.data.data_processamento;
      this.loading = false;

      this.destaques = this.dados.sort(
        (a,b) => {
          if (this.config.metrica == 'maxima')
            return b.observado - a.observado
          else 
            return b.media - a.media
        }).slice(0,10);
    }, (error) => {
      this.loading = false;
    });
    this.disableButtons(false);
  }

}
