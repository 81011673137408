import { Component, OnInit, Input } from '@angular/core';
import * as Highcharts from 'highcharts';


@Component({
  selector: 'app-calendario-grafico-quantis',
  templateUrl: './calendario-grafico-quantis.component.html',
  styleUrls: ['./calendario-grafico-quantis.component.css']
})
export class CalendarioGraficoQuantisComponent implements OnInit {

  private dados;
  public total: Number;

  @Input()
  set setDados(value){
    this.dados = value;
    if (value) this.draw();
  }

  public highcharts = Highcharts;
  public chartOptions = {   
    chart: {
      type: 'bar'
    },
    credits: {
      enabled: false
    },
    title: {
        text: ''
    },
    xAxis: {
        categories: ['']
    },
    yAxis: {
        min: 0,
        max: 0,
        endOnTick: false,
        title: {
            text: ''
        },
        visible: false
    },
    legend: {
        reversed: true,
        enabled: false
    },
    plotOptions: {
      bar: {
        pointWidth: 20,
        groupPadding: 0.5,
        dataLabels: {
          enabled: true,
          formatter: function(e) {
            if (e.align != 'right')
              return this.y.toFixed(1);
          }
        },
        enableMouseTracking: false
      },
      series: {
        tooltip: {
          headerFormat: '',
          pointFormat: 'Observado: <b>{point.y}</b><br/>' 
        }
      }
    },
    series: [{
        name: 'Acima da media',
        data: [],
        color: '#0064b4',
        dataGrouping: {
          enabled: false
        },
        dataLabels: {
          align: 'right'
        }
    }, {
        name: 'Em torno da media',
        data: [],
        color: '#5C9C5C',
        dataGrouping: {
          enabled: false
        },
        dataLabels: {
          color: '#0064b4',
          y:3,
          x:-10,
          verticalAlign: 'bottom'
        }
    }, {
        name: 'Abaixo da media',
        data: [],
        color: '#f73939',
        dataGrouping: {
          enabled: false
        },
        dataLabels: {
          color: '#f73939',
          y:-3,
          x:-15,
          verticalAlign: 'top'
        }
    }, {
        type: 'scatter',
        data: [],
        marker: {
            lineColor: 'black',
            lineWidth: 1,
            fillColor: 'white'
        }
    }]
  };

  public updateFlag = false;

  constructor() { }

  ngOnInit() {
  }

  private draw() 
  {
    if (!this.dados)
      return;

    let min = this.dados.limites.inferior;
    let max = this.dados.limites.superior;
    let final = (min == 0) ? 1 + max : min + max;

    if (final < this.dados.data)
      final = this.dados.data;

    this.chartOptions.yAxis.max = final;

    this.chartOptions.series[0].data = [final];
    this.chartOptions.series[1].data = [max];
    this.chartOptions.series[2].data = [min];
    this.chartOptions.series[3].data = [this.dados.data];

    this.updateFlag = true;
    
  }

}
