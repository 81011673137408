import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MunicipioSelectComponent } from './municipio-select/municipio-select.component';
import { CoreDataModule } from '../../core-app.module';
import { MunicipioLgridComponent } from './municipio-lgrid/municipio-lgrid.component';
import { MunicipioModalComponent } from './municipio-modal/municipio-modal.component';
import { MunicipioModalSelectorComponent } from './municipio-modal-selector/municipio-modal-selector.component';
import { MaterialModule } from '@funceme/material';
import { FormsModule } from '@angular/forms';
import { LazyGridModule } from '../../generics/lazy-grid/lazy-grid.module';
import { UfModule } from '../uf/uf.module';

@NgModule({
  declarations: [
    MunicipioSelectComponent, 
    MunicipioLgridComponent, 
    MunicipioModalComponent, 
    MunicipioModalSelectorComponent
  ],
  imports: [
    CommonModule,
    CoreDataModule,
    MaterialModule,
    FormsModule,    
    LazyGridModule,
    UfModule
  ],
  exports: [
    MunicipioSelectComponent, 
    MunicipioModalSelectorComponent
  ],
  entryComponents: [
    MunicipioModalComponent
  ]
})
export class MunicipioModule { }
