import { Component, OnInit } from '@angular/core';
import { Config, ConfigFacade } from '@funceme/core-app';
import { Mapa } from '../../../shared/models/mapa.model';
import { CalendarioItem } from '../../../shared/models/calendario.model';
import { Subscription } from 'rxjs';
import { NavigatorService } from '../../../shared/services/navigator.service';
import { CalendarioService } from '../../../shared/services/calendario.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-calendario-view-mes',
  templateUrl: './calendario-view-mes.component.html',
  styleUrls: ['./calendario-view-mes.component.css']
})
export class CalendarioViewMesComponent implements OnInit {

  public config: Config;
  public mapa: Mapa;
  public dados: CalendarioItem[] = [];
  public destaques: CalendarioItem[] = [];
  
  public routeParams;

  public loading: boolean = false;

  public hasPrevious: boolean = true;
  public hasNext: boolean = true;

  public data_atualizacao:string = '';

  private subscription: Subscription;

  constructor( 
    private navigator: NavigatorService,
    private configFacade: ConfigFacade,
    private calendarioFacade: CalendarioService,
    private location: Location
  ) { 
    this.subscription = this.configFacade.current$.subscribe(value => { 
        
      this.routeParams = this.location.path().split('/');

      this.hasPrevious = this.navigator.hasPreviousMonth();
      this.hasNext = this.navigator.hasNextMonth();
      
      if (this.config != value && value.ano && value.mes && !value.dia)
        this.make(value);
    });
  }

  ngOnInit() {

  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  backward() {
    this.dados = [];
    this.navigator.monthBackward();
  }

  forward() {
    this.dados = [];
    this.navigator.monthForward();
  }

  disableButtons(value){
    if (value)
      this.hasPrevious = false;
    else
      this.hasPrevious = this.navigator.hasPreviousMonth();

    if (value)
      this.hasNext = false;
    else
      this.hasNext = this.navigator.hasNextMonth();
  }

  up() {
    this.navigator.up();
  }

  private make(config: Config) 
  {
    this.disableButtons(true);
    this.config = config;
    
    if (this.config && this.config.mes && !this.checkIfEqual(this.config, this.routeParams))
    {
        return;
    }

    this.dados = [];
    this.data_atualizacao = '';

    let _metrica = (this.config.metrica == 'media')? 'thiessen' : 'chuva';

    let _mes = this.config.mes+''

    if (this.config.periodo == 'bimestre') {
      if ((this.config.mes+1) % 12 != 0)
        _mes = _mes + '-' + (this.config.mes+1) % 12;
      else   
        _mes = _mes + '-12';
    } else if (this.config.periodo == 'trimestre') {
      if ((this.config.mes+2) % 12 != 0)
        _mes = _mes + '-' + (this.config.mes+2) % 12;
      else 
        _mes = _mes + '-12';

    } else if (this.config.periodo == 'quadrimestre') {
      if ((this.config.mes+3) % 12 != 0)
        _mes = _mes + '-' + (this.config.mes+3) % 12;
      else 
        _mes = _mes + '-12';
    }

    let produtoNome = '';

    if (this.config.produto == 'reservatorios')
    {
      produtoNome = 'acudes';
    }
    else
    {
      produtoNome = this.config.produto;
    }


    this.mapa = {
      ativo: true,
      label: null,
      link: null,
      path: 'https://cdn.funceme.br/calendario/chuvas/' 
        + produtoNome + '/'
        + _metrica + '/anos/' 
        + this.config.ano + '/' 
        + _mes + '/mapa.gif',
      date: new Date(this.config.ano,this.config.mes-1,1)
    }   

    this.loading = true;

    this.calendarioFacade.get(this.config).subscribe(response => {
      this.dados = response.data.list;
      this.data_atualizacao = response.data.data_processamento;
      this.loading = false;

      this.destaques = this.dados.sort(
        (a,b) => {
          if (this.config.metrica == 'maxima')
            return b.observado - a.observado
          else 
            return b.media - a.media
        }).slice(0,10);

    }, (error) => {
      this.loading = false;
    });
    this.disableButtons(false);
  }
  
  checkIfEqual(config, params) {
      return (
        // config.produto == params[2] &&
        config.metrica == params[3] &&
        config.ano == params[4] &&
        config.mes == params[5]
      )
  }
}
