import * as fromPosto from './posto.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Posto, emptyPosto } from './posto.model';

export const selectPostostate = createFeatureSelector<fromPosto.PostoState>('posto');

export const selectPostoIds = createSelector(
  selectPostostate,
  fromPosto.selectPostoIds
);

export const selectPostoEntities = createSelector(
  selectPostostate,
  fromPosto.selectPostoEntities
);

export const selectAllPostos = createSelector(
  selectPostostate,
  fromPosto.selectAllPostos
);

export const selectMetaDataPostos = createSelector(
  selectPostostate,
  fromPosto.getMetaDataPostos
);

export const selectTotalPostos = createSelector(
  selectPostostate,
  fromPosto.getTotalPostos
); 

export const selectCurrentPostoId = createSelector(
  selectPostostate,
  fromPosto.getSelectedPostoId
);

export const selectLoadingPostos = createSelector(
  selectPostostate,
  fromPosto.getLoadingPostos
); 

export const selectCurrentPosto = createSelector(
  selectPostoEntities,
  selectCurrentPostoId,
  (postoEntities, postoId) => {
    return postoId ? postoEntities[postoId] : emptyPosto;
  }
);

export const selectCurrentPostoIds = createSelector(
  selectPostostate,
  fromPosto.getSelectedPostoIds
);

export const selectCurrentPostos = createSelector(
  selectPostoEntities,
  selectCurrentPostoIds,
  (PostoEntities, PostoIds) => {

    let list: Posto[] = [];

    PostoIds.forEach(element => {
      list.push(PostoEntities[element]);
    });

    return list;
  }
);