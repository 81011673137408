import { Component, ViewChild, TemplateRef } from '@angular/core';

@Component({
  selector: 'funceme-lazy-grid-td-action',
  templateUrl: './lazy-grid-td-action.component.html',
  styleUrls: ['./lazy-grid-td-action.component.css']
})
export class LazyGridTdActionComponent {
  @ViewChild(TemplateRef, {static: true}) template: TemplateRef<any>;
  model: any;
  data: any;
}
