import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaciaFuncemeSelectComponent } from './bacia-funceme-select/bacia-funceme-select.component';
import { CoreDataModule } from '../../core-app.module';

@NgModule({
  declarations: [BaciaFuncemeSelectComponent],
  imports: [
    CommonModule,
    CoreDataModule
  ],
  exports: [BaciaFuncemeSelectComponent]
})
export class BaciaFuncemeModule { }
