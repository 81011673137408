export interface Municipio {
    id: number;
    nome: string;
    uf: string;
}

export const emptyMunicipio: Municipio = {
    id: null,
    nome: null,
    uf: null
}
