import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { BaciaFuncemeActions, BaciaFuncemeActionTypes } from './bacia-funceme.actions';
import { BaciaFunceme } from './bacia-funceme.model';
import { ApiMetaData } from '../../generics/pagination.model';

/**
 * Interface to the part of the Store containing BaciaFuncemeState
 * and other information related to BaciaFuncemeData.
 */
export interface BaciaFuncemeState extends EntityState<BaciaFunceme> {
  selectedId: number;
  selectedIds: number[];
  loading: boolean;
  loaded_at: Date | null;
  meta_data: ApiMetaData;
  total: number,
  last_pagination: string
}

export const adapter: EntityAdapter<BaciaFunceme> = createEntityAdapter<BaciaFunceme>({
  selectId: (BaciaFunceme: BaciaFunceme) => BaciaFunceme.id,
});

export const initialState: BaciaFuncemeState = adapter.getInitialState({
  selectedId: null,
  selectedIds: [],
  loading: false,
  loaded_at: null,
  meta_data: null,
  total: null,
  last_pagination: null
});

export function baciafuncemeReducer(state = initialState, action: BaciaFuncemeActions): BaciaFuncemeState {

  switch (action.type) {
    
    case BaciaFuncemeActionTypes.BaciaFuncemeSelected: {
      return Object.assign({}, state, { selectedId: action.payload });
    }

    case BaciaFuncemeActionTypes.BaciasFuncemeSelected: {
      return Object.assign({}, state, { selectedIds: [...state.selectedIds, action.payload] });
    }

    case BaciaFuncemeActionTypes.BaciasFuncemeUnselected: {
      return Object.assign({}, state, { 
        selectedBaciaFuncemeIds: state.selectedIds.filter(id => !action.payload.includes(id))
      });
    }

    case BaciaFuncemeActionTypes.LoadBaciasFunceme: {
      return Object.assign({}, state, { loading: true });
    }

    case BaciaFuncemeActionTypes.BaciasFuncemeLoaded: {
      return adapter.addAll(action.payload, Object.assign({}, state, { loading: false }));
    }

    case BaciaFuncemeActionTypes.PaginateBaciasFunceme: {
      return Object.assign({}, state, { loading: true });
    }

    case BaciaFuncemeActionTypes.BaciasFuncemePaginated: {          
      return adapter.addAll(action.payload.data.list, Object.assign({}, state, { 
        meta_data: action.payload.meta, 
        total: action.payload.data.total_results,
        loading: false,
        loaded_at: new Date().toISOString(),
        last_pagination: JSON.stringify(action.payload.meta.request.query_params),
      }));  
    }

    case BaciaFuncemeActionTypes.BaciasFuncemeRequestDone:
    case BaciaFuncemeActionTypes.BaciasFuncemeRequestFailed: {
      return Object.assign({}, state, { loading: false });
    }
    
    default:
      return state;
  }
}

export const getSelectedBaciaFuncemeIds = (state: BaciaFuncemeState) => state.selectedIds;
export const getSelectedBaciaFuncemeId = (state: BaciaFuncemeState) => state.selectedId;
export const getMetaDataBaciasFunceme = (state: BaciaFuncemeState) => state.meta_data;
export const getTotalBaciasFunceme = (state: BaciaFuncemeState) => state.total;
export const getLoadingBaciasFunceme = (state: BaciaFuncemeState) => state.loading;

// get the selectors
const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

// select the array of BaciaFunceme ids
export const selectBaciaFuncemeIds = selectIds;

// select the dictionary of BaciaFunceme entities
export const selectBaciaFuncemeEntities = selectEntities;

// select the array of BaciaFunceme
export const selectAllBaciasFunceme = selectAll;

// select the total BaciaFunceme count
export const selectBaciaFuncemeTotal = selectTotal;
