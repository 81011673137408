import { Injectable } from '@angular/core';
import { ActionsSubject, select, Store } from '@ngrx/store';
import * as RegiaoHidrograficaActions from './regiao-hidrografica.actions';
import { RegiaoHidrograficaState } from './regiao-hidrografica.reducer';
import { selectAllRegiaoHidrografica, selectCurrentRegiaoHidrografica, selectCurrentRegioesHidrograficas, selectMetaDataRegiaoHidrografica, selectTotalRegiaoHidrografica, selectLoadingRegiaoHidrografica } from './regiao-hidrografica.selectors';
import { Pagination } from '../../generics/pagination.model';
import { GenericFacade } from '../../generics/generic-facade';

@Injectable({ providedIn: 'root' })
export class RegiaoHidrograficaFacade implements GenericFacade
{
  all$ = this.store.pipe(select(selectAllRegiaoHidrografica));
  current$ = this.store.pipe(select(selectCurrentRegiaoHidrografica));
  currentList$ = this.store.pipe(select(selectCurrentRegioesHidrograficas));
  metaData$ = this.store.pipe(select(selectMetaDataRegiaoHidrografica));
  total$ = this.store.pipe(select(selectTotalRegiaoHidrografica));
  loading$ = this.store.pipe(select(selectLoadingRegiaoHidrografica));

  constructor(private store: Store<RegiaoHidrograficaState>, private actions$: ActionsSubject) {}

  selectOne(regiaoHidrograficaId: number) {
    this.store.dispatch(new RegiaoHidrograficaActions.RegiaoHidrograficaSelected(regiaoHidrograficaId));
  }

  selectMany(regioesHidrograficasIds: number[]) {
    this.store.dispatch(new RegiaoHidrograficaActions.RegioesHidrograficasSelected(regioesHidrograficasIds));
  }

  unselectMany(regioesHidrograficasIds: number[]) {
    this.store.dispatch(new RegiaoHidrograficaActions.RegiaoHidrograficaUnselected(regioesHidrograficasIds));
  }

  load(reset: boolean) {
    this.store.dispatch(new RegiaoHidrograficaActions.LoadRegiaoHidrografica());
  }

  paginate(pagination: Pagination) {
    this.store.dispatch(new RegiaoHidrograficaActions.PaginateRegiaoHidrografica(pagination));
  }

  download(pagination: Pagination) {
    this.store.dispatch(new RegiaoHidrograficaActions.DownloadRegiaoHidrografica(pagination));
  }

  select(id: number) {
    this.selectOne(id);
  }

  pick(id: number) {}
  destroy(id: number) {};
  save(model: any) {};
  index() {};
  edit(id: number) {};
  show(id: number) {};
  create(): void {};
  refresh(): void {};
}
