import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { NotificationsService } from '../../notifications/notifications.service';
import { PluviometriaNormalizadaFunceme } from './pluviometria-normalizada-funceme.model';
import { Pagination, paginationToString, ApiResponse } from '../../generics/pagination.model';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class PluviometriaNormalizadaFuncemeService {
  
  model = 'rest/pluvio/pluviometria-funceme-normalizada';

  constructor(
    private http: HttpClient,
    private router: Router,
    private ns: NotificationsService
  ) { }

  getUrl() {
    return `${environment.apiEndpoint}${this.model}`;
  }

  getUrlForId(id) {
    return `${this.getUrl()}/${id}`;
  }

  all() {
    return this.http.get<PluviometriaNormalizadaFunceme[]>(this.getUrl() + '?limit=no&orderBy=data');
  }

  load(id) {
    return this.http.get<PluviometriaNormalizadaFunceme>(this.getUrlForId(id));
  }

  paginate(pagination: Pagination) {
    return this.http.get<ApiResponse<PluviometriaNormalizadaFunceme>>(this.getUrl() + '?' + paginationToString(pagination));
  }

  download(pagination: Pagination) {
    pagination.paginator.limit = 9999999;
    window.location.href = this.getUrl() + '?' + paginationToString(pagination) + '&download';
  }
}
