import { Component, OnInit, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CalendarioItem } from '../../../shared/models/calendario.model';

@Component({
  selector: 'app-calendario-dados',
  templateUrl: './calendario-dados.component.html',
  styleUrls: ['./calendario-dados.component.css']
})
export class CalendarioDadosComponent implements OnInit {

  private _data = new BehaviorSubject<CalendarioItem[]>([]);

  public info;
  public list;

  @Input()
  set data(value) {
      this._data.next(value);
  };

  get data() {
    return this._data.getValue();
  }

  constructor() { }

  ngOnInit() {

    this._data.subscribe(response => {
      if (!response)
        return;

      //this.list = response.list;
    });
  }
}
