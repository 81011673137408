import { Posto } from '../posto/posto.model';

export interface PluviometriaNormalizadaFunceme {
    id: number;
    data: string;
    valor: number;
    posto_id: number;
    posto: Posto
}

export const emptyPluviometriaNormalizadaFunceme: PluviometriaNormalizadaFunceme = {
    id: null,
    data: null,
    valor: null,
    posto_id: null,
    posto: null
}
