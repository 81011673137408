import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PostoFacade } from '../../../state/posto/posto.facade';
import { Posto } from '../../../state/posto/posto.model';

@Component({
  selector: 'posto-modal',
  templateUrl: './posto-modal.component.html',
  styleUrls: ['./posto-modal.component.css']
})
export class PostoModalComponent {

  constructor(
    public dialogRef: MatDialogRef<PostoModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {     
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  close(): void {
    this.dialogRef.close();
  }
}
