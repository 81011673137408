import { Component, OnInit, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Config, emptyConfig } from '@funceme/core-app';

@Component({
  selector: 'app-calendario-alerta',
  templateUrl: './calendario-alerta.component.html',
  styleUrls: ['./calendario-alerta.component.css']
})
export class CalendarioAlertaComponent implements OnInit {

  public show:boolean = false;
  public currentYear = new Date().getFullYear()

  private _config = new BehaviorSubject<Config>(emptyConfig);

  @Input()
  set config(value) {
      this._config.next(value);
  };

  get config() {
    return this._config.getValue();
  }

  constructor() { }

  ngOnInit() {
    this._config.subscribe(config => {
      const date = new Date();
      this.show = (config.ano == date.getFullYear());
    });
  }
}
