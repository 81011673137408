import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { OrgaoActions, OrgaoActionTypes } from './orgao.actions';
import { Orgao } from './orgao.model';
import { ApiMetaData } from '../../generics/pagination.model';

/**
 * Interface to the part of the Store containing OrgaoState
 * and other information related to OrgaoData.
 */
export interface OrgaoState extends EntityState<Orgao> {
  selectedId: number;
  selectedIds: number[];
  loading: boolean;
  loaded_at: Date | null;
  meta_data: ApiMetaData;
  total: number,
  last_pagination: string
}

export const adapter: EntityAdapter<Orgao> = createEntityAdapter<Orgao>({
  selectId: (orgao: Orgao) => orgao.id,
});

export const initialState: OrgaoState = adapter.getInitialState({
  selectedId: null,
  selectedIds: [],
  loading: false,
  loaded_at: null,
  meta_data: null,
  total: null,
  last_pagination: null
});

export function orgaoReducer(state = initialState, action: OrgaoActions): OrgaoState {

  switch (action.type) {
    
    case OrgaoActionTypes.OrgaoSelected: {
      return Object.assign({}, state, { selectedId: action.payload });
    }

    case OrgaoActionTypes.OrgaosSelected: {
      return Object.assign({}, state, { selectedIds: [...state.selectedIds, action.payload] });
    }

    case OrgaoActionTypes.OrgaosUnselected: {
      return Object.assign({}, state, { 
        selectedOrgaoIds: state.selectedIds.filter(id => !action.payload.includes(id))
      });
    }

    case OrgaoActionTypes.LoadOrgaos: {
      return Object.assign({}, state, { loading: true });
    }

    case OrgaoActionTypes.OrgaosLoaded: {
      return adapter.addAll(action.payload, Object.assign({}, state, { loading: false }));
    }

    case OrgaoActionTypes.PaginateOrgaos: {
      return Object.assign({}, state, { loading: true });
    }

    case OrgaoActionTypes.OrgaosPaginated: {          
      return adapter.addAll(action.payload.data.list, Object.assign({}, state, { 
        meta_data: action.payload.meta, 
        total: action.payload.data.total_results,
        loading: false,
        loaded_at: new Date().toISOString(),
        last_pagination: JSON.stringify(action.payload.meta.request.query_params),
      }));  
    }

    case OrgaoActionTypes.OrgaosRequestDone:
      case OrgaoActionTypes.OrgaosRequestFailed: {
      return Object.assign({}, state, { loading: false });
    }
    
    default:
      return state;
  }
}

export const getSelectedOrgaoIds = (state: OrgaoState) => state.selectedIds;
export const getSelectedOrgaoId = (state: OrgaoState) => state.selectedId;
export const getMetaDataOrgaos = (state: OrgaoState) => state.meta_data;
export const getTotalOrgaos = (state: OrgaoState) => state.total;
export const getLoadingOrgaos = (state: OrgaoState) => state.loaded_at;

// get the selectors
const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

// select the array of Orgao ids
export const selectOrgaoIds = selectIds;

// select the dictionary of Orgao entities
export const selectOrgaoEntities = selectEntities;

// select the array of Orgao
export const selectAllOrgaos = selectAll;

// select the total Orgao count
export const selectOrgaoTotal = selectTotal;
