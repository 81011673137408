import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CalendarioItem } from '../../../shared/models/calendario.model';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-calendario-sumario-maximas',
  templateUrl: './calendario-sumario-maximas.component.html',
  styleUrls: ['./calendario-sumario-maximas.component.css']
})
export class CalendarioSumarioMaximasComponent implements OnInit {

  private _data = new BehaviorSubject<CalendarioItem[]>([]);

  @Input()
  set data(value) {
      this._data.next(value);
  };

  get data() {
    return this._data.getValue();
  }

  public displayedColumns: string[] = [
    'nome', 
    'postos_total', 
    'postos_informados', 
    'postos_com_chuva', 
    'municipios_total', 
    'municipios_informados',
    'municipios_com_chuva',
    'maior_chuva'
  ];

  public dataSource = new MatTableDataSource<CalendarioItem>();

  public regioes: any[] = [];

  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor() { }

  ngOnInit() 
  {
    this.dataSource.sort = this.sort;
    this.dataSource.data = [];

    this._data.subscribe(values => {

      this.regioes = [];
      let _regioes: string[] = [];

      values.map(item => {
        if (!!item.regiao && _regioes.indexOf(item.regiao) == -1) 
          _regioes.push(item.regiao);
      });

      _regioes.forEach(_regiao => {

        let _municipios:string[] = [];

        values.filter(value => value.regiao == _regiao).forEach(_value => {
          if (!_municipios.includes(_value.municipio))
            _municipios.push(_value.municipio);
        });

        this.regioes.push({
          nome: _regiao,
          maior_chuva: values.filter(value => value.regiao == _regiao).sort((a, b) => (a.observado < b.observado)? 1 : -1)[0],
          postos_total: values.filter(value => value.regiao == _regiao).length,
          postos_informados: values.filter(value => value.regiao == _regiao && value.observado != null).length,
          postos_com_chuva: values.filter(value => value.regiao == _regiao && value.observado > 0).length,
          municipios_total: _municipios.length,
          municipios_informados: _municipios.filter(municipio => values.some(_value => _value.municipio == municipio && _value.observado != null)).length,
          municipios_com_chuva: _municipios.filter(municipio => values.some(_value => _value.municipio == municipio && _value.observado > 0)).length,
        });
      });

      this.dataSource.data = this.regioes;
    });
  }

  public getTotalPostos(): number
  {
    return this.regioes.map(value => value.postos_total).reduce((acm, curr) => acm + curr, 0);
  }

  public getPostosInformados(): number
  {
    return this.regioes.map(value => value.postos_informados).reduce((acm, curr) => acm + curr, 0);
  }

  public getPostosComChuva(): string
  {
    const count: number = this.regioes.map(value => value.postos_com_chuva).reduce((acm, curr) => acm + curr, 0);
    return count + ' (' + ((count/this.getTotalPostos())*100).toFixed(1) + '%)';
  }

  public getTotalMunicipios(): number
  {
    return this.regioes.map(value => value.municipios_total).reduce((acm, curr) => acm + curr, 0);
  }

  public getMunicipiosInformados(): number
  {
    return this.regioes.map(value => value.municipios_informados).reduce((acm, curr) => acm + curr, 0);
  }

  public getMunicipiosComChuva(): string
  {
    const count: number = this.regioes.map(value => value.municipios_com_chuva).reduce((acm, curr) => acm + curr, 0);
    return count + ' (' + ((count/this.getTotalMunicipios())*100).toFixed(1) + '%)';
  }
}
