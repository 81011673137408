import { Component, OnInit, ViewChild, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { ConfigFacade } from 'libs/core-app/src/lib/state/config/config.facade';
import { Config } from 'libs/core-app/src/lib/state/config/config.model';

declare var gtag;

@Component({
  selector: 'app-root',
  templateUrl: './root.component.html',
  styleUrls: ['./root.component.css']
})
export class RootComponent implements OnInit, OnDestroy {

  @ViewChild('sidenav',{static: false}) sidenav: MatSidenav;

  public fullscreen: boolean = false;

  public config: Config;
  public selectedMunicipios: boolean = false;
  public selectedMacrorregioes: boolean = false;
  public selectedRegioes: boolean = false;
  public selectedCeara: boolean = false;
  public selectedPostos: boolean = false;

  constructor(
    private router: Router,
    private configFacade: ConfigFacade,
    private cdr: ChangeDetectorRef
  ) {

    // alert("Prezados(as), \n\nGostaríamos de informar que, devido a uma manutenção programada e essencial, nossos sistemas poderão apresentar instabilidade no dia 27/03/2024.\n\nEstamos comprometidos em restaurar nossos serviços o mais rápido possível. Se você tiver alguma dúvida, entre em contato conosco através do telefone (85) 3101.1130.\n\nAtenciosamente.")

    const navEndEvents$ = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    );

    navEndEvents$.subscribe((event: NavigationEnd) => {
      gtag('config', 'UA-7298747-4',{
          'page_path': event.urlAfterRedirects
      });
    })

  }

  ngOnInit() {
    this.configFacade.current$.subscribe(value => {
      this.config = value;

      if (this.config) {
        this.selectedMunicipios = this.config.produto == "municipios";
        this.selectedMacrorregioes = this.config.produto == "macrorregioes";
        this.selectedRegioes = this.config.produto == "regioes";
        this.selectedCeara = this.config.produto == "ceara";
        this.selectedPostos = this.config.produto == "postos";

        this.cdr.detectChanges();
      }
    });
  }

  ngOnDestroy() { }

  close() {
    this.sidenav.close();
  }

  link(url: string)
  {
    this.close();

    if (url == '/postos')
      this.router.navigate(['/postos']);
    else {
      this.config.produto = url;
      this.configFacade.save(this.config);
    }

  }
}
