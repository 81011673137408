export interface Uf {
    id: number;
    nome: string;
    geocod: number
}

export const emptyUf: Uf = {
    id: null,
    nome: null,
    geocod: null
    
}
