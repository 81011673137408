import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HighchartsChartModule } from 'highcharts-angular';

import { MaterialModule } from '@funceme/material';

import { AppRoutingModule } from './../../app-routing.module';

import { CalendarioMensalComponent } from './calendario-mensal/calendario-mensal.component';
import { CalendarioMesComponent } from './calendario-mes/calendario-mes.component';
import { CalendarioDiarioComponent } from './calendario-diario/calendario-diario.component';
import { CalendarioDiaComponent } from './calendario-dia/calendario-dia.component';
import { CalendarioAnualComponent } from './calendario-anual/calendario-anual.component';
import { CalendarioParamsComponent } from './calendario-params/calendario-params.component';
import { CalendarioParamPeriodoComponent } from './calendario-param-periodo/calendario-param-periodo.component';
import { CalendarioParamMetricaComponent } from './calendario-param-metrica/calendario-param-metrica.component';
import { CalendarioParamDataComponent, CustomDateMonthFormat, CustomDateYearFormat } from './calendario-param-data/calendario-param-data.component';
import { CalendarioMapaComponent } from './calendario-mapa/calendario-mapa.component';
import { CalendarioLegendaComponent } from './calendario-legenda/calendario-legenda.component';
import { CalendarioDadosComponent } from './calendario-dados/calendario-dados.component';
import { CalendarioTabelaComponent } from './calendario-tabela/calendario-tabela.component';
import { CalendarioAnoComponent } from './calendario-ano/calendario-ano.component';
import { CalendarioSumarioMaximasComponent } from './calendario-sumario-maximas/calendario-sumario-maximas.component';
import { CalendarioTituloComponent } from './calendario-titulo/calendario-titulo.component';
import { CalendarioProgressComponent } from './calendario-progress/calendario-progress.component';
import { CalendarioProgressMunicipioComponent } from './calendario-progress-municipio/calendario-progress-municipio.component';
import { CalendarioDestaquesComponent } from './calendario-destaques/calendario-destaques.component';
import { CalendarioBimestreComponent } from './calendario-bimestre/calendario-bimestre.component';
import { CalendarioViewMesComponent } from './calendario-view-mes/calendario-view-mes.component';
import { CalendarioTrimestreComponent } from './calendario-trimestre/calendario-trimestre.component';
import { CalendarioQuadrimestreComponent } from './calendario-quadrimestre/calendario-quadrimestre.component';
import { CalendarioViewMensalComponent } from './calendario-view-mensal/calendario-view-mensal.component';
import { CalendarioQuadrimestralComponent } from './calendario-quadrimestral/calendario-quadrimestral.component';
import { CalendarioTrimestralComponent } from './calendario-trimestral/calendario-trimestral.component';
import { CalendarioBimestralComponent } from './calendario-bimestral/calendario-bimestral.component';
import { CalendarioCategoriaComponent } from './calendario-categoria/calendario-categoria.component';
import { CalendarioInterpolacaoComponent } from './calendario-interpolacao/calendario-interpolacao.component';
import { CalendarioGraficoSerieComponent } from './calendario-grafico-serie/calendario-grafico-serie.component';
import { CalendarioGraficoQuantisComponent } from './calendario-grafico-quantis/calendario-grafico-quantis.component';
import { CalendarioAlertaComponent } from './calendario-alerta/calendario-alerta.component';
import { CalendarioMiniComponent } from './calendario-mini/calendario-mini.component';
import { CalendarioAppComponent } from './calendario-app/calendario-app.component';
import { CalendarioAppTabelaComponent } from './calendario-app-tabela/calendario-app-tabela.component';
import { CalendarioAppGraficoComponent } from './calendario-app-grafico/calendario-app-grafico.component';
import { CalendarioAppDatePickerComponent } from './calendario-app-date-picker/calendario-app-date-picker.component';
import { MatTableExporterModule } from 'mat-table-exporter';

@NgModule({
  declarations: [
    CalendarioMensalComponent, 
    CalendarioMesComponent, 
    CalendarioDiarioComponent, 
    CalendarioDiaComponent, 
    CalendarioAnualComponent,
    CalendarioParamsComponent,
    CalendarioParamPeriodoComponent,
    CalendarioParamMetricaComponent,
    CalendarioParamDataComponent,
    CustomDateMonthFormat,
    CustomDateYearFormat,
    CalendarioMapaComponent,
    CalendarioLegendaComponent,
    CalendarioDadosComponent,
    CalendarioTabelaComponent,
    CalendarioAnoComponent,
    CalendarioSumarioMaximasComponent,
    CalendarioTituloComponent,
    CalendarioProgressComponent,
    CalendarioProgressMunicipioComponent,
    CalendarioDestaquesComponent,
    CalendarioBimestreComponent,
    CalendarioViewMesComponent,
    CalendarioTrimestreComponent,
    CalendarioQuadrimestreComponent,
    CalendarioViewMensalComponent,
    CalendarioQuadrimestralComponent,
    CalendarioTrimestralComponent,
    CalendarioBimestralComponent,
    CalendarioCategoriaComponent,
    CalendarioInterpolacaoComponent,
    CalendarioGraficoSerieComponent,
    CalendarioGraficoQuantisComponent,
    CalendarioAlertaComponent,
    CalendarioMiniComponent,
    CalendarioAppComponent,
    CalendarioAppTabelaComponent,
    CalendarioAppGraficoComponent,
    CalendarioAppDatePickerComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HighchartsChartModule,
    MatTableExporterModule
  ],
  entryComponents: [
    CalendarioGraficoSerieComponent,
    CalendarioAppGraficoComponent
  ],
  exports: [
    CalendarioMensalComponent, 
    CalendarioMesComponent, 
    CalendarioDiarioComponent, 
    CalendarioDiaComponent, 
    CalendarioAnualComponent
  ]
})
export class CalendarioModule { }
