import { Injectable } from '@angular/core';
import { ActionsSubject, select, Store } from '@ngrx/store';
import * as UfActions from './uf.actions';
import { UfState } from './uf.reducer';
import { selectAllUfs, selectCurrentUfs, selectCurrentUf, selectMetaDataUfs, selectTotalUfs, selectLoadingUfs } from './uf.selectors';
import { Pagination } from '../../generics/pagination.model';
import { GenericFacade } from '../../generics/generic-facade';

@Injectable({ providedIn: 'root' })
export class UfFacade implements GenericFacade
{
  all$ = this.store.pipe(select(selectAllUfs));
  current$ = this.store.pipe(select(selectCurrentUf));
  currentList$ = this.store.pipe(select(selectCurrentUfs));
  metaData$ = this.store.pipe(select(selectMetaDataUfs));
  total$ = this.store.pipe(select(selectTotalUfs));
  loading$ = this.store.pipe(select(selectLoadingUfs));

  constructor(private store: Store<UfState>, private actions$: ActionsSubject) {}

  selectOne(ufId: string) {
    this.store.dispatch(new UfActions.UfSelected(ufId));
  }

  selectMany(ufIds: string[]) {
    this.store.dispatch(new UfActions.UfsSelected(ufIds));
  }

  unselectMany(ufIds: number[]) {
    this.store.dispatch(new UfActions.UfsUnselected(ufIds));
  }

  load(reset: boolean) {
    this.store.dispatch(new UfActions.LoadUfs());
  }

  paginate(pagination: Pagination) {            
    this.store.dispatch(new UfActions.PaginateUfs(pagination));
  }

  select(id: string) {
    this.selectOne(id);
  }

  pick(id: number) {}
  destroy(id: number) {};
  save(model: any) {};
  index() {};
  edit(id: number) {};
  show(id: number) {};
  create(): void {};
  refresh(): void {};
}
