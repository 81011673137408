import { Observable } from 'rxjs';
import { Pagination, ApiResponse, ApiMetaData } from './pagination.model';

export abstract class GenericFacade
{
    all$: Observable<any[]>;
    current$: Observable<any>;
    currentList$: Observable<any[]>;
    loading$: Observable<any>;
    metaData$: Observable<ApiMetaData>;
    total$: Observable<any>;
    
    abstract select(id: any);

    abstract load(reset: boolean);

    abstract pick(id: number);

    abstract destroy(id: number);

    abstract save(model: any);

    abstract index(): void;

    abstract edit(id: number): void;

    abstract show(id: number): void;

    abstract create(): void;

    abstract refresh(): void;

    abstract paginate(pagination: Pagination);

   // abstract download(pagination: Pagination);
}