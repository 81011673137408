import { Point } from '../../generics/geom.model';
import { Orgao } from '../orgao/orgao.model';

export interface CleanModel {
    id: number;
    nome: string;
}

export interface Posto {
    id: number;
    nome: string;
    municipio_id: number;
    municipio: CleanModel;
    orgao_id: number;
    orgao: Orgao;
    bacia_id: number;
    regiao_hidrografica: any[];
    bacia: CleanModel;
    geom: Point;
    ativo: boolean;
}

export const emptyPosto: Posto = {
    id: null,
    nome: null,
    municipio_id: null,
    municipio: null,
    orgao_id: null,
    orgao: null,
    bacia_id: null,
    regiao_hidrografica: null,
    bacia: null,
    geom: null,
    ativo: null
}
