import { Component, OnInit, OnDestroy } from '@angular/core';
import { ConfigFacade } from 'libs/core-app/src/lib/state/config/config.facade';
import { Config, emptyConfig } from 'libs/core-app/src/lib/state/config/config.model';
import { Subscription } from 'rxjs';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class NavigationComponent implements OnInit, OnDestroy {

    logged_user = '';
    logged_user_id = null;

    public config: Config;
    private subscription: Subscription;

    public routeParams;

    constructor(
        private configFacade: ConfigFacade,
        private location: Location,
        private router: Router
    ) {
        this.subscription = this.configFacade.current$.subscribe(value => {
            this.config = (value)? value : emptyConfig;
        });        
    }

    ngOnInit() 
    {
        if (localStorage.getItem('user_name') != 'null') {
            this.logged_user = localStorage.getItem('user_name');
            this.logged_user_id = localStorage.getItem('user_id');
        } else {
            this.logged_user = 'Visitante';
            this.logged_user_id = null;
        }
    }

    ngOnDestroy()
    {
        this.subscription.unsubscribe();
    }

    logout()
    {
        
    }

    login()
    {
        
    }

    link(url: string) 
    {
        if (url == '/postos')
            this.router.navigate(['/postos']);
        else if (url == '/') {
            this.config = emptyConfig;
            this.config.produto = 'municipios'
            this.config.periodo = 'diario'
            this.config.metrica = 'maxima'
            this.configFacade.save(this.config);
        }
        else {
            this.config.produto = url;
            this.configFacade.save(this.config);
        }
    }
}
