import * as fromPluviometriaNormalizadaFunceme from './pluviometria-normalizada-funceme.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PluviometriaNormalizadaFunceme, emptyPluviometriaNormalizadaFunceme } from './pluviometria-normalizada-funceme.model';

export const selectPluviometriaNormalizadaFuncemeState = createFeatureSelector<fromPluviometriaNormalizadaFunceme.PluviometriaNormalizadaFuncemeState>('pluviometriaNormalizadaFunceme');

export const selectPluviometriaNormalizadaFuncemeIds = createSelector(
  selectPluviometriaNormalizadaFuncemeState,
  fromPluviometriaNormalizadaFunceme.selectPluviometriaNormalizadaFuncemeIds
);

export const selectPluviometriaNormalizadaFuncemeEntities = createSelector(
  selectPluviometriaNormalizadaFuncemeState,
  fromPluviometriaNormalizadaFunceme.selectPluviometriaNormalizadaFuncemeEntities
);

export const selectAllPluviometriasNormalizadasFunceme = createSelector(
  selectPluviometriaNormalizadaFuncemeState,
  fromPluviometriaNormalizadaFunceme.selectAllPluviometriasNormalizadasFunceme
);

export const selectMetaDataPluviometriasNormalizadasFunceme = createSelector(
  selectPluviometriaNormalizadaFuncemeState,
  fromPluviometriaNormalizadaFunceme.getMetaDataPluviometriasNormalizadasFunceme
);

export const selectTotalPluviometriasNormalizadasFunceme = createSelector(
  selectPluviometriaNormalizadaFuncemeState,
  fromPluviometriaNormalizadaFunceme.getTotalPluviometriasNormalizadasFunceme
); 

export const selectCurrentPluviometriaNormalizadaFuncemeId = createSelector(
  selectPluviometriaNormalizadaFuncemeState,
  fromPluviometriaNormalizadaFunceme.getSelectedPluviometriaNormalizadaFuncemeId
);

export const selectLoadingPluviometriasNormalizadasFunceme = createSelector(
  selectPluviometriaNormalizadaFuncemeState,
  fromPluviometriaNormalizadaFunceme.getLoadingPluviometriasNormalizadasFunceme
); 

export const selectCurrentPluviometriaNormalizadaFunceme = createSelector(
  selectPluviometriaNormalizadaFuncemeEntities,
  selectCurrentPluviometriaNormalizadaFuncemeId,
  (PluviometriaNormalizadaFuncemeEntities, PluviometriaNormalizadaFuncemeId) => {
    return PluviometriaNormalizadaFuncemeId ? PluviometriaNormalizadaFuncemeEntities[PluviometriaNormalizadaFuncemeId] : emptyPluviometriaNormalizadaFunceme;
  }
);

export const selectCurrentPluviometriaNormalizadaFuncemeIds = createSelector(
  selectPluviometriaNormalizadaFuncemeState,
  fromPluviometriaNormalizadaFunceme.getSelectedPluviometriaNormalizadaFuncemeIds
);

export const selectCurrentPluviometriasNormalizadasFunceme = createSelector(
  selectPluviometriaNormalizadaFuncemeEntities,
  selectCurrentPluviometriaNormalizadaFuncemeIds,
  (PluviometriaNormalizadaFuncemeEntities, PluviometriaNormalizadaFuncemeIds) => {

    let list: PluviometriaNormalizadaFunceme[] = [];

    PluviometriaNormalizadaFuncemeIds.forEach(element => {
      list.push(PluviometriaNormalizadaFuncemeEntities[element]);
    });

    return list;
  }
);