import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { PluviometriaNormalizadaFuncemeActions, PluviometriaNormalizadaFuncemeActionTypes } from './pluviometria-normalizada-funceme.actions';
import { PluviometriaNormalizadaFunceme } from './pluviometria-normalizada-funceme.model';
import { ApiMetaData } from '../../generics/pagination.model';

/**
 * Interface to the part of the Store containing PluviometriaNormalizadaFuncemeState
 * and other information related to PluviometriaNormalizadaFuncemeData.
 */
export interface PluviometriaNormalizadaFuncemeState extends EntityState<PluviometriaNormalizadaFunceme> {
  selectedId: number;
  selectedIds: number[];
  loading: boolean;
  loaded_at: Date | null;
  meta_data: ApiMetaData;
  total: number;
  last_pagination: string
}

export const adapter: EntityAdapter<PluviometriaNormalizadaFunceme> = createEntityAdapter<PluviometriaNormalizadaFunceme>({
  selectId: (PluviometriaNormalizadaFunceme: PluviometriaNormalizadaFunceme) => PluviometriaNormalizadaFunceme.id
});

export const initialState: PluviometriaNormalizadaFuncemeState = adapter.getInitialState({
  selectedId: null,
  selectedIds: [],
  loading: false,
  loaded_at: null,
  meta_data: null,
  total: null,
  last_pagination: null
});

export function pluviometrianormalizadafuncemeReducer(state = initialState, action: PluviometriaNormalizadaFuncemeActions): PluviometriaNormalizadaFuncemeState {

  switch (action.type) {
    
    case PluviometriaNormalizadaFuncemeActionTypes.PluviometriasNormalizadasFuncemeelected: {
      return Object.assign({}, state, { selectedId: action.payload });
    }

    case PluviometriaNormalizadaFuncemeActionTypes.PluviometriasNormalizadasFuncemeSelected: {
      return Object.assign({}, state, { selectedIds: [...state.selectedIds, action.payload] });
    }

    case PluviometriaNormalizadaFuncemeActionTypes.PluviometriasNormalizadasFuncemeUnselected: {
      return Object.assign({}, state, { 
        selectedPluviometriaNormalizadaFuncemeIds: state.selectedIds.filter(id => !action.payload.includes(id))
      });
    }

    case PluviometriaNormalizadaFuncemeActionTypes.LoadPluviometriasNormalizadasFunceme: {
      return Object.assign({}, state, { loading: true });
    }

    case PluviometriaNormalizadaFuncemeActionTypes.PluviometriasNormalizadasFuncemeLoaded: {
      return adapter.addAll(action.payload, Object.assign({}, state, { loading: false }));
    }

    case PluviometriaNormalizadaFuncemeActionTypes.PaginatePluviometriasNormalizadasFunceme: {
      return Object.assign({}, state, { loading: true });
    }

    case PluviometriaNormalizadaFuncemeActionTypes.PluviometriasNormalizadasFuncemePaginated: {          
      return adapter.addAll(action.payload.data.list, Object.assign({}, state, { 
        meta_data: action.payload.meta, 
        total: action.payload.data.total_results,
        loading: false,
        loaded_at: new Date().toISOString(),
        last_pagination: JSON.stringify(action.payload.meta.request.query_params),
      }));  
    }

    case PluviometriaNormalizadaFuncemeActionTypes.PluviometriasNormalizadasFuncemeRequestDone: 
    case PluviometriaNormalizadaFuncemeActionTypes.PluviometriasNormalizadasFuncemeRequestFailed: {
      return Object.assign({}, state, { loading: false });
    }
    
    default:
      return state;
  }
}

export const getSelectedPluviometriaNormalizadaFuncemeIds = (state: PluviometriaNormalizadaFuncemeState) => state.selectedIds;
export const getSelectedPluviometriaNormalizadaFuncemeId = (state: PluviometriaNormalizadaFuncemeState) => state.selectedId;
export const getMetaDataPluviometriasNormalizadasFunceme = (state: PluviometriaNormalizadaFuncemeState) => state.meta_data;
export const getTotalPluviometriasNormalizadasFunceme = (state: PluviometriaNormalizadaFuncemeState) => state.total;
export const getLoadingPluviometriasNormalizadasFunceme = (state: PluviometriaNormalizadaFuncemeState) => state.loading;

// get the selectors
const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

// select the array of PluviometriaNormalizadaFunceme ids
export const selectPluviometriaNormalizadaFuncemeIds = selectIds;

// select the dictionary of PluviometriaNormalizadaFunceme entities
export const selectPluviometriaNormalizadaFuncemeEntities = selectEntities;

// select the array of PluviometriaNormalizadaFunceme
export const selectAllPluviometriasNormalizadasFunceme = selectAll;

// select the total PluviometriaNormalizadaFunceme count
export const selectPluviometriaNormalizadaFuncemeTotal = selectTotal;
