import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PostoModalComponent } from '../posto-modal/posto-modal.component';
import { PostoFacade } from '../../../state/posto/posto.facade';
import { Subscription } from 'rxjs';

@Component({
  selector: 'posto-modal-selector',
  templateUrl: './posto-modal-selector.component.html',
  styleUrls: ['./posto-modal-selector.component.css']
})
export class PostoModalSelectorComponent implements OnInit {

  private subscriptions = new Subscription();
  private dialogRef: MatDialogRef<PostoModalComponent, any>;
  public nome: string = '';

  constructor(public dialog: MatDialog, private postoFacade: PostoFacade) {}

  ngOnInit() {
    this.subscriptions.add(
      this.postoFacade.current$.subscribe(value => {
        if (this.dialogRef && value)
          this.dialogRef.close();
        if (value)
          this.nome = value.nome;
      })
    );
  }

  openModal(): void 
  {   
    this.postoFacade.select(null);    

    this.dialogRef = this.dialog.open(PostoModalComponent, {
      width: '800px',
      data: null
    });    

    this.dialogRef.afterClosed().subscribe(result => {
      
    });
  }
}