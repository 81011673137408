import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MaterialModule } from '@funceme/material';
import { FormsModule }   from '@angular/forms';

import { CalendarioModule } from '../modules/calendario/calendario.module';
import { ErroComponent } from './erro/erro.component';
import { PostosPluviometricosComponent } from './postos-pluviometricos/postos-pluviometricos.component';
import { PostosPluviometricosIndexComponent } from './postos-pluviometricos-index/postos-pluviometricos-index.component';
import { 
  CoreDataModule, 
  PostoModule,   
  PluviometriaNormalizadaFuncemeModule, 
  OrgaoModule } from '@funceme/core-app';
import { PluviometriaDadosComponent } from './pluviometria-dados/pluviometria-dados.component';
import { RepositorioChuvaComponent } from './repositorio-chuva/repositorio-chuva.component';

@NgModule({
  declarations: [
    ErroComponent, 
    PostosPluviometricosComponent, 
    PostosPluviometricosIndexComponent, 
    PluviometriaDadosComponent, 
    RepositorioChuvaComponent
  ],
  imports: [
    CommonModule,    
    MaterialModule,
    FormsModule, 
    CalendarioModule,
    PostoModule,
    PluviometriaNormalizadaFuncemeModule,
    OrgaoModule,
    CoreDataModule
  ]
})
export class PagesModule { }
