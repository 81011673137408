import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavigatorService } from '../../shared/services/navigator.service';

@Component({
  selector: 'app-postos-pluviometricos',
  templateUrl: './postos-pluviometricos.component.html',
  styleUrls: ['./postos-pluviometricos.component.css']
})
export class PostosPluviometricosComponent implements OnInit {
  
  constructor(
    private route: ActivatedRoute,
    private navigator: NavigatorService
  ) {

  }

  ngOnInit() {
    this.navigator.parse('postos', this.route.snapshot); 
  }
}
