import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { Posto } from '../../../state/posto/posto.model';

@Component({
  selector: 'funceme-pluviometria-normalizada-funceme-download-button',
  templateUrl: './pluviometria-normalizada-funceme-download-button.component.html',
  styleUrls: ['./pluviometria-normalizada-funceme-download-button.component.css']
})
export class PluviometriaNormalizadaFuncemeDownloadButtonComponent implements OnInit {

  @ViewChild(TemplateRef, {static: true}) template: TemplateRef<any>;
  model: any;
  data: Posto;

  constructor() { }

  ngOnInit() {
  }

  download()
  {
    let url = 'https://cdn.funceme.br/calendario/postos/' + this.data.id + '.txt';
    window.open(url, "_blank");
  }
}
