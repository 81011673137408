import * as fromMunicipio from './municipio.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Municipio, emptyMunicipio } from './municipio.model';

export const selectMunicipioState = createFeatureSelector<fromMunicipio.MunicipioState>('municipio');

export const selectMunicipioIds = createSelector(
  selectMunicipioState,
  fromMunicipio.selectMunicipioIds
);

export const selectMunicipioEntities = createSelector(
  selectMunicipioState,
  fromMunicipio.selectMunicipioEntities
);

export const selectAllMunicipios = createSelector(
  selectMunicipioState,
  fromMunicipio.selectAllMunicipios
);

export const selectMetaDataMunicipios = createSelector(
  selectMunicipioState,
  fromMunicipio.getMetaDataMunicipios
);

export const selectTotalMunicipios = createSelector(
  selectMunicipioState,
  fromMunicipio.getTotalMunicipios
); 

export const selectCurrentMunicipioId = createSelector(
  selectMunicipioState,
  fromMunicipio.getSelectedMunicipioId
);

export const selectLoadingMunicipios = createSelector(
  selectMunicipioState,
  fromMunicipio.getLoadingMunicipios
); 

export const selectCurrentMunicipio = createSelector(
  selectMunicipioEntities,
  selectCurrentMunicipioId,
  (MunicipioEntities, MunicipioId) => {
    return MunicipioId ? MunicipioEntities[MunicipioId] : emptyMunicipio;
  }
);

export const selectCurrentMunicipioIds = createSelector(
  selectMunicipioState,
  fromMunicipio.getSelectedMunicipioIds
);

export const selectCurrentMunicipios = createSelector(
  selectMunicipioEntities,
  selectCurrentMunicipioIds,
  (MunicipioEntities, MunicipioIds) => {

    let list: Municipio[] = [];

    MunicipioIds.forEach(element => {
      list.push(MunicipioEntities[element]);
    });

    return list;
  }
);